<template>
    <div class="user-index-table-wrapper">
        <div class="alert alert-danger" role="alert" v-if="hasError">
            <p>Sorry, there was an error retrieving the members.</p>
        </div>

        <div class="alert alert-success" role="alert" v-if="successMessage">
            <p class="success-message">
                {{ successMessage }}
            </p>
        </div>

        <v-client-table
            v-if="users.length > 0"
            :columns="columns"
            :data="users"
            :options="options"
        >
            <div slot="allowed_access" slot-scope="props">
                <p class="card-text">
                    {{ props.row.allowed_access == 0 ? "No" : "Yes" }}
                </p>
            </div>

            <div slot="edit" slot-scope="props">
                <button
                    type="button"
                    class="btn btn-dark"
                    data-toggle="modal"
                    data-target="#editModal"
                    @click="edit(props.row.id)"
                    :disabled="props.row.role == 2"
                >
                    <i class="far fa-edit"></i>
                </button>
            </div>

            <div slot="delete" slot-scope="props">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-toggle="modal"
                    data-target="#deleteModal"
                    @click="openDeleteModal(props.row.id, props.row.name)"
                    :disabled="props.row.role == 2"
                >
                    <i class="far fa-trash-alt"></i>
                </button>
            </div>
        </v-client-table>

        <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="editUserModalLabel"
            aria-hidden="true"
            data-backdrop="static"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Edit user: {{ editUser.name }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="saving">
                            <clip-loader class="loading-spinner"></clip-loader>
                        </div>

                        <errors :errors="errors" :has-error="hasError"></errors>

                        <!-- form -->
                        <div v-if="!saving && userLoaded">
                            <form @submit.prevent="onSubmit($event)">
                                <b-form-group
                                    label="Full name:"
                                    label-for="name"
                                >
                                    <b-form-input
                                        id="name"
                                        v-model="editUser.name"
                                        placeholder=""
                                        v-bind:class="{
                                            'is-invalid': errors.name
                                        }"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    label="Email address:"
                                    label-for="email_address"
                                >
                                    <b-form-input
                                        id="email_address"
                                        v-model="editUser.email"
                                        placeholder=""
                                        v-bind:class="{
                                            'is-invalid': errors.email
                                        }"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    label="Unlimited status:"
                                    label-for="unlimited"
                                >
                                    <b-form-checkbox
                                        id="unlimited_status"
                                        v-model="editUser.allowed_access"
                                        name="unlimited_status"
                                        value="1"
                                        unchecked-value="0"
                                    >
                                        This user has unlimited membership.
                                    </b-form-checkbox>
                                </b-form-group>

                                <div class="form-group">
                                    <button
                                        type="submit"
                                        class="btn btn-submit"
                                        aria-label="Update"
                                        :disabled="saving"
                                    >
                                        Update
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="deleteModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Are you sure you want to delete
                            {{ selectedUser.name }}'s account?
                        </h5>
                    </div>
                    <div class="modal-body d-flex justify-content-around">
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="deleteUser(selectedUser.id)"
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            class="btn btn-dark"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/api/users";

import errors from "@/components/layout/Errors.vue";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "users-index-table",
    components: {
        errors,
        ClipLoader
    },
    data() {
        return {
            hasError: false,
            errors: [],
            users: [],
            userLoaded: false,
            saving: false,
            successMessage: null,
            editUser: {
                id: null,
                name: "",
                email: "",
                allowed_access: "0"
            },
            editing: false,
            userClasses: null,
            classesCheck: [],
            selectedUser: {
                id: null,
                name: ""
            },
            paginationData: null,
            columns: [
                "name",
                "email",
                "date_of_birth",
                "contact_number",
                "member_id",
                "gym",
                "allowed_access",
                "edit",
                "delete"
            ],
            options: {
                skin:
                    "user-index-table table table-striped table-bordered table-hover",
                headings: {
                    name: "Name",
                    email: "Email",
                    allowed_access: "Unlimited Access",
                    edit: "",
                    delete: ""
                },
                texts: {
                    count: "",
                    filter: "",
                    filterPlaceholder: "Search"
                }
            }
        };
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        /**
         *  Get the users data for the table from the backend.
         */
        async getUsers() {
            this.users = api.all().then(response => {
                this.users = response.data.users;
            });
        },

        /**
         * Load the user ready to be edited.
         * @param int userId
         */
        edit(userId) {
            this.successMessage = null;
            this.userLoaded = false;
            this.sucessMessage = null;
            this.editUser = this.users.find(user => user.id === userId);
            this.userLoaded = true;
            this.editing = true;
        },

        /**
         * Submits the form on the edit modal which will update the user's details.
         *
         * @param event
         */
        onSubmit(event) {
            this.saving = true;
            this.success = false;
            this.sucessMessage = null;

            // post request to backend
            axios
                .post("/users/" + this.editUser.id, this.editUser)
                .then(res => {
                    // if successful then display success message
                    this.successMessage =
                        "You have sucessfully updated " +
                        this.editUser.name +
                        "'s details.";
                    this.hasError = false;
                    this.errors = [];

                    $("#editModal").modal("hide");

                    // refresh the users
                    this.users = [];
                    this.getUsers();
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                })
                .then(_ => {
                    this.saving = false;
                });
        },

        /**
         * Deletes the user from the backend.
         * @param userId
         */
        deleteUser(userId) {
            this.successMessage = null;

            api.destroyUser(userId).then(response => {
                $("#deleteModal").modal("hide");
                this.users = [];
                this.getUsers();
                // if successful then display success message
                this.successMessage =
                    "You have sucessfully deleted user: " +
                    this.selectedUser.name +
                    ".";
            });
        },

        /**
         * Open the delete model.
         * @param int userId
         * @param string userName
         */
        openDeleteModal(userId, userName) {
            this.successMessage = null;

            this.selectedUser = {
                id: userId,
                name: userName
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.user-index-table-wrapper {
    .btn-submit {
        width: 100%;
        background-color: $saru-dark-grey;
        color: $saru-white;
        font-family: Marker Felt, fantasy;
    }

    .success-message {
        margin-bottom: 0px;
        padding: 0px;
    }
}
</style>
