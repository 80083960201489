<template>
    <div>
        <b-container class="class-page-header" fluid>
            <b-row>
                <b-col>
                    <img
                        class="img-responsive"
                        src="storage/images/classes/sg/saru_1.jpg"
                        alt=""
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-title">
            <b-jumbotron header="Sub Grappling">
                <hr class="saru-hr" />
            </b-jumbotron>
        </b-container>

        <b-container>
            <b-row>
                <b-col class="class-page-text">
                    <h2>What is Sub Grappling?</h2>
                    <p>
                        Sub Grappling stands for Submission Grappling.
                    </p>
                    <p>
                        Submission Grappling is the no-gi version of BJJ and has
                        a higher focus on wrestling and submissions. It is a
                        sport based martial art that has more submissions than
                        any other style (predominantly leg locks) Due to its
                        heavy emphasis on wrestling it is great for physical
                        fitness, and with loads of professional shows now to
                        compete on it is growing rapidly all over the world.
                    </p>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="!updatingDisciplineTimetables">
            <b-row>
                <b-col class="mt-3">
                    <hr class="saru-hr" />
                    <img
                        class="img-responsive"
                        src="storage/images/classes/sg/timetable.png"
                        alt="SJA Martial Arts YMCA Sub Grappling Timetable"
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-gallery" fluid>
            <b-row>
                <b-col>
                    <h2>Gallery</h2>
                    <hr class="saru-hr" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <classes-gallery-slider
                        class-type="sg"
                        :gallery-image-paths="galleryImagePaths"
                    ></classes-gallery-slider>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "sg-class-page",
    props: {
        galleryImagePaths: {
            required: true,
            type: Array
        },
        updatingDisciplineTimetables: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fields: [
                { time: "Time " },
                { monday: "Monday" },
                { tuesday: "Tuesday" },
                { wednesday: "Wednesday" },
                { thursday: "Thursday" },
                { saturday: "Saturday" },
                { sunday: "Sunday" }
            ],
            items: [
                {
                    time: "9.40am - 10.20am",
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    saturday: "Kids SG Age 4 - 13",
                    sunday: ""
                },
                {
                    time: "10.40am - 11.40am",
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    saturday: "",
                    sunday: "Adult SG Age 14+"
                },
                {
                    time: "11am - 12pm",
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    saturday: "Adult SG Age 14+",
                    sunday: ""
                },
                {
                    time: "4.40pm - 5.20pm",
                    monday: "",
                    tuesday: "Kids SG Age 4 - 13",
                    wednesday: "",
                    thursday: "Kids SG Age 4 - 13",
                    saturday: "",
                    sunday: ""
                },
                {
                    time: "7pm - 8pm",
                    monday: "Adult SG Age 14+",
                    tuesday: "",
                    wednesday: "Adult SG Age 14+",
                    thursday: "",
                    saturday: "",
                    sunday: ""
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.timetable {
    table {
        min-width: 675px;
    }
}
</style>
