import axios from "axios";

export default {
    /**
     * Get all questions.
     * 
     * @returns
     */
    all() {
        return axios.get("/new-starters");
    },

    download(id) {
        return axios.get("/new-starter/" + id + "/download", {
            responseType: 'blob',
        })
    }
};
