<template>
    <div>
        <b-container class="class-page-header" fluid>
            <b-row>
                <b-col>
                    <img
                        class="img-responsive"
                        src="storage/images/classes/cjj/saru_1.jpg"
                        alt=""
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-title">
            <b-jumbotron header="CJJ (Combat Jiu-Jitsu)">
                <hr class="saru-hr" />
            </b-jumbotron>
        </b-container>

        <b-container>
            <b-row>
                <b-col class="class-page-text">
                    <h2>What is CJJ?</h2>
                    <p>
                        CJJ stands for Combat Jiu-Jitsu.
                    </p>
                    <p>
                        Combat Jiu-Jitsu originated from traditional Jiu-Jitsu
                        which was developed in Japan by the samurai. CJJ is a
                        modern take on the art developed by the samurai all
                        those years ago - after all who carries around a sword
                        anymore! The focus of our CJJ programme is on self
                        defence and confidence building. Our programme is unique
                        with a focus on verbal resolution, we encourage all of
                        our students to use non contact methods to deal with
                        situations.
                    </p>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="!updatingDisciplineTimetables">
            <b-row>
                <b-col class="mt-3">
                    <hr class="saru-hr" />
                    <img
                        class="img-responsive"
                        src="storage/images/classes/cjj/timetable.png"
                        alt="SJA Martial Arts YMCA CJJ Timetable"
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-gallery" fluid>
            <b-row>
                <b-col>
                    <h2>Gallery</h2>
                    <hr class="saru-hr" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <classes-gallery-slider
                        class-type="cjj"
                        :gallery-image-paths="galleryImagePaths"
                    ></classes-gallery-slider>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "cjj-class-page",
    props: {
        galleryImagePaths: {
            required: true,
            type: Array
        },
        updatingDisciplineTimetables: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fields: [
                { time: "Time " },
                { monday: "Monday" },
                { tuesday: "Tuesday" },
                { wednesday: "Wednesday" },
                { thursday: "Thursday" },
                { saturday: "Saturday" }
            ],
            items: [
                {
                    time: "9am - 9.40am",
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    saturday: "Lemur CJJ Age 4 - 7"
                },
                {
                    time: "10.20am - 11am",
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    saturday: "Gibbon CJJ Age 8 - 13"
                },
                {
                    time: "4pm - 4.40pm",
                    monday: "Lemur CJJ Age 4 - 7",
                    tuesday: "",
                    wednesday: "Lemur CJJ Age 4 - 7",
                    thursday: "",
                    saturday: ""
                },
                {
                    time: "5.20pm - 6pm",
                    monday: "Gibbon CJJ Age 8 - 13",
                    tuesday: "",
                    wednesday: "Gibbon CJJ Age 8 - 13",
                    thursday: "",
                    saturday: ""
                },
                {
                    time: "8pm - 9pm",
                    monday: "Adult CJJ Age 14+",
                    tuesday: "Women's CJJ Age 14+",
                    wednesday: "Adult CJJ Age 14+",
                    thursday: "Women's CJJ Age 14+",
                    saturday: ""
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.intro-wrapper {
    text-align: center;
}

.cjj-page-text {
    text-align: center;
    padding: 20px 0px 40px 0px;
}
</style>
