<template>
    <div class="container m-b-15">
        <div class="row">
            <div class="col-md-12">
                <hooper
                    :settings="hooperSettings"
                    pagination="yes" 
                    class="hooper-slider" 
                    style="height: 100%">
                    <slide v-for="review in reviews" :key="review.name">
                        <div class="carousel-review-slide">
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <!-- insert image here for review -->
                                </div>

                                <div class="col-12 col-sm-12">
                                    <p class="review-text">
                                        <i>
                                            "{{ review.review }}" 
                                            <br><br>– {{ review.name}}
                                        </i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </slide>
                </hooper>
            </div>
        </div>
    </div>
</template>

<script>
    import { Hooper, Slide } from 'hooper';
    import 'hooper/dist/hooper.css';

    export default {
        components: {
            Hooper,
            Slide
        },
        data() {
            return {
                hooperSettings: {
                    itemsToShow: 1.25,
                    infiniteScroll: true,
                    centerMode: true,
                    progress: true,
                    autoPlay: true,
                    playSpeed: 6000,
                    mouseControl: false,
                    wheelControl: false
                },
                reviews: [
                    {
                        review: 'Fantastic place to train me and both my son\'s train here, very welcoming gym all the coaches are outstanding from the kids classes up to the adults, would highly recommend to anyone looking for them or their child to join and train! 5 star!',
                        name: 'Sam Lightfoot'
                    },
                    {
                        review: 'Really enjoyed attending the Sunday Basic and Competition BJJ. Josh is a passionate and enthusiastic coach/sensei, the guys training were all friendly, helpful, and great partners overall. Would highly recommend.',
                        name: 'Luke Richards'
                    },
                    {
                        review: 'I have been taking my 4 year old son for about 8 weeks now and he absolutely loves it. If it was 7 days a week he would want to go everyday. The trainers there are fantastic, and really patient with the kids. My sons confidence is growing by the day which is really good to see.',
                        name: 'Craig Davies'
                    },
                    {
                        review: 'My son is incredibly shy but his confidence has grown so much since starting with Saru. He has been training almost two years and has achieved so much already. The team provide a great mix of training, discipline and fun. He is now training four days a week!',
                        name: 'Rebecca Obrien'
                    },
                    {
                        review: 'I\'ve been bringing my son here since September and the change in him is amazing he seems to have grown up so much even his school teachers have noticed. He\'s better behaved and happier. He\'s made some lovely new friends and really enjoys going. Everyone at saru are so friendly and they instructors are just fab with the kids.',
                        name: 'Rhian Mansfield'
                    },
                ],
            }
        }
    }   
</script>
