<template>
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-center">
                <div
                    class="errors alert alert-danger"
                    v-if="hasError || Object.keys(errors).length > 0"
                >
                    <div v-if="errors">
                        <div v-for="(v, k) in errors" :key="k">
                            <p
                                class="error-message"
                                v-for="error in v"
                                :key="error"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "errors",
    props: {
        errors: {
            required: true,
            type: [Array, Object]
        },
        hasError: {
            required: false,
            type: Boolean
        }
    }
};
</script>

<style lang="scss" scoped>
.errors {
    text-align: center;
    width: 100%;
}

.error-message {
    margin-bottom: 0px;
    padding: 0px;
}
</style>
