<template>
    <div v-if="!hideFooter" class="footer-wrapper">
        <b-container>
            <b-row>
                <b-col cols="12" md="5" lg="5" xl="5">
                    <b-row>
                        <b-col>
                            <div class="d-flex justify-content-center">
                                <img class="footer-logo image-responsive" :src="logoUrl" alt="SJA Martial Arts Logo" />
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p class="copyright-text">
                                © "SJA Martial Arts" 2022
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="2" lg="2" xl="2">
                    <div class="footer-links">
                        Quick Links
                        <p>
                            <ul class="list list-unstyled">
                                <li><a href="/">Home</a></li>
                                <li><a href="/locations">Locations</a></li>
                                <li v-if="enableMembershipHub === '1'">
                                    <a href="/membership-hub/login">Membership Hub</a>
                                </li>
                                <li><a v-if="storeLink" href="/store">Shop</a></li>
                                <li><a href="/frequently-asked-questions">FAQ</a></li>
                                <li><a href="/contact-us">Contact Us</a></li>
                            </ul>
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="2" lg="2" xl="2">
                    <div class="footer-links">
                        Services
                        <p>
                            <ul class="list list-unstyled">
                                <li><a href="/combat-jiu-jitsu">Combat Jiu-Jitsu</a></li>
                                <li><a href="/brazilian-jiu-jitsu">Brazilian Jiu-Jitsu</a></li>
                                <li><a href="/submission-grappling">Submisson Grappling</a></li>
                                <li><a href="/mixed-martial-arts">Mixed Martial Arts</a></li>
                            </ul>
                        </p>
                    </div>
                </b-col>
                <b-col cols="12" md="2" lg="2" xl="2">
                    <div class="footer-links">
                        Connect
                        <p>
                            <ul class="list list-unstyled">
                                <li><a href="https://www.instagram.com/sjamartialarts">Instagram</a></li>
                                <li><a href="https://www.facebook.com/SJAmartialarts">Facebook</a></li>
                                <li><a href="https://twitter.com/sarujujitsu">Twitter</a></li>
                            </ul>
                        </p>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <hr>
                </b-col>
            </b-row>
            <div class="footer-after-hr">
                <b-row>
                    <b-col class="mb-2" cols="12" md="3" lg="3" xl="3">
                        <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                    </b-col>
                    <b-col class="mb-2" cols="12" md="3" lg="3" xl="3">
                        Call 03332 423919
                    </b-col>
                    <b-col class="mb-2" cols="12" md="3" lg="3" xl="3">
                        <a href="mailto:contact-us@sjamartialarts.co.uk">
                            contact-us@sjamartialarts.co.uk
                        </a>
                    </b-col>
                    <b-col class="mb-2" cols="12" md="3" lg="3" xl="3">
                        v{{ version }} <br> Developed by Webmac Solutions
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "layout-footer",
    components: {},
    props: {
        hideFooter: {
            required: false,
            type: Boolean
        },
        version: {
            required: true,
            type: String
        },
        storeLink: {
            required: false,
            default: null,
            type: String
        },
        enableMembershipHub: {
            required: true,
            type: String
        },
    },
    data() {
        return {
            logoUrl: '/storage/images/footer/logo.png'
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.footer-wrapper {
    background-color: $saru-dark-grey;
    padding: 40px 0px 20px 0px;
    color: $saru-white;
    font-size: 10pt;
    text-align: center;

    .footer-logo {
        height: 100px;
        margin-bottom: 10px;
    }

    .copyright-text {
        text-align: center;
    }

    .footer-links {
        text-align: center;
        color: $saru-grey;

        .list {
            a { 
                color: $saru-white;
            }
        }
    }

    hr {
        background-color: $saru-teal;
    }

    .footer-after-hr {
        padding: 10px;

        a { 
            color: $saru-white;
        }
    }


    @media (min-width: 325px) {
        .footer-links {
            text-align: center;
        }
    }

    @media (min-width: 576px) {
        .footer-links {
            text-align: center;
        }
    }

    @media (min-width: 768px) {
        .footer-links {
            text-align: left;
        }
    }

    @media (min-width: 992px) {
        .footer-links {
            text-align: left;
        }
    }
}
</style>
