<template>
    <div class="mat-system-contact-form-wrapper">
        <b-row>
            <b-col>
                <div class="d-flex justify-content-center">
                    <embed
                        class="contact-form-wrapper"
                        src="https://sarujj.wavecrm.net/Automation/embed/enquiry?fbclid=IwAR1j7irD81snniFcIZz2KWZM9k-GCDC9sGVRlOx-_5HM8eRqZZt8lwRGaWc"
                    />
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <SocialMediaLinks />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import SocialMediaLinks from "@/components/SocialMediaLinks.vue";

export default {
    name: "mat-system-contact-form-page",
    components: {
        SocialMediaLinks
    },
    props: {},
    data() {
        return {};
    },
    computed: {},
    methods: {},
    mounted() {}
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.mat-system-contact-form-wrapper {
    text-align: center;

    margin-top: 75px;
    margin-bottom: 75px;

    .contact-form-wrapper {
        width: 100%;
        min-height: 900px;
    }

    @media (min-width: 576px) {
        margin-top: 75px;
        margin-bottom: 75px;

        .contact-form-wrapper {
            width: 100%;
            min-height: 715px;
        }
    }

    @media (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 200px;

        .contact-form-wrapper {
            width: 100%;
            min-height: 685px;
        }
    }

    @media (min-width: 992px) {
        margin-top: 150px;
        margin-bottom: 350px;

        .contact-form-wrapper {
            width: 100%;
            min-height: 665px;
        }
    }
}
</style>
