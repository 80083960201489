<template>
    <div class="container">
        <backButton></backButton>

        <errors :errors="errors" :has-error="hasError"></errors>

        <div class="alert alert-success" role="alert" v-if="successMessage">
            <p class="success-message">
                {{ successMessage }}
            </p>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h3>My Membership</h3>
                    </div>
                    <div class="card-body">
                        <div v-if="saving">
                            <clip-loader class="loading-spinner"></clip-loader>
                        </div>

                        <div v-if="!saving">
                            <!-- Show good alert if has allowed unlimited access  -->
                            <div
                                v-if="$auth.user().allowed_access == '1'"
                                class="alert alert-success"
                                role="alert"
                            >
                                You have unlimited access to all of our online
                                content.
                            </div>

                            <div
                                v-if="
                                    !$auth.user().allowed_access &&
                                        !$auth.user().access_token_expired &&
                                        $auth.user().access_token_days_left > 0
                                "
                                class="alert alert-success"
                                role="alert"
                            >
                                You have unlimited access to all of our online
                                content for
                                {{ $auth.user().access_token_days_left }} days.
                            </div>

                            <!-- otherwise show bad alert and redeem token -->
                            <div
                                v-if="
                                    this.$auth.user().allowed_access == 0 &&
                                        this.$auth.user().access_token_expired
                                "
                                class="alert alert-danger"
                                role="alert"
                            >
                                You currently do not have access to any of our
                                online content.
                            </div>

                            <!-- form -->
                            <form
                                autocomplete="off"
                                @submit.prevent="submitToken"
                                method="post"
                            >
                                <b-form-group label="Code:" label-for="token">
                                    <b-form-input
                                        id="token"
                                        v-model="user.token"
                                        placeholder=""
                                        v-bind:class="{
                                            'is-invalid': errors.token
                                        }"
                                    ></b-form-input>
                                </b-form-group>

                                <div class="submit-token-button-toolbar">
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                    >
                                        Redeem
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api/users";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import backButton from "@/membership_hub/components/Back.vue";
import errors from "@/components/layout/Errors.vue";

export default {
    name: "user-membership",
    components: {
        ClipLoader,
        backButton,
        errors
    },
    data() {
        return {
            hasError: false,
            saving: false,
            user: {
                token: ""
            },
            errors: [],
            successMessage: null
        };
    },
    methods: {
        /**
         * Submit the token to the backend and check to see if its valid.
         */
        submitToken() {
            this.saving = true;
            this.successMessage = null;

            // post request to backend
            axios
                .post(
                    "/user/" +
                        this.$auth.user().id +
                        "/membership/token/submit",
                    this.user
                )
                .then(res => {
                    // if successful then display success message
                    this.success = true;
                    this.hasError = false;
                    this.errors = [];
                    this.successMessage =
                        "You have access to SJA Martial Arts Membership Hub for 7 days! Head back to the menu to access all of our features.";
                    // update the auth user data
                    this.$auth.user(res.data.data.user);
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                })
                .then(_ => {
                    this.saving = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.submit-token-button-toolbar {
    button {
        width: 100%;
        font-family: Marker Felt, fantasy;
    }

    button:hover {
        color: $teal;
    }
}

.errors {
    text-align: center;
    width: 100%;
}

.error-message {
    margin-bottom: 0px;
    padding: 0px;
}

.success-message {
    margin-bottom: 0px;
    padding: 0px;
}
</style>
