<template>
    <b-container fluid>
        <backButton></backButton>

        <h2>New Starters Reviews</h2>

        <!-- <h3>Results</h3>

        <newStarterReviewResults></newStarterReviewResults> -->

        <!-- <h3>Submitted Reviews</h3> -->

        <newStarterReviewIndexTable></newStarterReviewIndexTable>
    </b-container>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";
import newStarterReviewIndexTable from "@/membership_hub/components/new-starter-reviews/IndexTable.vue";

export default {
    name: "new-starter-reviews",
    components: {
        backButton,
        newStarterReviewIndexTable
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.btn-back {
    background-color: $saru-dark-grey;
    color: $saru-white;
    font-family: Marker Felt, fantasy;
}
</style>
