<template>
    <div>
        <b-container class="class-page-header" fluid>
            <b-row>
                <b-col>
                    <img
                        class="img-responsive"
                        src="storage/images/classes/mma/saru_1.jpg"
                        alt=""
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-title">
            <b-jumbotron header="MMA (Mixed Martial Arts)">
                <hr class="saru-hr" />
            </b-jumbotron>
        </b-container>

        <b-container>
            <b-row>
                <b-col class="class-page-text">
                    <h2>What is MMA?</h2>
                    <p>
                        MMA stands for Mixed Martial Arts.
                    </p>
                    <p>
                        Mixed Martial Arts has been made famous by the UFC. MMA
                        is a combination of all styles of combat like boxing,
                        wrestling, karate etc. Our MMA programme is run
                        following the guidelines of the IMMAF organisation with
                        a great structure for promoting people and giving them
                        the tools necessary to become a well rounded martial
                        artist.
                    </p>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="!updatingDisciplineTimetables">
            <b-row>
                <b-col class="mt-3">
                    <hr class="saru-hr" />
                    <img
                        class="img-responsive"
                        src="storage/images/classes/mma/timetable.png"
                        alt="SJA Martial Arts YMCA MMA Timetable"
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-gallery" fluid>
            <b-row>
                <b-col>
                    <h2>Gallery</h2>
                    <hr class="saru-hr" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <classes-gallery-slider
                        class-type="mma"
                        :gallery-image-paths="galleryImagePaths"
                    ></classes-gallery-slider>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "mma-class-page",
    props: {
        galleryImagePaths: {
            required: true,
            type: Array
        },
        updatingDisciplineTimetables: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fields: [
                { time: "Time " },
                { tuesday: "Tuesday" },
                { thursday: "Thursday" },
                { saturday: "Saturday" },
                { sunday: "Sunday" }
            ],
            items: [
                {
                    time: "10am - 10.40am",
                    tuesday: "",
                    thursday: "",
                    saturday: "",
                    sunday: "Kids MMA Age 8 - 13"
                },
                {
                    time: "11.40am - 12.40pm",
                    tuesday: "",
                    thursday: "",
                    saturday: "",
                    sunday: "Adult MMA Age 14+"
                },
                {
                    time: "12pm - 1pm",
                    tuesday: "",
                    thursday: "",
                    saturday: "",
                    sunday: "Adult MMA Age 14+"
                },
                {
                    time: "5.20pm - 6pm",
                    tuesday: "Kids MMA Age 8 - 13",
                    thursday: "Kids MMA Age 8 - 13",
                    saturday: "",
                    sunday: ""
                },
                {
                    time: "7pm - 8pm",
                    tuesday: "Adult MMA Age 14+",
                    thursday: "Adult MMA Age 14+",
                    saturday: "",
                    sunday: ""
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped></style>
