<template>
    <div class="counter-wrapper px-3 py-4 mb-3" v-if="loaded">
        <h2>Countdown</h2>
        <div class="d-flex flex-row justify-content-center">
            <div class="p-2">
                <h3>{{ displayDays }}</h3>
                days
            </div>
            <span class="counter-semi-colon">:</span>
            <div class="p-2">
                <h3>{{ displayHours }}</h3>
                hours
            </div>
            <span class="counter-semi-colon">:</span>
            <div class="p-2">
                <h3>{{ displayMinutes }}</h3>
                minutes
            </div>
            <span class="counter-semi-colon">:</span>
            <div class="p-2">
                <h3>{{ displaySeconds }}</h3>
                seconds
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "counter",
    props: {
        year: {
            required: false,
            type: Number
        },
        month: {
            required: false,
            type: Number
        },
        day: {
            required: false,
            type: Number
        },
        hour: {
            required: false,
            type: Number
        },
        minute: {
            required: false,
            type: Number
        },
        second: {
            required: false,
            type: Number
        },
        millisecond: {
            required: false,
            type: Number
        }
    },
    data() {
        return {
            displayDays: 0,
            displayHours: 0,
            displayMinutes: 0,
            displaySeconds: 0,
            loaded: false,
            expired: false
        };
    },
    computed: {
        _seconds: () => 1000,
        _minutes() {
            return this._seconds * 60;
        },
        _hours() {
            return this._minutes * 60;
        },
        _days() {
            return this._hours * 24;
        }
    },
    methods: {
        /**
         * Format the numbers for the display.
         *
         * @param {string} num
         * @return {string}
         */
        formatNum(num) {
            return num < 10 ? "0" + num : num;
        },
        /**
         * Show the remaining time left on the countdown timer.
         */
        showRemaining() {
            const timer = setInterval(() => {
                const now = new Date();
                const distance = this.getEnd().getTime() - now.getTime();

                // check to see if timer has expired
                if (distance < 0) {
                    clearInterval(timer);
                    this.expired = true;
                    this.$emit("expired");
                    this.loaded = true;
                    return;
                }

                const days = Math.floor(distance / this._days);
                const hours = Math.floor((distance % this._days) / this._hours);
                const minutes = Math.floor(
                    (distance % this._hours) / this._minutes
                );
                const seconds = Math.floor(
                    (distance % this._minutes) / this._seconds
                );

                this.displayDays = this.formatNum(days);
                this.displayHours = this.formatNum(hours);
                this.displayMinutes = this.formatNum(minutes);
                this.displaySeconds = this.formatNum(seconds);

                this.loaded = true;
            });
        },
        /**
         * Get the end date from params.
         *
         * @return {Date}
         */
        getEnd() {
            return new Date(
                this.year,
                this.month,
                this.day,
                this.hour,
                this.minute,
                this.second,
                this.millisecond
            );
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.counter-wrapper {
    background-color: $saru-dark-grey;
    border-radius: 10px;
    color: $saru-white;
}
</style>
