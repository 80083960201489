<template>
    <div class="landing-page-wrapper">
        <!-- step 0 -->
        <div v-show="step === 0">
            <div class="landing-first-section">
                <!-- logo -->
                <b-row>
                    <b-col>
                        <div
                            class="landing-logo-wrapper d-flex justify-content-center"
                        >
                            <img
                                class="landing-logo"
                                src="/storage/images/saru_logo.png"
                                alt="SJA Martial Arts Logo"
                            />
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- step 0 -->
            <div class="step-0-wrapper">
                <b-row>
                    <b-col>
                        <div class="content">
                            <b-row>
                                <b-col>
                                    <h3 v-show="!success" class="mt-3">
                                        SJA Martial Arts
                                    </h3>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <p>
                                        Start your journey by entering a few
                                        details...
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <b-form
                                            class="landing-form"
                                            @submit="nextStep"
                                        >
                                            <b-form-group
                                                label-class="sr-only"
                                                label="Your Name:"
                                                label-for="name"
                                            >
                                                <b-form-input
                                                    id="name"
                                                    v-model="details.name"
                                                    required
                                                    placeholder="Name"
                                                    v-bind:class="{
                                                        'is-invalid':
                                                            errors.name
                                                    }"
                                                ></b-form-input>
                                            </b-form-group>

                                            <b-form-group
                                                label-class="sr-only"
                                                label="Email address:"
                                                label-for="email"
                                            >
                                                <b-form-input
                                                    id="email"
                                                    v-model="details.email"
                                                    type="email"
                                                    placeholder="Email"
                                                    v-bind:class="{
                                                        'is-invalid':
                                                            errors.email
                                                    }"
                                                ></b-form-input>
                                            </b-form-group>

                                            <b-button
                                                class="btn-lg btn-submit"
                                                type="button"
                                                @click="nextStep"
                                                variant="primary"
                                            >
                                                Recieve your free...
                                            </b-button>

                                            <div class="mt-2">
                                                <a
                                                    @click="missOffer"
                                                    class="landing-exit-text"
                                                >
                                                    I want to miss this offer
                                                </a>
                                            </div>
                                        </b-form>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <!-- step 1 -->
        <div v-show="step === 1">
            <div class="landing-first-section">
                <!-- logo -->
                <b-row>
                    <b-col>
                        <div
                            class="landing-logo-wrapper d-flex justify-content-center"
                        >
                            <img
                                class="landing-logo"
                                src="/storage/images/saru_logo.png"
                                alt="SJA Martial Arts Logo"
                            />
                        </div>
                    </b-col>
                </b-row>

                <!-- video -->
                <b-row>
                    <b-col>
                        <div
                            class="landing-video-wrapper d-flex justify-content-center"
                        >
                            <div class="landing-video">
                                <div
                                    class="embed-responsive embed-responsive-16by9"
                                >
                                    <video
                                        id="landing-video"
                                        class="landing-video-video embed-responsive-item mx-auto"
                                        controls
                                        width="1280"
                                        height="720"
                                        controlsList="nodownload"
                                        :src="videoSource"
                                        type="video/mp4"
                                    >
                                        <p class="vjs-no-js">
                                            To view this video please enable
                                            JavaScript, and consider upgrading
                                            to a web browser that
                                            <a
                                                href="https://videojs.com/html5-video-support/"
                                                target="_blank"
                                            >
                                                supports HTML5 video
                                            </a>
                                        </p>
                                    </video>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <div class="landing-locked-wrapper">
                <b-row>
                    <b-col>
                        <div v-show="contentLocked" class="locked">
                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <h3 class="content">
                                            Content Locked
                                            <span class="fas fa-lock"></span>
                                        </h3>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <p class="content">
                                            Please watch this 2 minute video to
                                            unlock this content.
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div v-show="!contentLocked" class="unlocked">
                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <h3 class="content">
                                            Content Unlocked
                                            <span class="fas fa-unlock"></span>
                                        </h3>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <p class="content">
                                            We have unlocked this offer for you
                                            because you stayed until the end of
                                            the video and we only offer our
                                            spaces to those interested!
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>

                            <!-- step 1 -->
                            <div v-show="!success">
                                <b-row v-show="showCountdown">
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <div
                                                class="content landing-counter"
                                            >
                                                <counter
                                                    ref="landing-counter-1"
                                                    :year="countdown.year"
                                                    :month="countdown.month"
                                                    :day="countdown.day"
                                                    :hour="countdown.hour"
                                                    :minute="countdown.minute"
                                                    :second="countdown.second"
                                                    :millisecond="
                                                        countdown.millisecond
                                                    "
                                                ></counter>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <h3 class="content">
                                                Claim your free week trial by
                                                checking and submitting your
                                                details below!
                                            </h3>
                                        </div>
                                    </b-col>
                                </b-row>

                                <!-- landing form errors 1 -->
                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <div
                                                v-if="hasError"
                                                class="content landing-errors"
                                            >
                                                <Errors :errors="errors" />
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>

                                <!-- landing form 1 -->
                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <b-form
                                                @submit="onSubmit"
                                                class="content landing-form"
                                                v-if="!saving"
                                            >
                                                <b-form-group
                                                    label-class="sr-only"
                                                    label="Your Name:"
                                                    label-for="name"
                                                >
                                                    <b-form-input
                                                        id="name"
                                                        v-model="details.name"
                                                        required
                                                        placeholder="Name"
                                                        v-bind:class="{
                                                            'is-invalid':
                                                                errors.name
                                                        }"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <b-form-group
                                                    label-class="sr-only"
                                                    label="Email address:"
                                                    label-for="email"
                                                >
                                                    <b-form-input
                                                        id="email"
                                                        v-model="details.email"
                                                        type="email"
                                                        placeholder="Email"
                                                        v-bind:class="{
                                                            'is-invalid':
                                                                errors.email
                                                        }"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <b-form-group
                                                    label-class="sr-only"
                                                    label="Contact Number:"
                                                    label-for="contactNumber"
                                                >
                                                    <b-form-input
                                                        id="contactNumber"
                                                        v-model="
                                                            details.contactNumber
                                                        "
                                                        required
                                                        placeholder="Contact Number"
                                                        v-bind:class="{
                                                            'is-invalid':
                                                                errors.contactNumber
                                                        }"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <p>
                                                    By submitting this form you
                                                    are accepting our
                                                    <a
                                                        href="/privacy-policy"
                                                        target="_blank"
                                                        >Privacy Policy</a
                                                    >.
                                                </p>

                                                <b-button
                                                    class="btn-lg btn-submit"
                                                    type="button"
                                                    @click="onSubmit"
                                                    variant="primary"
                                                    >Claim my space
                                                    NOW</b-button
                                                >

                                                <div class="mt-2">
                                                    <a
                                                        href="/"
                                                        class="landing-exit-text"
                                                    >
                                                        I want to miss this
                                                        offer
                                                    </a>
                                                </div>
                                            </b-form>
                                        </div>
                                    </b-col>
                                </b-row>

                                <!-- community & achivement & facility & reviews -->
                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <div
                                                class="content landing-reviews"
                                            >
                                                <b-row>
                                                    <b-col class="mt-3">
                                                        <img
                                                            class="landing-image image-responsive"
                                                            src="/storage/images/landing/community.png"
                                                        />
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="mt-3">
                                                        <img
                                                            class="landing-image image-responsive"
                                                            src="/storage/images/landing/achivement.png"
                                                        />
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="mt-3">
                                                        <img
                                                            class="landing-image image-responsive"
                                                            src="/storage/images/landing/facility.png"
                                                        />
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="mt-3">
                                                        <img
                                                            class="landing-image image-responsive"
                                                            src="/storage/images/landing/elias.png"
                                                        />
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="mt-3">
                                                        <img
                                                            class="landing-image image-responsive"
                                                            src="/storage/images/landing/daniel.png"
                                                        />
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col class="mt-3">
                                                        <img
                                                            class="landing-image image-responsive"
                                                            src="/storage/images/landing/barrie.png"
                                                        />
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row v-show="showCountdown">
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <div
                                                class="content landing-counter"
                                            >
                                                <counter
                                                    ref="landing-counter-2"
                                                    :year="countdown.year"
                                                    :month="countdown.month"
                                                    :day="countdown.day"
                                                    :hour="countdown.hour"
                                                    :minute="countdown.minute"
                                                    :second="countdown.second"
                                                    :millisecond="
                                                        countdown.millisecond
                                                    "
                                                    @expired="countdownExpired"
                                                ></counter>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <h3 class="content">
                                                You can get started as soon as
                                                you want!
                                            </h3>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <div
                                                v-if="hasError"
                                                class="content landing-errors"
                                            >
                                                <Errors :errors="errors" />
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <b-form
                                                @submit="onSubmit"
                                                class="content landing-form"
                                                v-if="!saving"
                                            >
                                                <b-form-group
                                                    label-class="sr-only"
                                                    label="Your Name:"
                                                    label-for="name"
                                                >
                                                    <b-form-input
                                                        id="name"
                                                        v-model="details.name"
                                                        required
                                                        placeholder="Name"
                                                        v-bind:class="{
                                                            'is-invalid':
                                                                errors.name
                                                        }"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <b-form-group
                                                    label-class="sr-only"
                                                    label="Email address:"
                                                    label-for="email"
                                                >
                                                    <b-form-input
                                                        id="email"
                                                        v-model="details.email"
                                                        type="email"
                                                        placeholder="Email"
                                                        v-bind:class="{
                                                            'is-invalid':
                                                                errors.email
                                                        }"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <b-form-group
                                                    label-class="sr-only"
                                                    label="Contact Number:"
                                                    label-for="contactNumber"
                                                >
                                                    <b-form-input
                                                        id="contactNumber"
                                                        v-model="
                                                            details.contactNumber
                                                        "
                                                        required
                                                        placeholder="Contact Number"
                                                        v-bind:class="{
                                                            'is-invalid':
                                                                errors.contactNumber
                                                        }"
                                                    ></b-form-input>
                                                </b-form-group>

                                                <p>
                                                    By submitting this form you
                                                    are accepting our
                                                    <a
                                                        href="/privacy-policy"
                                                        target="_blank"
                                                        >Privacy Policy</a
                                                    >.
                                                </p>

                                                <b-button
                                                    class="btn-lg btn-submit"
                                                    type="button"
                                                    @click="onSubmit"
                                                    variant="primary"
                                                    >Claim my space
                                                    NOW</b-button
                                                >

                                                <div class="mt-2">
                                                    <a
                                                        href="/"
                                                        class="landing-exit-text"
                                                    >
                                                        I want to miss this
                                                        offer
                                                    </a>
                                                </div>
                                            </b-form>
                                        </div>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <button
                                                @click="scrollToTop"
                                                class="btn btn-primary btn-saru-alt"
                                            >
                                                Back to top
                                                <span
                                                    class="fas fa-arrow-up"
                                                ></span>
                                            </button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>

                            <!-- success -->
                            <b-row v-if="success">
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <div
                                                class="d-flex justify-content-center"
                                            >
                                                <h3
                                                    v-show="success"
                                                    class="display-4"
                                                >
                                                    Thank you
                                                </h3>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <div
                                                class="d-flex justify-content-center"
                                            >
                                                <p
                                                    class="thank-you-message"
                                                    v-show="success"
                                                >
                                                    Thank you for filling out
                                                    the form, one of our team
                                                    members will be contacting
                                                    you shortly. If you want
                                                    more updates then follow our
                                                    social media below.
                                                </p>
                                            </div>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <div
                                                class="d-flex justify-content-center"
                                            >
                                                <SocialMediaLinks />
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <LandingFooter />
    </div>
</template>

<script>
import Errors from "../../components/messages/Errors.vue";
import SocialMediaLinks from "../../components/landing/SocialMediaLinks.vue";
import LandingFooter from "@/pages/landing/LandingFooter.vue";

import axios from "axios";
import moment from "moment";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Counter from "../../components/Counter";

export default {
    name: "start-advertising-page",
    components: {
        Errors,
        SocialMediaLinks,
        ClipLoader,
        Counter,
        LandingFooter
    },
    data() {
        return {
            details: {
                name: null,
                email: null,
                contactNumber: null,
                landingPage: "normal"
            },
            step: 0,
            saving: false,
            hasError: false,
            success: false,
            errors: [],
            videoSource: "/storage/videos/landing/landing_video_2.mp4",
            titleImgSource: "/storage/images/landing/header.png",
            contentLocked: true,
            unlockTimer: 1000,
            countdownTimerMins: 1,
            countdown: {
                year: null,
                month: null,
                day: null,
                hour: null,
                minute: null,
                second: null,
                millisecond: null
            },
            showCountdown: true
        };
    },
    methods: {
        /**
         * Switch the view from the orignal form to the video and text.
         */
        nextStep() {
            this.step = 1;

            this.playVideo();

            // unlock the content after 135 seconds
            setTimeout(() => {
                this.startCountdownTimer();
                this.contentLocked = false;
            }, this.unlockTimer);
        },
        /**
         * When the user clicks on 'I want to miss offer' on the modal, hide the
         * modal, play the video and set the timer for the content.
         */
        missOffer() {
            this.step = 1;

            this.playVideo();

            // unlock the content after 135 seconds
            setTimeout(() => {
                this.startCountdownTimer();
                this.contentLocked = false;
            }, this.unlockTimer);
        },
        /**
         * Play the landing video.
         */
        playVideo() {
            var landingVideo = document.getElementById("landing-video");
            landingVideo.play();
        },
        /**
         * When the from submits add enquiry to backend database.
         */
        onSubmit() {
            this.saving = true;
            this.errors = [];
            this.hasError = false;
            this.errors = [];

            // post request to backend
            axios
                .post("/api/landing", this.details)
                .then(res => {
                    // if successful then display success message
                    this.success = true;
                    this.saving = false;
                    this.scrollToTop();
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                    this.saving = false;
                });
        },
        /**
         * Scroll the page to the top.
         */
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        /**
         * Set the countdown timer ready for when content unlocks.
         */
        startCountdownTimer() {
            var currentDate = new Date();
            var countdownDate = moment(currentDate)
                .add(this.countdownTimerMins, "m")
                .toDate();

            this.countdown.year = countdownDate.getFullYear();
            this.countdown.month = countdownDate.getMonth();
            this.countdown.day = countdownDate.getDate();
            this.countdown.hour = countdownDate.getHours();
            this.countdown.minute = countdownDate.getMinutes();
            this.countdown.second = countdownDate.getSeconds();
            this.countdown.millisecond = countdownDate.getMilliseconds();

            this.$refs["landing-counter-1"].showRemaining();
            this.$refs["landing-counter-2"].showRemaining();
        },
        /**
         * When countdown expires redirect to home page?
         */
        countdownExpired() {
            this.showCountdown = false;
        }
    },
    mounted() {
        // set axios base URL
        axios.defaults.baseURL = "/";
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.landing-page-wrapper {
    text-align: center;

    .landing-first-section {
        background-image: url("/storage/images/landing/header.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    // logo
    .landing-logo-wrapper {
        padding: 10px;

        .landing-logo {
            width: 120px;
            height: 110px;
        }
    }

    .step-0-wrapper {
        padding: 10px;
        background-color: $saru-dark-grey;
        color: $saru-white;

        .content {
            min-height: 800px;
            background-color: $saru-teal;
            padding: 30px 10px 30px 10px;
            border-radius: 8px;
        }
    }

    // video
    .landing-video-wrapper {
        padding: 0px 10px 10px 10px;

        .landing-video {
            width: 720px;

            .landing-video-video {
                border-radius: 8px;
            }
        }

        .landing-video-text {
            width: 400px;
            text-align: left;
            margin-top: 15px;
            margin-bottom: 15px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    // locked content
    .landing-locked-wrapper {
        padding: 10px 10px 10px 10px;
        background-color: $saru-dark-grey;
        color: $saru-white;

        .locked {
            min-height: 450px;
            background-color: $saru-teal;
            padding: 30px 10px 30px 10px;
            border-radius: 8px;
        }

        .unlocked {
            min-height: 450px;
            background-color: $saru-teal;
            padding: 30px 10px 30px 10px;
            border-radius: 8px;

            .content {
                width: 600px;
            }
        }
    }

    .landing-form {
        width: 400px;
        padding: 10px;
    }

    .landing-counter {
        padding: 10px;
    }

    .landing-reviews {
        padding: 10px;

        .landing-image {
            border-radius: 8px;
        }

        .image-responsive {
            width: 100%;
        }
    }

    .landing-errors {
        width: 100%;
        padding: 0px 10px 0px 10px;
    }

    .landing-exit-text {
        color: $saru-dark-grey;
    }

    @media (min-width: 576px) {
        .landing-logo {
            width: 120px;
            height: 110px;
        }

        .unlocked .content {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .landing-logo {
            width: 120px;
            height: 110px;
        }

        .unlocked .content {
            width: 500px;
        }
    }

    @media (min-width: 992px) {
        .landing-logo {
            width: 120px;
            height: 110px;
        }

        .unlocked .content {
            width: 600px;
        }
    }

    .errors {
        width: 500px;
    }

    .thank-you-message {
        width: 300px;
    }

    .btn-submit {
        width: 100%;
        background-color: $saru-dark-grey;
        color: $saru-white;
        font-family: Marker Felt, fantasy;
    }

    .btn-saru-alt {
        background-color: $saru-white;
        color: $saru-dark-grey;
        border-color: $saru-white;
        font-family: Marker Felt, fantasy;
        border-radius: 8px;
        font-size: 14pt;
    }

    .btn-saru-alt:hover {
        background-color: $saru-dark-grey;
        color: white;
        border-color: $saru-dark-grey;
    }
}
</style>
