import axios from "axios";

export default {
    /**
     * Get all questions.
     */
    all() {
        return axios.get("/questions");
    },

    /**
     * Get a question.
     *
     * @param {Int} id
     */
    find(id) {
        return axios.get(`/question/${id}`);
    },
    
    /**
     * Store a new question.
     * 
     * @param {Object} data 
     * @returns 
     */
    store(data) {
        return axios.post(`/question/add`, data);
    },

    /**
     * Update a question.
     *
     * @param {Int} id
     * @param {Object} data
     */
    update(id, data) {
        return axios.put(`/question/${id}/edit`, data);
    },
    
    /**
     * Deletes a question.
     * 
     * @param {Int} id 
     */
    destroy(id) {
        return axios.post(`/question/${id}/delete`);
    }
};
