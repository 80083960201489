import axios from "axios";

export default {
    find(id) {
        return axios.get(`/classes/${id}`);
    },
    all() {
        return axios.get("/classes/");
    },
    videoGroupData(classType) {
        return axios.get("/classes/videos/" + classType);
    }
};
