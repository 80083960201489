<template>
    <div class="enquire-form-wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <h3 v-show="!success" class="display-4">
                        Get in touch
                    </h3>
                    <h4 v-show="success" class="display-4">Thank you</h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <p class="enquire-subtitle" v-show="!success">
                        Fill out the short form below and one of our team will
                        contact you.
                    </p>
                    <p class="enquire-subtitle" v-show="success">
                        Thank you for filling out the form, one of our team
                        members will be contacting you shortly. If you want more
                        updates then follow our social media below.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <div class="enquire-form" v-if="hasError">
                        <Errors :errors="errors" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <!-- loading spinner -->
                    <div v-if="saving">
                        <clip-loader class="loading-spinner"></clip-loader>
                    </div>

                    <b-form
                        v-show="!success && !saving"
                        class="enquire-form"
                        @submit="onSubmit"
                    >
                        <b-form-group>
                            <b-form-select
                                id="gym"
                                v-model="enquire.gym"
                                :options="helpOptions"
                                v-bind:class="{
                                    'is-invalid': errors.gym
                                }"
                            >
                                <b-form-select-option :value="null" disabled
                                    >Which gym are you contacting us
                                    about?</b-form-select-option
                                >
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                            v-show="enquire.help !== null"
                            label-class="sr-only"
                            label="Your Name:"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="enquire.name"
                                required
                                placeholder="Name"
                                v-bind:class="{
                                    'is-invalid': errors.name
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            v-show="enquire.help !== null"
                            label-class="sr-only"
                            label="Email address:"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="enquire.email"
                                type="email"
                                placeholder="Email"
                                v-bind:class="{
                                    'is-invalid': errors.email
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            v-show="enquire.help !== null"
                            label-class="sr-only"
                            label="Contact Number:"
                            label-for="contact_number"
                        >
                            <b-form-input
                                id="contact_number"
                                v-model="enquire.contact_number"
                                required
                                placeholder="Contact Number"
                                v-bind:class="{
                                    'is-invalid': errors.contact_number
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <p>
                            By submitting this form you are accepting our
                            <a href="/privacy-policy" target="_blank"
                                >Privacy Policy</a
                            >.
                        </p>

                        <div class="btn-submit">
                            <b-button
                                class="btn-lg"
                                v-show="enquire.help !== null"
                                type="button"
                                @click="onSubmit"
                                variant="primary"
                                >Submit</b-button
                            >
                        </div>
                    </b-form>
                </div>
            </div>
        </div>

        <SocialMediaLinks />
    </div>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Errors from "../components/messages/Errors.vue";
import SocialMediaLinks from "../components/SocialMediaLinks.vue";

export default {
    name: "contact-form-page",
    components: {
        ClipLoader,
        Errors,
        SocialMediaLinks
    },
    props: {
        baseUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            enquire: {
                gym: null,
                name: null,
                email: null,
                contact_number: null
            },
            saving: false,
            hasError: false,
            success: false,
            errors: []
        };
    },
    computed: {
        helpOptions() {
            return [
                {
                    value: "Swansea",
                    text: "Swansea (YMCA)"
                },
                {
                    value: "Neath",
                    text: "Neath"
                }
            ];
        }
    },
    methods: {
        /**
         * When the from submits add enquiry to backend database.
         */
        onSubmit() {
            this.saving = true;

            // post request to backend
            axios
                .post("/contact-us", this.enquire)
                .then(res => {
                    // if successful then display success message
                    this.success = true;
                    this.hasError = false;
                    this.errors = [];
                    this.saving = false;
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                    this.saving = false;
                });
        }
    },
    mounted() {
        // set axios base URL
        axios.defaults.baseURL = "/";
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.enquire-form-wrapper {
    text-align: center;

    margin-top: 100px;
    margin-bottom: 100px;

    .enquire-subtitle {
        width: 300px;
    }

    @media (min-width: 576px) {
        margin-top: 100px;
        margin-bottom: 100px;

        .enquire-form {
            width: 400px;
        }

        .errors {
            width: 360px;
        }
    }

    @media (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 200px;

        .enquire-form {
            width: 400px;
        }

        .errors {
            width: 100%;
        }
    }

    @media (min-width: 992px) {
        margin-top: 150px;
        margin-bottom: 350px;

        .enquire-form {
            width: 400px;
        }

        .errors {
            width: 400px;
        }
    }

    .errors {
        width: 400px;
    }

    .enquire-form {
        .btn-submit {
            padding: 10px;
            font-family: Marker Felt, fantasy;

            button {
                width: 100%;
            }

            button:hover {
                color: $saru-teal;
            }
        }
    }
}
</style>
