<template>
    <b-container fluid>
        <backButton></backButton>

        <h2>Questions</h2>

        <p>
            These are the current FAQ questions displayed on the website. You
            can add, edit and delete the questions in the table below:
        </p>

        <questionIndexTable></questionIndexTable>
    </b-container>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";
import questionIndexTable from "@/membership_hub/components/question/IndexTable.vue";

export default {
    name: "questions",
    components: {
        backButton,
        questionIndexTable
    },
    data() {
        return {
            showAddModal: false
        };
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.btn-back {
    background-color: $saru-dark-grey;
    color: $saru-white;
    font-family: Marker Felt, fantasy;
}
</style>
