var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"events-wrapper"},[_c('b-row',[_c('b-col',[_c('backButton')],1)],1),_vm._v(" "),_c('b-row',[_c('b-col',[_c('h2',[_vm._v("Events")])])],1),_vm._v(" "),_c('b-alert',{attrs:{"variant":"success","show":_vm.successMessage != null}},[_c('p',{staticClass:"success-message"},[_vm._v("\n            "+_vm._s(_vm.successMessage)+"\n        ")])]),_vm._v(" "),_c('b-row',[_c('b-col',[_c('div',{staticClass:"text-center mb-2"},[_c('v-calendar',{staticClass:"events-calendar",attrs:{"masks":_vm.masks,"attributes":_vm.events,"disable-page-swipe":"","is-expanded":""},scopedSlots:_vm._u([{key:"day-content",fn:function({ day, attributes }){return [_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(_vm._s(day.day))]),_vm._v(" "),_c('div',{staticClass:"flex-grow"},_vm._l((attributes),function(event){return _c('div',{key:event.key,staticClass:"p-1 mb-1",class:'events-calendar-event ' +
                                            event.customData.class,on:{"click":function($event){return _vm.viewEventModal(event.key)}}},[_c('span',{staticClass:"events-calendar-event-title"},[_vm._v("\n                                        "+_vm._s(event.customData.title)+"\n                                    ")])])}),0)])]}}])})],1)])],1),_vm._v(" "),_c('b-row',[_c('b-col',[(
                    _vm.$auth.user().role == 2 &&
                        _vm.$auth.user().allowed_access == 1
                )?_c('b-button-toolbar',{staticClass:"mb-2",attrs:{"key-nav":"","aria-label":"Toolbar with action buttons for events."}},[_c('b-button-group',{staticClass:"mx-1"},[_c('b-button',{on:{"click":function($event){return _vm.addEventModal()}}},[_vm._v("Add Event")])],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('b-modal',{attrs:{"id":"add-event-modal","title":"Add Event","ok-variant":"success","ok-title":"Add"},on:{"ok":_vm.handleAddEvent,"cancel":_vm.handleCancelEvent},model:{value:(_vm.modal.add.show),callback:function ($$v) {_vm.$set(_vm.modal.add, "show", $$v)},expression:"modal.add.show"}},[(Object.keys(_vm.modal.add.errors).length > 0)?_c('div',{ref:"addModalErrors",attrs:{"id":"add-modal-errors"}},[_c('Errors',{attrs:{"errors":_vm.modal.add.errors}})],1):_vm._e(),_vm._v(" "),_c('b-form-group',[_c('b-form-select',{class:{
                    'is-invalid': _vm.modal.add.errors.event_type
                },attrs:{"id":"modal.add.form.event_type","options":_vm.eventTypes},model:{value:(_vm.modal.add.form.event_type),callback:function ($$v) {_vm.$set(_vm.modal.add.form, "event_type", $$v)},expression:"modal.add.form.event_type"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Event type")])],1)],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Event date:","label-for":"modal.add.form.date"}},[_c('datetime',{attrs:{"type":"date","format":"dd/LL/yyyy","input-id":"modal.add.form.date","title":"Event date","flow":['date'],"input-class":{
                    'form-control is-invalid': _vm.modal.add.errors.date,
                    'form-control': !_vm.modal.add.errors.date
                }},model:{value:(_vm.modal.add.form.date),callback:function ($$v) {_vm.$set(_vm.modal.add.form, "date", $$v)},expression:"modal.add.form.date"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label-class":"sr-only","label":"Title:","label-for":"modal.add.form.title"}},[_c('b-form-textarea',{class:{
                    'is-invalid': _vm.modal.add.errors.title
                },attrs:{"id":"modal.add.form.title","placeholder":"Enter a title...","rows":"2","max-rows":"2"},model:{value:(_vm.modal.add.form.title),callback:function ($$v) {_vm.$set(_vm.modal.add.form, "title", $$v)},expression:"modal.add.form.title"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Event details:","label-for":"modal.add.form.details"}},[_c('ckeditor',{attrs:{"id":"ckeditor-add","config":_vm.editorConfig,"input-class":{
                    'form-control is-invalid':
                        _vm.modal.add.errors.details,
                    'form-control': !_vm.modal.add.errors.details
                }},model:{value:(_vm.modal.add.form.details),callback:function ($$v) {_vm.$set(_vm.modal.add.form, "details", $$v)},expression:"modal.add.form.details"}})],1)],1),_vm._v(" "),_c('b-modal',{attrs:{"id":"view-event-modal","title":_vm.modal.view.edit ? 'Edit Event' : ''},on:{"ok":_vm.handleViewEvent},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok, cancel }){return [(!_vm.modal.view.edit)?_c('span',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("\n                    Cancel\n                ")]),_vm._v(" "),(
                        _vm.$auth.user().role == 2 &&
                            _vm.$auth.user().allowed_access == 1
                    )?_c('b-button',{attrs:{"variant":"info"},on:{"click":function($event){_vm.modal.view.edit = true}}},[_vm._v("\n                    Edit\n                ")]):_vm._e(),_vm._v(" "),(
                        _vm.$auth.user().role == 2 &&
                            _vm.$auth.user().allowed_access == 1
                    )?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return ok()}}},[_vm._v("\n                    Delete\n                ")]):_vm._e()],1):_c('span',[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("\n                    Cancel\n                ")]),_vm._v(" "),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return ok()}}},[_vm._v("\n                    Save\n                ")])],1)]}}]),model:{value:(_vm.modal.view.show),callback:function ($$v) {_vm.$set(_vm.modal.view, "show", $$v)},expression:"modal.view.show"}},[(_vm.modal.view.edit)?_c('div',[(Object.keys(_vm.modal.view.errors).length > 0)?_c('div',[_c('Errors',{attrs:{"errors":_vm.modal.view.errors}})],1):_vm._e(),_vm._v(" "),_c('b-form-group',[_c('b-form-select',{class:{
                        'is-invalid': _vm.modal.view.errors.event_type
                    },attrs:{"id":"modal.view.form.event_type","options":_vm.eventTypes,"disabled":!_vm.modal.view.edit},model:{value:(_vm.modal.view.form.event_type),callback:function ($$v) {_vm.$set(_vm.modal.view.form, "event_type", $$v)},expression:"modal.view.form.event_type"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Event type")])],1)],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Event date:","label-for":"modal.view.form.date"}},[_c('datetime',{attrs:{"type":"date","format":"dd/LL/yyyy","input-id":"modal.add.form.date","title":"Event date","flow":['date'],"input-class":{
                        'form-control is-invalid': _vm.modal.view.errors.date,
                        'form-control': !_vm.modal.view.errors.date
                    }},model:{value:(_vm.modal.view.form.date),callback:function ($$v) {_vm.$set(_vm.modal.view.form, "date", $$v)},expression:"modal.view.form.date"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label-class":"sr-only","label":"Event:","label-for":"modal.view.form.title"}},[_c('b-form-textarea',{class:{
                        'is-invalid': _vm.modal.view.errors.title
                    },attrs:{"id":"modal.view.form.title","placeholder":"Enter a title...","rows":"2","max-rows":"2","disabled":!_vm.modal.view.edit},model:{value:(_vm.modal.view.form.title),callback:function ($$v) {_vm.$set(_vm.modal.view.form, "title", $$v)},expression:"modal.view.form.title"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Event details:","label-for":"modal.view.form.details"}},[_c('ckeditor',{attrs:{"id":"ckeditor-edit","config":_vm.editorConfig,"input-class":{
                        'form-control is-invalid':
                            _vm.modal.view.errors.details,
                        'form-control': !_vm.modal.view.errors
                            .details
                    },"disabled":!_vm.modal.view.edit},model:{value:(_vm.modal.view.form.details),callback:function ($$v) {_vm.$set(_vm.modal.view.form, "details", $$v)},expression:"modal.view.form.details"}})],1)],1):_c('div',[_c('h5',[_vm._v("\n                "+_vm._s(_vm.modal.view.form.event_type_type +
                        " - " +
                        _vm.modal.view.form.title)+"\n            ")]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.modal.view.form.details)}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }