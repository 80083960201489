<template>
    <div>
        <div class="intro-wrapper">
            <div class="jumbotron">
                <div class="container">
                    <h1 class="display-4">Locations</h1>
                    <hr class="saru-hr" />
                </div>
            </div>
        </div>

        <div class="affilliates-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="affilliate-heading">
                            Saru West Cross
                            <a
                                href="https://www.facebook.com/sarujujitsuwestcross/"
                                target="_blank"
                                ><i class="fab fa-facebook-square"></i
                            ></a>
                        </h2>
                        <hr class="saru-hr" />
                        <p>
                            <b>Address:</b><br />
                            Linden Church<br />
                            Elmgrove Road<br />
                            West Cross<br />
                            Swansea<br />
                            SA3 5LD
                        </p>
                        <p>
                            03332 423919
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h2 class="affilliate-heading">
                            Saru Neath
                        </h2>
                        <hr class="saru-hr" />
                        <p>
                            <b>Address:</b><br />
                            5 Wind Street<br />
                            Neath<br />
                            SA11 3GE
                        </p>
                        <p>
                            03332 423919
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "locations-page"
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.affilliates-wrapper {
    padding-top: 15px;
    background-color: $saru-white;
}

.affilliate-heading {
    a {
        margin-left: 5px;
        color: $saru-dark-grey;
    }
    a:hover {
        color: $saru-teal;
    }
}
</style>
