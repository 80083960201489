<template>
    <div class="d-flex justify-content-center">
        <div class="errors alert alert-danger">
            <div v-if="errors">
                <div v-for="(v, k) in errors" :key="k">
                    <p class="error-message" v-for="error in v" :key="error">
                        {{ error }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "error-messages",
    props: {
        errors: {
            required: false,
            type: Array | Object
        }
    }
};
</script>

<style lang="scss" scoped>
.errors {
    width: 100%;
}

.error-message {
    margin-bottom: 0px;
    padding: 0px;
}
</style>
