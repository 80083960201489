<template>
    <div class="user-index-table-wrapper">
        <div class="alert alert-success" role="alert" v-if="successMessage">
            <p class="success-message">
                {{ successMessage }}
            </p>
        </div>

        <v-client-table
            v-if="newStarters.length > 0"
            :columns="columns"
            :data="newStarters"
            :options="options"
        >
            <div slot="date_of_birth" slot-scope="props">
                {{ formatDate(props.row.date_of_birth) }}
            </div>

            <div slot="created_at" slot-scope="props">
                {{ formatDateTime(props.row.created_at) }}
            </div>

            <div slot="download" slot-scope="props">
                <i
                    @click="download(props.row)"
                    class="fas fa-download download-icon"
                ></i>
            </div>
        </v-client-table>

        <b-button-toolbar
            class="mb-2"
            key-nav
            aria-label="Toolbar with action buttons for new starters."
        >
            <b-button-group class="mx-1">
                <b-button @click="getNewStarters">Refresh</b-button>
            </b-button-group>
        </b-button-toolbar>
    </div>
</template>

<script>
import api from "@/api/new-starters";

import errors from "@/components/layout/Errors.vue";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import download from "downloadjs";
import moment from "moment";

export default {
    name: "users-index-table",
    components: {
        errors,
        ClipLoader
    },
    data() {
        return {
            errors: [],
            newStarters: [],
            busy: false,
            successMessage: null,
            paginationData: null,
            columns: [
                "name",
                "email",
                "date_of_birth",
                "contact_number",
                "gym",
                "created_at",
                "download"
            ],
            options: {
                skin:
                    "new-starter-index-table table table-striped table-bordered table-hover",
                headings: {
                    created_at: "Submitted at",
                    download: ""
                },
                texts: {
                    count: "",
                    filter: "",
                    filterPlaceholder: "Search"
                }
            }
        };
    },
    mounted() {
        this.getNewStarters();
    },
    methods: {
        /**
         *  Get the users data for the table from the backend.
         */
        async getNewStarters() {
            api.all().then(response => {
                this.newStarters = response.data.newStarters;
            });
        },

        /**
         * Download a pdf of the new starter form.
         * @param newStarter
         */
        download(newStarter) {
            api.download(newStarter.id).then(response => {
                const content = response.headers["content-type"];

                download(
                    response.data,
                    this.getDownloadFileName(newStarter),
                    content
                );
            });
        },

        /**
         * Get the file name for the new starter download.
         * @param newStarter
         * @returns
         */
        getDownloadFileName(newStarter) {
            return (
                newStarter.name.replace(" ", "_").toLowerCase() +
                "_new_starter_form"
            );
        },

        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },

        formatDateTime(date) {
            return moment(date).format("DD/MM/YYYY hh:mm");
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.new-starter-index-table {
    .btn-submit {
        width: 100%;
        background-color: $saru-dark-grey;
        color: $saru-white;
        font-family: Marker Felt, fantasy;
    }

    .success-message {
        margin-bottom: 0px;
        padding: 0px;
    }

    .download-icon {
        cursor: pointer;
    }
}
</style>
