<template>
    <div class="landing-footer-wrapper">
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col>
                    <img
                        class="landing-logo"
                        src="/storage/images/saru_logo.png"
                        alt="SJA Martial Arts Logo"
                    />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "landing-footer-wrapper"
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.landing-footer-wrapper {
    background-color: $saru-dark-grey;
    padding: 20px 0px 40px 0px;

    .landing-logo {
        width: 120px;
        height: 110px;
    }
}
</style>
