<template>
    <div @click="$emit('set-current-video', video)">
        <img
            class="image-responsive video-card-thumbnail"
            :src="video.thumbnail"
            :alt="video.title"
        />
        <div class="card-body">
            <h5 class="card-title">{{ video.title }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    props: ["video"]
};
</script>

<style lang="scss" scoped>
.video-card-thumbnail {
    width: 100%;
}
</style>
