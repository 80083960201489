<template>
    <div class="faq-wrapper">
        <b-container>
            <b-row class="text-center justify-content-md-center mt-3">
                <b-col cols="12" md="8">
                    <h1>Frequently Asked Questions</h1>
                    <p>
                        Below you will find answers to the questions we get
                        asked the most.
                    </p>
                </b-col>
            </b-row>
            <b-row class="text-center justify-content-md-center mt-3 mb-3">
                <b-col cols="12" md="8">
                    <div class="accordion" role="tablist">
                        <b-card
                            no-body
                            class="mb-1"
                            v-for="question in questions"
                            :key="question.id"
                        >
                            <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                            >
                                <b-button
                                    block
                                    v-b-toggle="'accordion-' + question.id"
                                    variant="primary"
                                    >{{ question.question }}</b-button
                                >
                            </b-card-header>
                            <b-collapse
                                :id="'accordion-' + question.id"
                                accordion="my-accordion"
                                role="tabpanel"
                            >
                                <b-card-body>
                                    <b-card-text>{{
                                        question.answer
                                    }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "faq-page",
    props: {
        questions: {
            required: true,
            type: Array
        }
    },
    data() {
        return {};
    }
};
</script>

<style style="scss" scoped>
.faq-wrapper {
}
</style>
