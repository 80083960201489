import bearer from "@websanova/vue-auth/drivers/auth/bearer";
import axios from "@websanova/vue-auth/drivers/http/axios.1.x";
import router from "@websanova/vue-auth/drivers/router/vue-router.2.x";

// Auth base configuration some of this options
// can be override in method calls
const config = {
    auth: bearer,
    http: axios,
    router: router,
    tokenDefaultName: "saru-jiu-jitsu-website",
    tokenStore: ["localStorage"],
    rolesVar: "role",
    // registerData: { url: "auth/register", method: "POST", redirect: "/login" },
    loginData: {
        url: "auth/login",
        method: "POST",
        redirect: "",
        fetchUser: true,
        staySignedIn: true
    }
    // logoutData: {
    //     url: "auth/logout",
    //     method: "POST",
    //     redirect: "/",
    //     makeRequest: true
    // },
    // fetchData: { url: "auth/me", method: "POST", enabled: true },
    // refreshData: {
    //     url: "auth/refresh",
    //     method: "POST",
    //     enabled: true,
    //     interval: 30
    // }
};

export default config;
