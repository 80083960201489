<template>
    <div class="enquire-form-wrapper">
        <!-- title -->
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <h4 v-show="!success" class="display-4">
                        New Starter Form
                    </h4>
                    <h4 v-show="success" class="display-4">Thank you</h4>
                </div>
            </div>
        </div>

        <!-- intro & success message -->
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <p class="enquire-subtitle" v-show="!success && !busy">
                        Welcome to SJA Martial Arts, to get started please
                        fill out the form below.
                    </p>
                    <p class="enquire-subtitle" v-show="success">
                        Thank you for filling out the form! Please let someone
                        at the desk know you've completed it.
                    </p>
                </div>
            </div>
        </div>

        <!-- errors -->
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <div
                        class="errors alert alert-danger"
                        v-if="Object.keys(errors).length > 0"
                    >
                        <div v-if="errors">
                            <div v-for="(v, k) in errors" :key="k">
                                <p
                                    class="error-message"
                                    v-for="error in v"
                                    :key="error"
                                >
                                    {{ error }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- form -->
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <b-form
                        v-show="!success"
                        class="enquire-form"
                        @submit.prevent="onSubmit"
                    >
                        <b-form-group>
                            <b-form-select
                                id="gym"
                                v-model="newStarterForm.gym"
                                :options="helpOptions"
                                v-bind:class="{
                                    'is-invalid': errors.gym
                                }"
                            >
                                <b-form-select-option :value="null" disabled
                                    >Which gym are you
                                    joining?</b-form-select-option
                                >
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Your Name:"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="newStarterForm.name"
                                placeholder="Name"
                                v-bind:class="{
                                    'is-invalid': errors.name
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label="Date of birth:"
                            label-for="dateOfBirth"
                        >
                            <datetime
                                v-model="newStarterForm.dateOfBirth"
                                type="datetime"
                                format="dd/LL/yyyy"
                                input-id="dateOfBirth"
                                title="Date of birth"
                                :flow="['year', 'month', 'date']"
                                :max-datetime="new Date().toISOString()"
                                v-bind:input-class="{
                                    'form-control is-invalid':
                                        errors.dateOfBirth,
                                    'form-control': !errors.dateOfBirth
                                }"
                            ></datetime>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Email address:"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="newStarterForm.email"
                                type="email"
                                placeholder="Email"
                                v-bind:class="{
                                    'is-invalid': errors.email
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Contact Number:"
                            label-for="contactNo"
                        >
                            <b-form-input
                                id="contactNo"
                                v-model="newStarterForm.contactNo"
                                placeholder="Contact Number"
                                v-bind:class="{
                                    'is-invalid': errors.contactNo
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Emergency Contact Name:"
                            label-for="emergencyContactName"
                        >
                            <b-form-input
                                id="emergencyContactName"
                                v-model="newStarterForm.emergencyContactName"
                                placeholder="Emergency Contact Name"
                                v-bind:class="{
                                    'is-invalid': errors.emergencyContactName
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Emergency Contact Number:"
                            label-for="emergencyContactNo"
                        >
                            <b-form-input
                                id="emergencyContactNo"
                                v-model="newStarterForm.emergencyContactNo"
                                placeholder="Emergency Contact Number"
                                v-bind:class="{
                                    'is-invalid': errors.emergencyContactNo
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Medical Conditions:"
                            label-for="medicalConditions"
                        >
                            <b-form-textarea
                                id="medicalConditions"
                                v-model="newStarterForm.medicalConditions"
                                placeholder="Please note here any medical conditions you suffer with or any drugs/treatment that the academy should be aware of."
                                rows="4"
                                no-resize
                                v-bind:class="{
                                    'is-invalid': errors.medicalConditions
                                }"
                            ></b-form-textarea>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Injuries:"
                            label-for="injuries"
                        >
                            <b-form-textarea
                                id="injuries"
                                v-model="newStarterForm.injuries"
                                placeholder="Please note here any injuries that the academy should be aware of and give a brief description."
                                rows="4"
                                no-resize
                                v-bind:class="{
                                    'is-invalid': errors.injuries
                                }"
                            ></b-form-textarea>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Accept risk of injury:"
                            label-for="riskOfInjury"
                        >
                            <b-form-checkbox
                                id="riskOfInjury"
                                v-model="newStarterForm.riskOfInjury"
                                value="accepted"
                                unchecked-value="not_accepted"
                                v-bind:class="{
                                    'is-invalid': errors.riskOfInjury
                                }"
                            >
                                I accept that martial arts involes a risk of
                                injury.
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group
                            label="During classes we often take photographs or videos for promotional use including the class dogo app, our website and Facebook."
                            label-for="photos"
                        >
                            <b-form-checkbox
                                id="photos"
                                v-model="newStarterForm.photos"
                                value="opt_out"
                                unchecked-value="opt_in"
                                v-bind:class="{
                                    'is-invalid': errors.photos
                                }"
                            >
                                I would like to <b>OPT OUT</b> of this
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group
                            label="Please sign below:"
                            label-for="signature"
                        >
                            <VueSignaturePad
                                class="signature-pad"
                                width="100%"
                                height="150px"
                                ref="signaturePad"
                                v-bind:class="{
                                    'is-invalid': errors.signature
                                }"
                            />
                            <div class="signature-buttons">
                                <b-button @click="saveSignature">Save</b-button>
                                <b-button @click="undoSignature">Undo</b-button>
                            </div>
                        </b-form-group>

                        <p class="privacy-policy-text">
                            By submitting this form you are accepting our
                            <a href="/privacy-policy" target="_blank"
                                >Privacy Policy</a
                            >.
                        </p>

                        <div class="btn-submit">
                            <b-button
                                class="btn-submit"
                                :disabled="busy"
                                type="submit"
                                variant="primary"
                                size="lg"
                            >
                                <span v-show="busy">
                                    <b-spinner
                                        small
                                        label="Loading..."
                                    ></b-spinner>
                                    <span class="ml-1">
                                        Loading...
                                    </span>
                                </span>

                                <span v-show="!busy">
                                    Submit
                                </span>
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "new-starter-form",
    components: {},
    props: {
        baseUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            newStarterForm: {
                gym: null,
                name: null,
                dateOfBirth: null,
                email: null,
                contactNo: null,
                emergencyContactName: null,
                emergencyContactNo: null,
                medicalConditions: null,
                injuries: null,
                riskOfInjury: null,
                photos: "opt_in",
                signature: null
            },
            success: false,
            errors: {},
            busy: false
        };
    },
    computed: {
        helpOptions() {
            return [
                {
                    value: "Swansea",
                    text: "Swansea (YMCA)"
                },
                {
                    value: "Neath",
                    text: "Neath"
                }
            ];
        }
    },
    methods: {
        /**
         * When the from submits add enquiry to backend database
         */
        onSubmit() {
            this.busy = true;
            this.errors = {};

            // post request to backend
            axios
                .post("/new-starter", this.newStarterForm)
                .then(res => {
                    // if successful then display success message
                    this.success = true;

                    // refresh page after 5 seconds
                    setTimeout(function() {
                        window.location.href = "/new-starter";
                    }, 5000);
                })
                .catch(e => {
                    this.errors = e.response.data.errors;
                })
                .then(_ => {
                    this.busy = false;
                    this.scrollToTop();
                });
        },
        /**
         * Store the signature in the new starter form request data.
         */
        saveSignature() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (!isEmpty) {
                this.newStarterForm.signature = data;
            }
        },
        /**
         * Undo the previous action on the signature pad.
         */
        undoSignature() {
            this.$refs.signaturePad.undoSignature();
        },
        /**
         * Scroll the page to the top.
         */
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    mounted() {
        // set axios base URL
        axios.defaults.baseURL = "/";
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.enquire-form-wrapper {
    text-align: center;

    margin-top: 100px;
    margin-bottom: 100px;

    .error-message {
        margin-bottom: 0px;
        padding: 0px;
    }

    .enquire-subtitle {
        width: 300px;
    }

    @media (min-width: 576px) {
        margin-top: 100px;
        margin-bottom: 100px;

        .enquire-form {
            width: 100%;
        }

        .errors {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 200px;

        .enquire-form {
            width: 600px;
        }

        .errors {
            width: 600px;
        }
    }

    @media (min-width: 992px) {
        margin-top: 150px;
        margin-bottom: 350px;

        .enquire-form {
            width: 600px;
        }

        .errors {
            width: 600px;
        }
    }

    .errors {
        padding: 10px 20px 10px 20px;
    }

    .enquire-form {
        padding: 0px 20px 10px 20px;
        text-align: left;

        .btn-submit {
            button {
                width: 100%;
                font-family: Marker Felt, fantasy;
            }

            button:hover {
                color: $saru-teal;
            }
        }
    }

    .enquire-social-media-links {
        font-size: 28pt;
        a {
            margin-left: 5px;
            color: $saru-dark-grey;
        }
        a:hover {
            color: $saru-teal;
        }
    }

    .signature-pad {
        width: 100%;
        height: 150px;
        background-color: $saru-white;
        border-style: solid;
        border-width: 1px;
        border-color: #ced4da;
        margin-bottom: 10px;
    }

    .signature-buttons {
        text-align: right;
        font-family: Marker Felt, fantasy;
    }

    .privacy-policy-text {
        text-align: center;
    }

    .loading-spinner {
        margin-top: 50px;
    }
}
</style>
