<template>
    <b-container fluid>
        <backButton></backButton>

        <h2>New Starters</h2>

        <newStarterIndexTable></newStarterIndexTable>
    </b-container>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";
import newStarterIndexTable from "@/membership_hub/components/new_starter/IndexTable.vue";

export default {
    name: "members",
    components: {
        backButton,
        newStarterIndexTable
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.btn-back {
    background-color: $saru-dark-grey;
    color: $saru-white;
    font-family: Marker Felt, fantasy;
}
</style>
