<template>
  <div class="new-starter-review-create-wrapper">
      <!-- title -->
      <b-row>
          <b-col>
              <div class="d-flex justify-content-center">
                    <h4 v-show="!success" class="display-4">
                        Rate your first class
                    </h4>
                    <h4 v-show="success" class="display-4">Thank you</h4>
                </div>
          </b-col>
      </b-row>

      <!-- intro & success message -->
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <div class="subtitle">
                        <p class="enquire-subtitle" v-show="!success && !busy">
                            We really hope you enjoyed, we will be ever so grateful if you could fill out the review form below about your first class with us!
                        </p>
                        <p class="enquire-subtitle" v-show="success">
                            Thank you for so much for giving us your feedback! You will be now be redirected to our home page.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- errors -->
        <b-row>
            <b-col>
                <div class="d-flex justify-content-center">
                    <div
                        class="errors alert alert-danger"
                        v-if="Object.keys(errors).length > 0"
                    >
                        <div v-if="errors">
                            <div v-for="(v, k) in errors" :key="k">
                                <p
                                    class="error-message"
                                    v-for="error in v"
                                    :key="error"
                                >
                                    {{ error }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <div class="d-flex justify-content-center">
                    <b-form
                        v-show="!success"
                        class="new-starter-review-create-form"
                        @submit.prevent="onSubmit"
                    >
                        <b-form-group>
                            <b-form-select
                                id="gym"
                                v-model="form.gym"
                                :options="gymOptions"
                                v-bind:class="{
                                    'is-invalid': errors.gym
                                }"
                            >
                                <b-form-select-option :value="null" disabled>
                                    Which gym did you attend?
                                </b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <b-form-group
                                label="Please enter which class and date you attended:"
                                label-for="class_date"
                        >
                            <b-form-input
                                id="class_date"
                                v-model="form.class_date"
                                placeholder="e.g. BJJ dd/mm/yyyy"
                                v-bind:class="{
                                    'is-invalid': errors.class_date
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                                label-class="sr-only"
                                label="Name:"
                                label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                placeholder="Name"
                                v-bind:class="{
                                    'is-invalid': errors.name
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Email address:"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                v-model="form.email"
                                type="email"
                                placeholder="Email"
                                v-bind:class="{
                                    'is-invalid': errors.email
                                }"
                            ></b-form-input>
                        </b-form-group>     

                        <b-form-group
                            label="Tell us a bit about your experience:"
                            label-for="experience"
                        >
                            <b-form-textarea
                                id="experience"
                                v-model="form.experience"
                                rows="4"
                                no-resize
                                v-bind:class="{
                                    'is-invalid': errors.experience
                                }"
                            ></b-form-textarea>
                        </b-form-group>

                        <b-form-group label="Please rate your experience with our admin team out of 5:">
                            <b-form-radio-group
                                id="admin_rating"
                                v-model="form.admin_rating"
                                :options="ratingOptions"
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-form-group label="Please rate your experience with our coaches out of 5:">
                            <b-form-radio-group
                                id="coaching_rating"
                                v-model="form.coaching_rating"
                                :options="ratingOptions"
                                name="coaching_rating"
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-form-group label="Please rate your experience with our social media accounts out of 5:">
                            <b-form-radio-group
                                id="social_media_rating"
                                v-model="form.social_media_rating"
                                :options="ratingOptions"
                                name="social_media_rating"
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-form-group label="Would you recommend us?:">
                            <b-form-radio-group
                                id="would_recommend"
                                v-model="form.would_recommend"
                                :options="yesNoOptions"
                                name="would_recommend"
                            ></b-form-radio-group>
                        </b-form-group>

                        <b-form-group label="How did you hear about us?:">
                            <b-form-select 
                                id="how_did_you_hear_about_us_option_id"
                                v-model="form.how_did_you_hear_about_us_option_id" 
                                :options="howDidYouHearAboutUsOptions"
                                v-bind:class="{
                                    'is-invalid': errors.how_did_you_hear_about_us_option_id
                                }"
                            >
                            </b-form-select>
                        </b-form-group>

                        <b-form-group v-if="otherOptionSelected">
                            <b-form-input
                                id="how_did_you_hear_about_us_option_other"
                                v-model="form.how_did_you_hear_about_us_option_other"
                                type="text"
                                placeholder="Other"
                                v-bind:class="{
                                    'is-invalid': errors.how_did_you_hear_about_us_option_other
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Have you signed up?:">
                            <b-form-radio-group
                                id="signed_up"
                                v-model="form.signed_up"
                                :options="yesNoOptions"
                                name="signed_up"
                            ></b-form-radio-group>
                        </b-form-group>

                        <p class="privacy-policy-text">
                            By submitting this form you are accepting our
                            <a href="/privacy-policy" target="_blank"
                                >Privacy Policy</a
                            >.
                        </p>

                        <div class="btn-submit">
                            <b-button
                                class="btn-submit"
                                :disabled="busy"
                                type="submit"
                                variant="primary"
                                size="lg"
                            >
                                <span v-show="busy">
                                    <b-spinner
                                        small
                                        label="Loading..."
                                    ></b-spinner>
                                    <span class="ml-1">
                                        Loading...
                                    </span>
                                </span>

                                <span v-show="!busy">
                                    Submit
                                </span>
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </b-col>
        </b-row>
  </div>
</template>

<script>
import api from "@/api/new-starter-reviews";

export default {
    name: 'new-starter-review-create-wrapper',
    props: {
        gymOptions: {
            required: true,
            type: Array
        },
        howDidYouHearAboutUsOptions: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            form: {
                gym: null,
                class_date: null,
                name: null,
                email: null,
                experience: null,
                admin_rating: null,
                coaching_rating: null,
                social_media_rating: null,
                would_recommend: null,
                how_did_you_hear_about_us_option_id: null,
                how_did_you_hear_about_us_option_other: null,
                signed_up: null
            },
            success: false,
            busy: false,
            errors: [],
            ratingOptions: [
                { text: '0', value: 0},
                { text: '1', value: 1},
                { text: '2', value: 2},
                { text: '3', value: 3},
                { text: '4', value: 4},
                { text: '5', value: 5}
            ],
            yesNoOptions: [
                { text: 'Yes', value: 1},
                { text: 'No', value: 1}
            ]
        }
    },
    computed: {
        /**
         * Returns true if the other option is selected
         * @return Boolean
         */
        otherOptionSelected() {
            let otherOptionId = this.howDidYouHearAboutUsOptions.find(option => {
                return option.name == 'Other';
            });

            return otherOptionId.id == this.form.how_did_you_hear_about_us_option_id;
        }
    },
    methods: {
        /**
         * When the from submits add enquiry to backend database
         */
        onSubmit() {
            this.busy = true;
            this.errors = {};

            api.store(this.form).then(response => {
                // if successful then display success message
                this.success = true;

                // redirect to home page after 5 seconds
                setTimeout(function() {
                    window.location.href = "/";
                }, 5000);
            }).catch(e => {
                this.errors = e.response.data.errors;
            }).then(_ => {
                this.busy = false;
                this.scrollToTop();
            });
        },

        /**
         * Scroll the page to the top.
         */
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.new-starter-review-create-wrapper {
    text-align: center;

    margin-top: 100px;
    margin-bottom: 100px;

    .error-message {
        margin-bottom: 0px;
        padding: 0px;
    }

    @media (min-width: 576px) {
        margin-top: 100px;
        margin-bottom: 100px;

        .new-starter-review-create-form {
            width: 100%;
        }

        .subtitle {
            width: 100%;
        }

        .errors {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        margin-top: 100px;
        margin-bottom: 200px;

        .new-starter-review-create-form {
            width: 600px;
        }

        .subtitle {
            width: 600px;
        }

        .errors {
            width: 600px;
        }
    }

    @media (min-width: 992px) {
        margin-top: 150px;
        margin-bottom: 350px;

        .new-starter-review-create-form {
            width: 600px;
        }

        .subtitle {
            width: 600px;
        }

        .errors {
            width: 600px;
        }
    }

    .subtitle {
        padding: 10px 20px 10px 20px;
    }

    .errors {
        padding: 10px 20px 10px 20px;
    }

    .new-starter-review-create-form {
        padding: 0px 20px 10px 20px;
        text-align: left;

        .btn-submit {
            button {
                width: 100%;
                font-family: Marker Felt, fantasy;
            }

            button:hover {
                color: $saru-teal;
            }
        }
    }

    .privacy-policy-text {
        text-align: center;
    }

    .loading-spinner {
        margin-top: 50px;
    }
}
</style>