<template>
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-center social-media-links">
                <a
                    href="https://www.facebook.com/SJAmartialarts"
                    target="_blank"
                    ><i class="fab fa-facebook-square"></i
                ></a>
                <a href="https://twitter.com/sarujujitsu" target="_blank"
                    ><i class="fab fa-twitter-square"></i
                ></a>
                <a
                    href="https://www.instagram.com/sjamartialarts/"
                    target="_blank"
                    ><i class="fab fa-instagram"></i
                ></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "social-media-links"
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.social-media-links {
    font-size: 28pt;

    a {
        margin-left: 5px;
        color: $saru-dark-grey;
    }

    a:hover {
        color: $saru-white;
    }
}
</style>
