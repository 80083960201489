<template>
    <b-container class="dashboard-container">
        <div class="container dashboard-container">
            <b-row>
                <b-col>
                    <div
                        v-if="
                            $auth.user().allowed_access == 0 &&
                                $auth.user().access_token_expired
                        "
                        class="success alert alert-success"
                    >
                        <p>
                            Thank you for your registering, your access to the online content will be approved in 24 hours. Any issues please contact us on 03332 423919 or email us at contact-us@sjamartialarts.co.uk.
                        </p>
                    </div>
                    <div
                        v-if="
                            $auth.user().date_of_birth == null ||
                                $auth.user().contact_number == null ||
                                $auth.user().gym == null
                        "
                        class="errors alert alert-warning"
                    >
                        <p>
                            It seems you we haven't entered all of your details
                            in yet! Please head over to
                            <router-link tag="a" :to="{ name: 'user.details' }">
                                My Details</router-link
                            >
                            and enter them in.
                        </p>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-navbar>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-item-dropdown
                                id="my-nav-dropdown"
                                :text="$auth.user().name"
                                toggle-class="nav-link-custom"
                                right
                            >
                                <b-dropdown-item
                                    :to="{ name: 'user.details' }"
                                >
                                    My Details
                                </b-dropdown-item>

                                <b-dropdown-item
                                    :to="{ name: 'user.membership' }"
                                    >My Membership</b-dropdown-item
                                >

                                <b-dropdown-divider></b-dropdown-divider>

                                <div v-if="isAdmin()">
                                    <b-dropdown-item
                                        :to="{ name: 'members' }"
                                        >Members</b-dropdown-item
                                    >

                                    <b-dropdown-item
                                        :to="{ name: 'questions' }"
                                        >Questions</b-dropdown-item
                                    >

                                    <b-dropdown-item :to="{ name: 'new-starters' }">
                                        New Starters
                                    </b-dropdown-item>

                                    <b-dropdown-item :to="{ name: 'new-starter-reviews' }">
                                        New Starter Reviews
                                    </b-dropdown-item>
                                    
                                    <b-dropdown-divider></b-dropdown-divider>
                                </div>

                                <b-dropdown-item @click.prevent="logout"
                                    >Logout</b-dropdown-item
                                >
                            </b-nav-item-dropdown>
                        </b-navbar-nav>
                    </b-navbar>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-card header="Membership Hub" header-tag="h3">
                        <p>
                            Welcome to your dashboard. From here you will be
                            able to access all of your online features.
                        </p>
                        <div class="row">
                            <div
                                class="col-md-6 col-lg-4"
                                v-if="isAdmin() || isAllowedAccess()"
                            >
                                <div class="dashboard-card">
                                    <a @click="cjj($auth.user().id)">
                                        <div class="card">
                                            <img
                                                class="card-img-top"
                                                src="/storage/images/home/cjj_1.jpg"
                                                alt=""
                                            />
                                            <div class="card-body">
                                                <h4 class="card-title">
                                                    Combat Jiu-Jitsu
                                                </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div
                                class="col-md-6 col-lg-4"
                                v-if="isAdmin() || isAllowedAccess()"
                            >
                                <div class="dashboard-card">
                                    <a @click="bjj($auth.user().id)">
                                        <div class="card">
                                            <img
                                                class="card-img-top"
                                                src="/storage/images/home/bjj_1.jpg"
                                                alt=""
                                            />
                                            <div class="card-body">
                                                <h4 class="card-title">
                                                    Brazilian Jiu-Jitsu
                                                </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div
                                class="col-md-6 col-lg-4"
                                v-if="isAdmin() || isAllowedAccess()"
                            >
                                <div class="dashboard-card">
                                    <a @click="sg($auth.user().id)">
                                        <div class="card">
                                            <img
                                                class="card-img-top"
                                                src="/storage/images/home/sg_1.jpg"
                                                alt=""
                                            />
                                            <div class="card-body">
                                                <h4 class="card-title">
                                                    Submission Grappling
                                                </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div
                                class="col-md-6 col-lg-4"
                                v-if="isAdmin() || isAllowedAccess()"
                            >
                                <div class="dashboard-card">
                                    <a @click="mma($auth.user().id)">
                                        <div class="card">
                                            <img
                                                class="card-img-top"
                                                src="/storage/images/home/mma_1.jpg"
                                                alt=""
                                            />
                                            <div class="card-body">
                                                <h4 class="card-title">
                                                    Mixed Martial Arts
                                                </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-4">
                                <div class="dashboard-card">
                                    <router-link
                                        tag="span"
                                        :to="{ name: 'events' }"
                                    >
                                        <div class="card">
                                            <div class="card-icon">
                                                <i
                                                    class="fas fa-calendar-week"
                                                ></i>
                                            </div>
                                            <div class="card-body">
                                                <h4 class="card-title">
                                                    Events
                                                </h4>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>

                            <!-- <div class="col-md-6 col-lg-4" v-if="$auth.check()">
                <div class="dashboard-card">
                    <router-link tag="span" :to="{ name: 'information' }">
                        <div class="card">
                            <div class="card-icon">
                                <i class="fas fa-info-circle"></i>
                            </div>
                            <div class="card-body">
                                <h4 class="card-title">Information Hub</h4>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div> -->

                            <div class="col-md-6 col-lg-4">
                                <div class="dashboard-card">
                                    <a
                                        href="https://sarujj.wavecrm.net/automation/embed/makebooking?fbclid=IwAR3-F1qZ9l8E5AnD7HTqpGslPGq1QNBYuG9cLFPzzwefY4kdxNvgfBw82-I"
                                        target="_blank"
                                    >
                                        <div class="card">
                                            <div class="card-icon">
                                                <i
                                                    class="fas fa-calendar-week"
                                                ></i>
                                            </div>
                                            <div class="card-body">
                                                <h4 class="card-title">
                                                    Book Class
                                                </h4>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div
                                class="col-md-6 col-lg-4"
                                v-if="Object.keys(activeTopics).length > 0"
                            >
                                <div class="dashboard-card">
                                    <div class="card">
                                        <div class="card-body">
                                            <p class="text-center">Current Topics</p>
                                            <p v-for="topic in activeTopics" :key="topic.id">
                                                {{ topic.discipline.name }} - {{ topic.name }}
                                            </p>
                                            <h4 class="card-title" mt-5>
                                                Week {{ activeTopics[0].week }}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import api from "@/api/users";
import topicsApi from "@/api/topics";

export default {
    name: "dashboard",
    data() {
        return {
            activeTopics: [],
        }
    },
    methods: {
        /**
         * Display the CJJ class videos.
         *
         * @param userId
         */
        cjj(userId) {
            api.getClasses(userId).then(response => {
                this.userClasses = response.data;

                this.$router.push({ name: "classes.cjj" }).catch(err => {
                    throw new Error(`Problem handling something: ${err}.`);
                });
            });
        },
        /**
         * Display the BJJ class videos.
         *
         * @param userId
         */
        bjj(userId) {
            api.getClasses(userId).then(response => {
                this.userClasses = response.data;

                this.$router.push({ name: "classes.bjj" }).catch(err => {
                    throw new Error(`Problem handling something: ${err}.`);
                });
            });
        },
        /**
         * Display the submission grappling class videos.
         *
         * @param userId
         */
        sg(userId) {
            api.getClasses(userId).then(response => {
                this.userClasses = response.data;

                this.$router.push({ name: "classes.sg" }).catch(err => {
                    throw new Error(`Problem handling something: ${err}.`);
                });
            });
        },
        /**
         * Display the mixed martial arts class videos.
         *
         * @param userId
         */
        mma(userId) {
            api.getClasses(userId).then(response => {
                this.userClasses = response.data;

                this.$router.push({ name: "classes.mma" }).catch(err => {
                    throw new Error(`Problem handling something: ${err}.`);
                });
            });
        },

        /**
         * Logs the user out and then redirect back to login route.
         */
        logout() {
            // log the user out
            this.$auth.logout();
            // redirect to login route
            this.$router.push({ name: "login" });
        },

        /**
         * Get all of the current active week topics.
         */
        getActiveTopics() {
            topicsApi.allActive().then(response => {
                this.activeTopics = response.data.topics;
            });
        },

        /**
         * Check to see if the logged in user is an admin.
         * @returns {Boolean}
         */
        isAdmin() {
            return this.$auth.user().role == 2;
        },

        /**
         * Check to see if the logged in user has access.
         * @returns {Boolean}
         */
        isAllowedAccess() {
            return this.$auth.user().allowed_access == 1 ||
                !this.$auth.user().access_token_expired;
        }
    },
    mounted() {
        this.getActiveTopics();
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.dashboard-container {
    margin-top: 10px;
    margin-bottom: 20px;

    .dashboard-card {
        text-align: center;
        cursor: pointer;
        opacity: 0.75;
        margin-top: 15px;

        .card-title {
            margin-bottom: 0px;
            color: $saru-dark-grey;
        }

        .card-icon {
            font-size: 110px;
            color: $saru-dark-grey;
        }
    }

    .dashboard-card:hover {
        opacity: 1;
    }

    @media (min-width: 1200px) {
        .dashboard-card {
            .card-icon {
                font-size: 135px;
            }
        }
    }
}
</style>
