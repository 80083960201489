var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-container"},[_c('div',{staticClass:"register"},[_c('div',{staticClass:"register-form"},[_c('h3',[_vm._v("Sign me up!")]),_vm._v(" "),(_vm.has_error)?_c('div',{staticClass:"alert alert-danger"},[(_vm.errors)?_c('div',_vm._l((_vm.errors),function(v,k){return _c('div',{key:k},_vm._l((v),function(error){return _c('p',{key:error,staticClass:"error-message"},[_vm._v("\n                            "+_vm._s(error)+"\n                        ")])}),0)}),0):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.saving)?_c('div',[_c('clip-loader',{staticClass:"loading-spinner"})],1):_vm._e(),_vm._v(" "),(!_vm.success && !_vm.saving)?_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-class":"sr-only","label":"Gym:","label-for":"gym"}},[_c('b-form-select',{class:{
                            'is-invalid': _vm.has_error && _vm.errors.gym
                        },attrs:{"id":"gym","options":_vm.getGymOptions()},model:{value:(_vm.gym),callback:function ($$v) {_vm.gym=$$v},expression:"gym"}},[_c('b-form-select-option',{attrs:{"value":'',"disabled":""}},[_vm._v("Which gym location do you\n                            attend?")])],1)],1),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.has_error && _vm.errors.name
                        },attrs:{"type":"text","id":"name","aria-describedby":"name","placeholder":"Full Name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.has_error && _vm.errors.email
                        },attrs:{"type":"email","id":"email","placeholder":"Email address","aria-describedby":"emailHelp"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_vm._v(" "),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"emailHelp"}},[_vm._v("We'll never share your email with anyone\n                        else.")])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.memberId),expression:"memberId"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.has_error && _vm.errors.memberId
                        },attrs:{"type":"text","id":"memberId","placeholder":"Member ID","aria-describedby":"memberIdHelp"},domProps:{"value":(_vm.memberId)},on:{"input":function($event){if($event.target.composing)return;_vm.memberId=$event.target.value}}}),_vm._v(" "),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"memberIdHelp"}},[_vm._v("This is the ID you use to book classes e.g. JD2103")])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.has_error && _vm.errors.password
                        },attrs:{"type":"password","id":"password","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"form-control",class:{
                            'is-invalid': _vm.has_error && _vm.errors.password
                        },attrs:{"type":"password","id":"password_confirmation","placeholder":"Confirm Password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.password_confirmation=$event.target.value}}})]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"register-button-toolbar"},[_c('router-link',{attrs:{"to":'login'}},[_c('button',{key:'login',staticClass:"btn btn-primary nav-link m-1",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-arrow-circle-left"}),_vm._v("\n                            Back to Login\n                        ")])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary nav-link m-1",attrs:{"type":"submit"}},[_vm._v("\n                        Register\n                    ")])],1)],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n                    By submitting this form you are accepting our\n                    "),_c('a',{attrs:{"href":"/privacy-policy","target":"_blank"}},[_vm._v("Privacy Policy")]),_vm._v(".\n                ")])
}]

export { render, staticRenderFns }