<template>
    <div class="events-page-wrapper">
        <b-container>
            <b-row class="text-center justify-content-md-center mt-3">
                <b-col cols="8">
                    <h1>Events</h1>
                </b-col>
            </b-row>
            
            <b-row>
                <b-col>
                    <div class="text-center mb-2">
                        <v-calendar
                            class="events-calendar"
                            :masks="masks"
                            :attributes="events"
                            disable-page-swipe
                            is-expanded
                        >
                            <template v-slot:day-content="{ day, attributes }">
                                <div class="flex flex-col">
                                    <span>{{ day.day }}</span>

                                    <div class="flex-grow">
                                        <div
                                            v-for="event in attributes"
                                            :key="event.key"
                                            class="p-1 mb-1"
                                            :class="
                                                'events-calendar-event ' +
                                                    event.customData.class
                                            "
                                            @click="viewEventModal(event.key)"
                                        >
                                            <span
                                                class="events-calendar-event-title"
                                            >
                                                {{ event.customData.title }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </v-calendar>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <!-- view modal -->
        <b-modal
            v-model="modal.view.show"
            id="view-event-modal"
            :title="modal.view.edit ? 'Edit Event' : ''"
        >
            <div>
                <h5>
                    {{
                        modal.view.form.event_type_type +
                            " - " +
                            modal.view.form.title
                    }}
                </h5>
                
                <p v-html="modal.view.form.details"></p>
            </div>

            <template #modal-footer="{ cancel }">
                <b-button variant="secondary" @click="cancel()">
                    Cancel
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "events-page",
    props: {
        events: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            masks: {
                weekdays: "WWW"
            },
            modal: {
                view: {
                    show: false,
                    edit: false,
                    errors: [],
                    form: {
                        id: null,
                        event_type: null,
                        date: null,
                        title: null,
                        details: null,
                    }
                }
            },
        };
    },
    methods: {
        /**
         * Prepare data for the view event modal.
         * @param eventKey
         */
        viewEventModal(eventKey) {
            let event = this.events.find(event => event.key === eventKey);

            this.modal.view.form.id = event.id;
            this.modal.view.form.event_type = event.event_type_id;
            this.modal.view.form.event_type_type = event.event_type.type;
            this.modal.view.form.date = event.date;
            this.modal.view.form.title = event.title;
            this.modal.view.form.details = event.details;
        
            this.modal.view.edit = false;
            this.modal.view.show = true;
        },
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.events-calendar {
    border-radius: 0;
    width: 100%;
    overflow: auto;

    .events-calendar-event {
        background-color: $saru-dark-grey;
        color: $saru-white;
        border-radius: 10px;
        cursor: pointer;
        margin: 2px;

        .events-calendar-event-title {
            font-size: 10pt;
        }
    }

    .events-calendar-event-adults-subgrappling {
        background-color: $saru-light-grey;
        color: $saru-dark-grey;
    }

    .events-calendar-event-kids-subgrappling {
        background-color: $saru-red;
        color: $saru-dark-grey;
    }

    .events-calendar-event-adult-mma {
        background-color: $saru-turquoise;
        color: $saru-dark-grey;
    }

    .events-calendar-event-kids-mma {
        background-color: $saru-purple;
        color: $saru-dark-grey;
    }

    .events-calendar-event-adult-bjj {
        background-color: $saru-dark-grey;
    }

    .events-calendar-event-kids-bjj {
        background-color: $saru-green;
        color: $saru-dark-grey;
    }

    .events-calendar-event-adults-cjj {
        background-color: $saru-light-grey;
        color: $saru-dark-grey;
    }

    .events-calendar-event-gibbon-cjj {
        background-color: $saru-orange;
        color: $saru-dark-grey;
    }

    .events-calendar-event-lemur-cjj {
        background-color: $saru-light-blue;
        color: $saru-dark-grey;
    }

    .events-calendar-event-womens-cjj {
        background-color: $saru-light-grey;
        color: $saru-dark-grey;
    }

    .events-calendar-event-kids-camp {
        background-color: $saru-green;
        color: $saru-dark-grey;
    }
    
    .events-calendar-event-other {
        background-color: $saru-light-grey-alt;
        color: $saru-dark-grey;
    }
}
</style>
