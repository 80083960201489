import axios from "axios";

export default {
    /**
     * Get all active topics
     */
    allActive() {
        return axios.get("/topics?active=1");
    },
};
