<template>
    <div class="container m-b-15">
        <div class="row">
            <div class="col-md-12">
                <hooper
                    :settings="hooperSettings"
                    pagination="yes"
                    class="hooper-slider"
                    style="height: 100%"
                >
                    <slide
                        v-for="(image_path, index) in galleryImagePaths"
                        :key="index"
                    >
                        <div class="gallery-slide">
                            <img
                                class="img-fluid gallery-image"
                                :src="
                                    '/storage/images/classes/' +
                                        classType +
                                        '/gallery/' +
                                        index +
                                        '.jpg'
                                "
                            />
                        </div>
                    </slide>
                </hooper>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        classType: {
            required: true,
            type: String
        },
        galleryImagePaths: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            hooperSettings: {
                itemsToShow: 1.75,
                infiniteScroll: true,
                centerMode: true,
                progress: true,
                mouseDrag: false,
                touchDrag: true,
                wheelControl: false,
                mouseControl: false,
                keysControl: true,
                autoPlay: true,
                playSpeed: 2000,
                transition: 500
            }
        };
    }
};
</script>
