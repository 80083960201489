<template>
    <div class="video-card-group">
        <div class="video-group-menu">
            <div
                class="video-group-item"
                :key="group.name"
                v-for="group in videoGroups"
            >
                <button
                    class="btn btn-primary"
                    @click="setVideoGroupActive(group)"
                    v-bind:class="{
                        'video-card-active': group.name == currentVideoGroup
                    }"
                >
                    {{ group.name }}
                </button>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <h4>
                    {{ currentVideo.title }}
                </h4>
                <div class="card">
                    <div class="embed-responsive embed-responsive-16by9">
                        <video
                            id="current-video"
                            class="embed-responsive-item mx-auto"
                            controls
                            preload
                            width="1280"
                            height="720"
                            data-setup="{}"
                            controlsList="nodownload"
                            :src="currentVideo.source"
                            type="video/mp4"
                            oncontextmenu="return false;"
                        >
                            <p class="vjs-no-js">
                                To view this video please enable JavaScript, and
                                consider upgrading to a web browser that
                                <a
                                    href="https://videojs.com/html5-video-support/"
                                    target="_blank"
                                >
                                    supports HTML5 video
                                </a>
                            </p>
                        </video>
                    </div>
                    <div class="card-body">
                        <p>
                            {{ currentVideo.description }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="videoGroups">
            <div class="row mt-3" v-if="videoGroups">
                <div
                    class="col-md-4"
                    v-for="video in currentVideos"
                    :key="video.title"
                >
                    <div
                        class="video-card-wrapper"
                        v-bind:class="{
                            'video-card-active': video == currentVideo
                        }"
                    >
                        <div class="card m-2">
                            <classes-video-card
                                :video="video"
                                v-on:set-current-video="setCurrentVideo(video)"
                            ></classes-video-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VideoPlayer from "vue-video-player";
import api from "../../../api/classes";

export default {
    props: {
        classType: {
            type: String,
            required: true
        }
    },
    components: {
        VideoPlayer
    },
    data() {
        return {
            currentVideo: "",
            currentVideoTitle: "",
            currentVideoGroup: "",
            currentVideos: [],
            videoSelected: false,
            loading: false,
            videoGroups: null
        };
    },
    methods: {
        /**
         * Set the current video.
         */
        setCurrentVideo(video) {
            this.currentVideo = video;
        },
        /**
         * Set the current video group.
         */
        setVideoGroupActive(group) {
            this.currentVideos = group.videos;
            this.currentVideoGroup = group.name;

            // set the default video to the first video in the group
            this.currentVideo = this.currentVideos[0];
        }
    },
    mounted() {
        let component = this;

        api.videoGroupData(this.classType).then(res => {
            component.videoGroups = res.data.videoGroups;

            // Set the first group in video groups array as active
            component.setVideoGroupActive(component.videoGroups.week_1);
        });
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.video-card-group {
    .video-group-menu {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .video-group-item {
            padding: 5px;
            height: 60px;

            button {
                font-size: 16pt;
                background-color: $saru-dark-grey;
                border-color: $saru-dark-grey;
                color: $saru-white;
                opacity: 0.5;
                font-family: Marker Felt, fantasy;
            }
        }
    }

    .video-card-wrapper {
        cursor: pointer;
        opacity: 0.5;
    }

    .video-card-wrapper:hover {
        opacity: 1;
    }

    .video-card-active {
        opacity: 1 !important;
    }
}
</style>
