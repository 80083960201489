<template>
    <div class="user-index-table-wrapper">
        <div class="alert alert-danger" role="alert" v-if="hasError">
            <p>Sorry, there was an error retrieving the new starter reviews.</p>
        </div>

        <div class="alert alert-success" role="alert" v-if="successMessage">
            <p class="success-message">
                {{ successMessage }}
            </p>
        </div>

        <v-client-table
            v-if="newStarterReviews.length > 0"
            :columns="columns"
            :data="newStarterReviews"
            :options="options"
            ref="table"
        >
            <div slot="admin_rating" slot-scope="props">
                <p class="card-text">
                    {{ props.row.admin_rating + '/5' }}
                </p>
            </div>

            <div slot="coaching_rating" slot-scope="props">
                <p class="card-text">
                    {{ props.row.coaching_rating + '/5' }}
                </p>
            </div>

            <div slot="social_media_rating" slot-scope="props">
                <p class="card-text">
                    {{ props.row.social_media_rating + '/5' }}
                </p>
            </div>

            <div slot="would_recommend" slot-scope="props">
                <p class="card-text">
                    {{ props.row.would_recommend ? 'Yes' : 'No' }}
                </p>
            </div>

            <div slot="how_did_you_hear_about_us_option_name" slot-scope="props">
                <p class="card-text">
                    {{ props.row.how_did_you_hear_about_us_option_name }}
                    <span v-if="props.row.how_did_you_hear_about_us_option_other">
                        ({{ props.row.how_did_you_hear_about_us_option_other }})
                    </span>
                </p>
            </div>

            <div slot="signed_up" slot-scope="props">
                <p class="card-text">
                    {{ props.row.signed_up ? 'Yes' : 'No' }}
                </p>
            </div>

            <div slot="delete" slot-scope="props">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-toggle="modal"
                    data-target="#deleteModal"
                    @click="openDeleteModal(props.row.id, props.row.name)"
                    :disabled="props.row.role == 2"
                >
                    <i class="far fa-trash-alt"></i>
                </button>
            </div>
        </v-client-table>

        <div
            class="modal fade"
            id="deleteModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="deleteModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Are you sure you want to delete
                            {{ selectedNewStarterReview.name }}'s review?
                        </h5>
                    </div>
                    <div class="modal-body d-flex justify-content-around">
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="deleteNewStarterReview(selectedNewStarterReview.id)"
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            class="btn btn-dark"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/api/new-starter-reviews";

import errors from "@/components/layout/Errors.vue";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "new-starter-reviews-index-table",
    components: {
        errors,
        ClipLoader
    },
    data() {
        return {
            hasError: false,
            errors: [],
            newStarterReviews: [],
            saving: false,
            successMessage: null,
            editing: false,
            selectedNewStarterReview: {
                id: null,
                name: ""
            },
            paginationData: null,
            columns: [
                "name",
                "email",
                "gym_name",
                "class_date",
                "experience",
                "admin_rating",
                "coaching_rating",
                "social_media_rating",
                "would_recommend",
                "how_did_you_hear_about_us_option_name",
                "signed_up",
                "delete"
            ],
            options: {
                skin:
                    "new-starter-reviews-index-table table table-striped table-bordered table-hover",
                headings: {
                    gym_name: 'Gym',
                    class_date: 'Class and date attended',
                    would_recommend: 'Would recommend?',
                    how_did_you_hear_about_us_option_name: 'How did you hear about us?',
                    signed_up: 'Signed up?',
                    delete: ''
                },
                texts: {
                    count: "",
                    filter: "",
                    filterPlaceholder: "Search"
                }
            }
        };
    },
    mounted() {
        this.getNewStarterReviews();
    },
    methods: {
        /**
         *  Get the new starter reviews data for the table from the backend.
         */
        getNewStarterReviews() {
            api.all().then(response => {
                this.newStarterReviews = response.data.newStarterReviews;
            });
        },

        /**
         * Deletes the user from the backend.
         * @param newStarterReviewId
         */
        deleteNewStarterReview(newStarterReviewId) {
            this.successMessage = null;
            api.destroyNewStarterReview(newStarterReviewId).then(response => {
                this.getNewStarterReviews();
                $("#deleteModal").modal("hide");
                this.newStarterReviews = [];
                this.successMessage = 'New starter review deleted successfully';
                this.selectedNewStarterReview = {
                    id: null,
                    name: ""
                };
            });
        },

        /**
         * Open the delete model.
         * @param int id
         * @param string name
         */
        openDeleteModal(id, name) {
            this.selectedNewStarterReview = {
                id: id,
                name: name
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.new-starter-review-index-table-wrapper {
    .btn-submit {
        width: 100%;
        background-color: $saru-dark-grey;
        color: $saru-white;
        font-family: Marker Felt, fantasy;
    }

    .success-message {
        margin-bottom: 0px;
        padding: 0px;
    }
}
</style>
