<template>
    <div v-if="!hideNavBar" class="layout-navbar-wrapper sticky-top p-0">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <nav class="navbar navbar-expand-lg navbar-dark">
                        <!-- navbar logo -->
                        <a href="/" class="navbar-brand">
                            <img
                                src="/storage/images/saru_logo.png"
                                alt="SJA Martial Arts Logo"
                                height="65"
                                width="70"
                            />
                        </a>

                        <!-- main navbar -->
                        <div class="collapse navbar-collapse" id="navbar_menu">
                            <ul class="navbar-nav mr-auto m-r-10">
                                <li class="nav-item dropdown">
                                    <a
                                        class="nav-link dropdown-toggle"
                                        href="#"
                                        id="disciplinesNavbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Disciplines
                                    </a>
                                    <div
                                        class="dropdown-menu"
                                        aria-labelledby="disciplinesNavbarDropdown"
                                    >
                                        <a
                                            class="dropdown-item"
                                            href="/combat-jiu-jitsu"
                                            >Combat Jiu-Jitsu</a
                                        >
                                        <a
                                            class="dropdown-item"
                                            href="/brazilian-jiu-jitsu"
                                            >Brazilian Jiu-Jitsu</a
                                        >
                                        <a
                                            class="dropdown-item"
                                            href="/submission-grappling"
                                            >Submission Grappling</a
                                        >
                                        <a
                                            class="dropdown-item"
                                            href="/mixed-martial-arts"
                                            >Mixed Martial Arts</a
                                        >
                                    </div>
                                </li>
                                <li class="nav-item dropdown">
                                    <a
                                        class="nav-link dropdown-toggle"
                                        href="#"
                                        id="locationsNavbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Locations
                                    </a>
                                    <div
                                        class="dropdown-menu"
                                        aria-labelledby="locationsNavbarDropdown"
                                    >
                                        <a class="dropdown-item" href="/location/swansea">
                                            Swansea
                                        </a>
                                        <a class="dropdown-item" href="/location/neath">
                                            Neath
                                        </a>
                                    </div>
                                </li>
                                <li
                                    v-if="enableMembershipHub === '1'"
                                    class="nav-item"
                                >
                                    <a href="/membership-hub/login" class="nav-link">
                                        Membership Hub
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a v-if="storeLink" href="/store" class="nav-link">
                                        Shop
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a v-if="displayFaqNavbar === '1'" href="/frequently-asked-questions" class="nav-link">
                                        FAQ
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/contact-us" class="nav-link"
                                        >Contact Us</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        class="nav-link"
                                        href="https://sarujj.wavecrm.net/automation/embed/makebooking?fbclid=IwAR3-F1qZ9l8E5AnD7HTqpGslPGq1QNBYuG9cLFPzzwefY4kdxNvgfBw82-I"
                                        target="_blank"
                                    >
                                        Book Class
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div
                            class="social-media-links ml-auto m-r-10 visible-md-block visible-lg-block"
                        >
                            <a
                                href="https://www.facebook.com/SJAmartialarts"
                                target="_blank"
                                ><i class="fab fa-facebook-square"></i
                            ></a>
                            <a
                                href="https://twitter.com/sarujujitsu"
                                target="_blank"
                                ><i class="fab fa-twitter-square"></i
                            ></a>
                            <a
                                href="https://www.instagram.com/sjamartialarts/"
                                target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </div>

                        <!-- button which appears when navbar-expand-lg is too small -->
                        <button
                            class="navbar-toggler ml-auto"
                            data-toggle="collapse"
                            data-target="#navbar_menu"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "layout-navbar",
    props: {
        enableMembershipHub: {
            required: true,
            type: String
        },
        displayFaqNavbar: {
            required: true,
            type: String
        },
        displayEventsNavbar: {
            required: true,
            type: String
        },
        hideNavBar: {
            required: false,
            type: Boolean
        },
        storeLink: {
            required: false,
            default: null,
            type: String
        },
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.layout-navbar-wrapper {
    background-color: $saru-dark-grey;
}
</style>
