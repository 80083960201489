var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hideNavBar)?_c('div',{staticClass:"layout-navbar-wrapper sticky-top p-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbar_menu"}},[_c('ul',{staticClass:"navbar-nav mr-auto m-r-10"},[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),(_vm.enableMembershipHub === '1')?_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/membership-hub/login"}},[_vm._v("\n                                    Membership Hub\n                                ")])]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"nav-item"},[(_vm.storeLink)?_c('a',{staticClass:"nav-link",attrs:{"href":"/store"}},[_vm._v("\n                                    Shop\n                                ")]):_vm._e()]),_vm._v(" "),_c('li',{staticClass:"nav-item"},[(_vm.displayFaqNavbar === '1')?_c('a',{staticClass:"nav-link",attrs:{"href":"/frequently-asked-questions"}},[_vm._v("\n                                    FAQ\n                                ")]):_vm._e()]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4)])]),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6)])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('img',{attrs:{"src":"/storage/images/saru_logo.png","alt":"SJA Martial Arts Logo","height":"65","width":"70"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"disciplinesNavbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("\n                                    Disciplines\n                                ")]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"disciplinesNavbarDropdown"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"/combat-jiu-jitsu"}},[_vm._v("Combat Jiu-Jitsu")]),_vm._v(" "),_c('a',{staticClass:"dropdown-item",attrs:{"href":"/brazilian-jiu-jitsu"}},[_vm._v("Brazilian Jiu-Jitsu")]),_vm._v(" "),_c('a',{staticClass:"dropdown-item",attrs:{"href":"/submission-grappling"}},[_vm._v("Submission Grappling")]),_vm._v(" "),_c('a',{staticClass:"dropdown-item",attrs:{"href":"/mixed-martial-arts"}},[_vm._v("Mixed Martial Arts")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"locationsNavbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("\n                                    Locations\n                                ")]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"locationsNavbarDropdown"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"/location/swansea"}},[_vm._v("\n                                        Swansea\n                                    ")]),_vm._v(" "),_c('a',{staticClass:"dropdown-item",attrs:{"href":"/location/neath"}},[_vm._v("\n                                        Neath\n                                    ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"/contact-us"}},[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"https://sarujj.wavecrm.net/automation/embed/makebooking?fbclid=IwAR3-F1qZ9l8E5AnD7HTqpGslPGq1QNBYuG9cLFPzzwefY4kdxNvgfBw82-I","target":"_blank"}},[_vm._v("\n                                    Book Class\n                                ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"social-media-links ml-auto m-r-10 visible-md-block visible-lg-block"},[_c('a',{attrs:{"href":"https://www.facebook.com/SJAmartialarts","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook-square"})]),_vm._v(" "),_c('a',{attrs:{"href":"https://twitter.com/sarujujitsu","target":"_blank"}},[_c('i',{staticClass:"fab fa-twitter-square"})]),_vm._v(" "),_c('a',{attrs:{"href":"https://www.instagram.com/sjamartialarts/","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler ml-auto",attrs:{"data-toggle":"collapse","data-target":"#navbar_menu"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }