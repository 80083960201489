var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('backButton'),_vm._v(" "),_c('errors',{attrs:{"errors":_vm.errors,"has-error":_vm.hasError}}),_vm._v(" "),(_vm.successMessage)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('p',{staticClass:"success-message"},[_vm._v("\n            "+_vm._s(_vm.successMessage)+"\n        ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[(_vm.saving)?_c('div',[_c('clip-loader',{staticClass:"loading-spinner"})],1):_vm._e(),_vm._v(" "),(!_vm.saving)?_c('div',[(_vm.$auth.user().allowed_access == '1')?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_vm._v("\n                            You have unlimited access to all of our online\n                            content.\n                        ")]):_vm._e(),_vm._v(" "),(
                                !_vm.$auth.user().allowed_access &&
                                    !_vm.$auth.user().access_token_expired &&
                                    _vm.$auth.user().access_token_days_left > 0
                            )?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_vm._v("\n                            You have unlimited access to all of our online\n                            content for\n                            "+_vm._s(_vm.$auth.user().access_token_days_left)+" days.\n                        ")]):_vm._e(),_vm._v(" "),(
                                this.$auth.user().allowed_access == 0 &&
                                    this.$auth.user().access_token_expired
                            )?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v("\n                            You currently do not have access to any of our\n                            online content.\n                        ")]):_vm._e(),_vm._v(" "),_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.submitToken.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Code:","label-for":"token"}},[_c('b-form-input',{class:{
                                        'is-invalid': _vm.errors.token
                                    },attrs:{"id":"token","placeholder":""},model:{value:(_vm.user.token),callback:function ($$v) {_vm.$set(_vm.user, "token", $$v)},expression:"user.token"}})],1),_vm._v(" "),_vm._m(1)],1)]):_vm._e()])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("My Membership")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submit-token-button-toolbar"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("\n                                    Redeem\n                                ")])])
}]

export { render, staticRenderFns }