<template>
    <b-container class="events-wrapper">
        <b-row>
            <b-col>
                <backButton></backButton>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <h2>Events</h2>
            </b-col>
        </b-row>

        <b-alert variant="success" :show="successMessage != null">
            <p class="success-message">
                {{ successMessage }}
            </p>
        </b-alert>

        <b-row>
            <b-col>
                <div class="text-center mb-2">
                    <v-calendar
                        class="events-calendar"
                        :masks="masks"
                        :attributes="events"
                        disable-page-swipe
                        is-expanded
                    >
                        <template v-slot:day-content="{ day, attributes }">
                            <div class="flex flex-col">
                                <span>{{ day.day }}</span>

                                <div class="flex-grow">
                                    <div
                                        v-for="event in attributes"
                                        :key="event.key"
                                        class="p-1 mb-1"
                                        :class="
                                            'events-calendar-event ' +
                                                event.customData.class
                                        "
                                        @click="viewEventModal(event.key)"
                                    >
                                        <span
                                            class="events-calendar-event-title"
                                        >
                                            {{ event.customData.title }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-calendar>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button-toolbar
                    v-if="
                        $auth.user().role == 2 &&
                            $auth.user().allowed_access == 1
                    "
                    class="mb-2"
                    key-nav
                    aria-label="Toolbar with action buttons for events."
                >
                    <b-button-group class="mx-1">
                        <b-button @click="addEventModal()"
                            >Add Event</b-button
                        >
                    </b-button-group>
                </b-button-toolbar>
            </b-col>
        </b-row>

        <!-- add modal -->
        <b-modal
            v-model="modal.add.show"
            id="add-event-modal"
            title="Add Event"
            ok-variant="success"
            ok-title="Add"
            @ok="handleAddEvent"
            @cancel="handleCancelEvent"
        >
            <div id="add-modal-errors" ref="addModalErrors" v-if="Object.keys(modal.add.errors).length > 0">
                <Errors :errors="modal.add.errors" />
            </div>

            <b-form-group>
                <b-form-select
                    id="modal.add.form.event_type"
                    v-model="modal.add.form.event_type"
                    :options="eventTypes"
                    v-bind:class="{
                        'is-invalid': modal.add.errors.event_type
                    }"
                >
                    <b-form-select-option :value="null" disabled
                        >Event type</b-form-select-option
                    >
                </b-form-select>
            </b-form-group>

            <b-form-group label="Event date:" label-for="modal.add.form.date">
                <datetime
                    v-model="modal.add.form.date"
                    type="date"
                    format="dd/LL/yyyy"
                    input-id="modal.add.form.date"
                    title="Event date"
                    :flow="['date']"
                    v-bind:input-class="{
                        'form-control is-invalid': modal.add.errors.date,
                        'form-control': !modal.add.errors.date
                    }"
                ></datetime>
            </b-form-group>

            <b-form-group
                label-class="sr-only"
                label="Title:"
                label-for="modal.add.form.title"
            >
                <b-form-textarea
                    id="modal.add.form.title"
                    v-model="modal.add.form.title"
                    placeholder="Enter a title..."
                    rows="2"
                    max-rows="2"
                    v-bind:class="{
                        'is-invalid': modal.add.errors.title
                    }"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group
                label="Event details:"
                label-for="modal.add.form.details"
            >
                <ckeditor
                    id="ckeditor-add"
                    v-model="modal.add.form.details"
                    :config="editorConfig"
                    v-bind:input-class="{
                        'form-control is-invalid':
                            modal.add.errors.details,
                        'form-control': !modal.add.errors.details
                    }"
                ></ckeditor>
            </b-form-group>
        </b-modal>

        <!-- view modal -->
        <b-modal
            v-model="modal.view.show"
            id="view-event-modal"
            :title="modal.view.edit ? 'Edit Event' : ''"
            @ok="handleViewEvent"
        >
            <div v-if="modal.view.edit">
                <div v-if="Object.keys(modal.view.errors).length > 0">
                    <Errors :errors="modal.view.errors" />
                </div>

                <b-form-group>
                    <b-form-select
                        id="modal.view.form.event_type"
                        v-model="modal.view.form.event_type"
                        :options="eventTypes"
                        v-bind:class="{
                            'is-invalid': modal.view.errors.event_type
                        }"
                        :disabled="!modal.view.edit"
                    >
                        <b-form-select-option :value="null" disabled
                            >Event type</b-form-select-option
                        >
                    </b-form-select>
                </b-form-group>

                <b-form-group label="Event date:" label-for="modal.view.form.date">
                    <datetime
                        v-model="modal.view.form.date"
                        type="date"
                        format="dd/LL/yyyy"
                        input-id="modal.add.form.date"
                        title="Event date"
                        :flow="['date']"
                        v-bind:input-class="{
                            'form-control is-invalid': modal.view.errors.date,
                            'form-control': !modal.view.errors.date
                        }"
                    ></datetime>
                </b-form-group>

                <b-form-group
                    label-class="sr-only"
                    label="Event:"
                    label-for="modal.view.form.title"
                >
                    <b-form-textarea
                        id="modal.view.form.title"
                        v-model="modal.view.form.title"
                        placeholder="Enter a title..."
                        rows="2"
                        max-rows="2"
                        v-bind:class="{
                            'is-invalid': modal.view.errors.title
                        }"
                        :disabled="!modal.view.edit"
                    ></b-form-textarea>
                </b-form-group>
    
                <b-form-group
                    label="Event details:"
                    label-for="modal.view.form.details"
                >
                    <ckeditor
                        id="ckeditor-edit"
                        v-model="modal.view.form.details"
                        :config="editorConfig"
                        v-bind:input-class="{
                            'form-control is-invalid':
                                modal.view.errors.details,
                            'form-control': !modal.view.errors
                                .details
                        }"
                        :disabled="!modal.view.edit"
                    ></ckeditor>
                </b-form-group>
            </div>

            <div v-else>
                <h5>
                    {{
                        modal.view.form.event_type_type +
                            " - " +
                            modal.view.form.title
                    }}
                </h5>
                
                <p v-html="modal.view.form.details"></p>
            </div>

            <template #modal-footer="{ ok, cancel }">
                <span v-if="!modal.view.edit">
                    <b-button variant="secondary" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button
                        v-if="
                            $auth.user().role == 2 &&
                                $auth.user().allowed_access == 1
                        "
                        variant="info"
                        @click="modal.view.edit = true"
                    >
                        Edit
                    </b-button>
                    <b-button
                        v-if="
                            $auth.user().role == 2 &&
                                $auth.user().allowed_access == 1
                        "
                        variant="danger"
                        @click="ok()"
                    >
                        Delete
                    </b-button>
                </span>
                <span v-else>
                    <b-button variant="secondary" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button variant="success" @click="ok()">
                        Save
                    </b-button>
                </span>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";
import api from "@/api/events";
import Errors from "@/components/layout/Errors.vue";
import moment from "moment";

import CKEditor from 'ckeditor4-vue';

export default {
    name: "events",
    components: {
        backButton,
        Errors,
        CKEditor
    },
    data() {
        return {
            eventTypes: [],
            belts: [],
            modal: {
                add: {
                    show: false,
                    errors: [],
                    form: {
                        event_type: null,
                        date: null,
                        title: null,
                        details: null
                    }
                },
                view: {
                    show: false,
                    edit: false,
                    errors: [],
                    form: {
                        id: null,
                        event_type: null,
                        date: null,
                        title: null,
                        details: null,
                    }
                }
            },
            successMessage: null,
            masks: {
                weekdays: "WWW"
            },
            events: [],
            editorConfig: {
                // the configuration of the editor
            }
        };
    },
    computed: {},
    methods: {
        /**
         *  Get the event types.
         */
        async getEvents() {
            this.events = [];

            api.all().then(response => {
                this.events = response.data.events;
            });
        },

        /**
         * Get the event types.
         */
        async getEventTypes() {
            this.questions = [];

            api.eventTypes().then(response => {
                this.eventTypes = response.data.eventTypes;
            });
        },

        /**
         * Display the add event modal.
         */
        addEventModal() {
            this.modal.add.show = !this.modal.add.show;
        },

        /**
         * Prepare data for the view event modal.
         *
         * @param eventKey
         */
        viewEventModal(eventKey) {
            let event = this.events.find(event => event.key === eventKey);

            this.modal.view.form.id = event.id;
            this.modal.view.form.event_type = event.event_type_id;
            this.modal.view.form.event_type_type = event.event_type.type;
            this.modal.view.form.date = event.date;
            this.modal.view.form.title = event.title;
            this.modal.view.form.details = event.details;
        
            this.modal.view.edit = false;
            this.modal.view.show = true;
        },

        /**
         * Stop the modal event from closing by default.
         *
         * @param bvModalEvt
         */
        handleAddEvent(bvModalEvt) {
            // prevent modal from closing
            bvModalEvt.preventDefault();
            // trigger submit handler
            this.addEvent();
        },

        /**
         * Add an event.
         */
        addEvent() {
            this.successMessage = null;
            this.modal.add.errors = [];

            api.store(this.modal.add.form)
                .then(res => {
                    this.getEvents();
                    this.successMessage = "Event was added succesfully.";
                    this.modal.add.show = false;
                    this.resetAddForm();
                })
                .catch(e => {
                    this.modal.add.errors = e.response.data.errors;

                    var myDiv = document.getElementById('add-event-modal___BV_modal_body_');
                    myDiv.scrollTop = 0;
                });
        },

        /**
         * Handle view event modal actions.
         *
         * @param bvModalEvt
         */
        handleViewEvent(bvModalEvt) {
            // prevent modal from closing
            bvModalEvt.preventDefault();

            // trigger submit handler
            if (this.modal.view.edit) {
                this.editEvent();
            } else {
                this.deleteEvent();
            }
        },

        /**
         * Edit an event.
         */
        editEvent() {
            this.successMessage = null;
            this.modal.view.errors = [];

            api.update(this.modal.view.form.id, this.modal.view.form)
                .then(res => {
                    this.getEvents();
                    this.successMessage = "Event was edited succesfully.";
                    this.modal.view.show = false;
                    // this.resetViewForm();
                })
                .catch(e => {
                    this.modal.view.errors = e.response.data.errors;

                    var myDiv = document.getElementById('view-event-modal___BV_modal_body_');
                    myDiv.scrollTop = 0;
                });
        },

        /**
         * Delete an event.
         */
        deleteEvent() {
            this.successMessage = null;
            this.modal.view.errors = [];

            api.destroy(this.modal.view.form.id)
                .then(res => {
                    this.getEvents();
                    this.successMessage = "Event was deleted succesfully.";
                    this.modal.view.show = false;
                })
                .catch(e => {
                    this.modal.view.errors = e.response.data.errors;
                });
        },

        /**
         * Format in the date time format from config.
         * TODO: Get this string value from config
         */
        formatDateTime(date) {
            return moment(date).format("DD/MM/YYYY hh:mm");
        },

        /**
         * Format in the time format from config.
         * TODO: Get this string value from config
         */
        formatTime(date) {
            return moment(date).format("hh:mm A");
        },

        /**
         * Reset the add form.
         */
        resetAddForm() {
            this.modal.add.form = {
                title: null,
                date: null,
                event_type: null,
                details: null
            };
        },

        /**
         * Reset the add form.
         */
        resetViewForm() {
            this.modal.view.form = {
                title: null,
                date: null,
                event_type: null,
                details: null
            };
        },
    },
    mounted() {
        this.getEvents();
        this.getEventTypes();
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.events-calendar {
    border-radius: 0;
    width: 100%;
    overflow: auto;

    .events-calendar-event {
        background-color: $saru-dark-grey;
        color: $saru-white;
        border-radius: 10px;
        cursor: pointer;
        margin: 2px;

        .events-calendar-event-title {
            font-size: 10pt;
        }
    }

    .events-calendar-event-adults-subgrappling {
        background-color: $saru-light-grey;
        color: $saru-dark-grey;
    }

    .events-calendar-event-kids-subgrappling {
        background-color: $saru-red;
        color: $saru-dark-grey;
    }

    .events-calendar-event-adult-mma {
        background-color: $saru-turquoise;
        color: $saru-dark-grey;
    }

    .events-calendar-event-kids-mma {
        background-color: $saru-purple;
        color: $saru-dark-grey;
    }

    .events-calendar-event-adult-bjj {
        background-color: $saru-dark-grey;
    }

    .events-calendar-event-kids-bjj {
        background-color: $saru-green;
        color: $saru-dark-grey;
    }

    .events-calendar-event-adults-cjj {
        background-color: $saru-light-grey;
        color: $saru-dark-grey;
    }

    .events-calendar-event-gibbon-cjj {
        background-color: $saru-orange;
        color: $saru-dark-grey;
    }

    .events-calendar-event-lemur-cjj {
        background-color: $saru-light-blue;
        color: $saru-dark-grey;
    }

    .events-calendar-event-womens-cjj {
        background-color: $saru-light-grey;
        color: $saru-dark-grey;
    }

    .events-calendar-event-kids-camp {
        background-color: $saru-green;
        color: $saru-dark-grey;
    }
    
    .events-calendar-event-other {
        background-color: $saru-light-grey-alt;
        color: $saru-dark-grey;
    }
}
</style>
