import axios from "axios";

export default {
    /**
     * Get all questions.
     * 
     * @returns
     */
    all() {
        return axios.get("/events");
    },

    /**
     * Store a new event.
     * 
     * @param {Object} data 
     * @returns 
     */
    store(data) {
        return axios.post(`/event/add`, data);
    },

    /**
     * Update an event.
     *
     * @param {Int} id
     * @param {Object} data
     * @returns
     */
    update(id, data) {
        return axios.put(`/event/${id}/edit`, data);
    },

    /**
     * Deletes an event.
     * 
     * @param {int} id 
     * @returns 
     */
    destroy(id) {
        return axios.post(`/event/${id}/delete`);
    },

    /**
     * Get all event types.
     * 
     * @returns 
     */
    eventTypes() {
        return axios.get("/event-types");
    },

};
