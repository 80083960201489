<template> 
    <div>
        <div class="home-intro-wrapper">
            <div class="jumbotron">
                <div class="container">
                    <h1 class="display-4">SJA Martial Arts</h1>

                    <h5 class="lead">
                        <i>"Blood makes you related, loyalty makes you family"</i>
                    </h5>

                    <hr class="saru-hr">
                    
                    <p>
                        Welcome to SJA Martial Arts, we are a family, friendly academy based in Swansea YMCA and Neath 
                        town centre. Our primary focus is creating solid foundations for students through our structured
                        programmes allowing students to develop from a complete beginner to a well-rounded martial 
                        artist. With classes in Combat Jiu-Jitsu, Brazilian Jiu-Jitsu, Submission Grappling and MMA, 
                        there is a programme to suit everyone! For more information on what each programme involves 
                        follow the links below
                    </p>
                </div>
            </div>
        </div>

        <div class="home-classes-wrapper">
            <div class="container">
                <div class=row>
                    <div class="col-xs-12 col-md-6 mt-3">
                        <div class="home-classes">
                            <a href="/combat-jiu-jitsu">
                                <img src="/storage/images/home/cjj_1.jpg" class="img-fluid img-thumbnail" alt="">
                            </a>
                            <a href="/combat-jiu-jitsu"><h2>Combat Jiu-Jitsu</h2></a>
                            <hr class="saru-hr">
                            <p class="home-classes-description">
                                CJJ stands for Combat Jiu-Jitsu. Our programme focuses on self 
                                defence and confidence in confrontational situations.
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 mt-3">
                        <div class="home-classes">
                            <a href="/brazilian-jiu-jitsu">
                                <img src="/storage/images/home/bjj_1.jpg" class="img-fluid img-thumbnail" alt="">
                            </a>
                            <a href="/brazilian-jiu-jitsu"><h2>Brazilian Jiu-Jitsu</h2></a>
                            <hr class="saru-hr">
                            <p class="home-classes-description">
                                Brazilian Jiu-Jitsu is currently one of the fastest growing 
                                martial arts in the world made famous by the Gracies in the 
                                early days of the UFC.
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 mt-3">
                        <div class="home-classes">
                            <a href="/submission-grappling">
                                <img src="/storage/images/home/sg_1.jpg" class="img-fluid img-thumbnail" alt="">
                            </a>
                            <a href="/submission-grappling"><h2>Sub Grappling</h2></a>
                            <hr class="saru-hr">
                            <p class="home-classes-description">
                                Submission grappling has been made popular in recent years by 
                                Jiu-Jitsu superstars like Gordon Ryan. With tournaments like 
                                ADCC becoming more popular and being described as the olympics 
                                of grappling.
                            </p>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 mt-3">
                        <div class="home-classes">
                            <a href="/mixed-martial-arts">
                                <img src="/storage/images/home/mma_1.jpg" class="img-fluid img-thumbnail" alt="">
                            </a>
                            <a href="/mixed-martial-arts"><h2>Mixed Martial Arts</h2></a>
                            <hr class="saru-hr">
                            <p class="home-classes-description">
                                Mixed Martial Arts has been made famous by the UFC. MMA is a 
                                combination of all styles of combat like boxing, wrestling, 
                                karate etc. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- HQ timetable -->
        <div class="home-timetables-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <hr class="saru-hr">
                        <img class="img-responsive" src="storage/images/home/timetable.png" alt="SJA Martial Arts Timetable" style="width:100%">                        
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-md-12">
                    <iframe class="saru-home-maps" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ807bcDP1bkgRzle5KZlwdYg&key=AIzaSyANkuxc6rMWMVRsMsDrkJADv0UpqTDeODk" allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>

        <!-- Neath timetable -->
        <div class="home-timetables-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <img class="img-responsive" src="storage/images/home/neath_and_bridgend_timetable.png" alt="SJA Martial Arts Neath Timetable" style="width:100%">
                        <hr class="saru-hr">
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-md-12">
                    <iframe class="saru-home-maps" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJY9h7r8xdbkgRqddbU4w-qGw&key=AIzaSyANkuxc6rMWMVRsMsDrkJADv0UpqTDeODk"></iframe>
                </div>
            </div>
        </div>

        <div class="home-review-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <h2>Reviews</h2>
                        <hr class="saru-hr">
                    </div>
                </div>
            </div>
            <div>
                <review-slider>
                </review-slider>
            </div>
        </div>

        <b-modal
            class="pt-5"
            v-model="modal.show"
            id="home-modal"
            title="Free Trial Offer"
            title-class="w-100 text-center"
            ok-variant="success"
            ok-title="Add"
            size="md"
            centered
        >
            <template #modal-header>
                <h4 class="w-100 text-center m-0">
                    Free Trial Offer 🥋
                </h4>

                <button @click="hideTrialOfferModal" type="button" aria-label="Close" class="close">×</button>
            </template>

            <div id="home.modal-errors" ref="homeModalErrors" v-if="Object.keys(modal.errors).length > 0">
                <Errors :errors="modal.errors" />
            </div>

            <div class="d-flex justify-content-center" v-if="modal.success">
                <div class="alert alert-success">
                    <p>
                        {{ modal.success }}
                    </p>
                </div>
            </div>

            <div v-show="modal.step == 0">
                <p>
                    Please fill out the form below and pay a £10 deposit to secure your free trial.
                </p>

                <b-form-group>
                    <b-form-select
                        id="gym_id"
                        v-model="modal.form.gym_id"
                        :options="gymOptions"
                        @change="modal.form.booking_class_id = null"
                        v-bind:class="{
                            'is-invalid': modal.errors.gym_id
                        }"
                    >
                        <b-form-select-option :value="null" disabled
                            >Which gym are you
                            joining?</b-form-select-option
                        >
                    </b-form-select>
                </b-form-group>

                <b-form-group
                    label-class="sr-only"
                    label="Your Name:"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="modal.form.name"
                        placeholder="Name"
                        v-bind:class="{
                            'is-invalid': modal.errors.name
                        }"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label-class="sr-only"
                    label="Email address:"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="modal.form.email"
                        type="email"
                        placeholder="Email"
                        v-bind:class="{
                            'is-invalid': modal.errors.email
                        }"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label-class="sr-only"
                    label="Contact Number:"
                    label-for="contact_number"
                >
                    <b-form-input
                        id="contact_number"
                        v-model="modal.form.contact_number"
                        required
                        placeholder="Contact Number"
                        v-bind:class="{
                            'is-invalid': modal.errors.contact_number
                        }"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label-class="sr-only"
                    label="Date of birth:"
                    label-for="date_of_birth"
                >
                    <datetime
                        v-model="modal.form.date_of_birth"
                        type="date"
                        input-id="date_of_birth"

                        :flow="['year', 'month', 'date']"
                        :max-datetime="
                            new Date().toISOString()
                        "
                        :format="'dd/LL/yyyy'"
                        v-bind:input-class="{
                            'form-control is-invalid':
                                modal.errors.date_of_birth,
                            'form-control': !modal.errors.date_of_birth
                        }"
                        placeholder="Date of birth"
                    ></datetime>
                </b-form-group>

                <b-form-group>
                    <b-form-select
                        id="discipline_id"
                        v-model="modal.form.discipline_id"
                        :options="disciplineOptions"
                        v-bind:class="{
                            'is-invalid': modal.errors.discipline_id
                        }"
                    >
                        <b-form-select-option :value="null" disabled
                            >Which discipline are you
                            interested in?</b-form-select-option
                        >
                    </b-form-select>
                </b-form-group>

                <b-form-group>
                    <b-form-select
                        id="booking_class_id"
                        v-model="modal.form.booking_class_id"
                        :options="gymBookingClassOptions"
                        :disabled="modal.form.gym_id === null"
                        v-bind:class="{
                            'is-invalid': modal.errors.booking_class_id
                        }"
                    >
                        <b-form-select-option :value="null" disabled
                            >Please select a class to book</b-form-select-option
                        >
                    </b-form-select>
                </b-form-group>
            </div>

            <stripe-checkout
                ref="checkoutRef"
                mode="payment"
                :pk="publishableKey"
                :line-items="lineItems"
                :success-url="successURL"
                :cancel-url="cancelURL"
                :customer-email="modal.form.email"
                :client-reference-id="'ref_' + modal.form.email"
                @loading="v => loading = v"
            />

            <template #modal-footer="{}">
                <p v-show="modal.step == 0" class="w-100 text-center">
                    By submitting this form you are accepting our
                    <a href="/privacy-policy" target="_blank">Privacy Policy</a> and 
                    <a href="/terms-and-conditions">Terms & Conditions</a>.
                </p>

                <b-button  
                    class="w-100" 
                    v-show="modal.step == 0"
                    variant="success" 
                    @click="handleOk()" 
                    :disabled="modal.busy"
                >
                    <!-- loading spinner -->
                    <span v-if="modal.busy">
                        <clip-loader class="loading-spinner"></clip-loader>
                    </span>
                    <span v-else>
                        Pay £10 Deposit
                    </span>
                </b-button>

                <b-button  
                    class="w-100" 
                    v-show="modal.step == 1"
                    variant="secondary" 
                    @click="hideTrialOfferModal" 
                    :disabled="modal.busy"
                >
                    <!-- loading spinner -->
                    <span v-if="modal.busy">
                        <clip-loader class="loading-spinner"></clip-loader>
                    </span>
                    <span v-else>
                        Close
                    </span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Errors from "../components/messages/Errors.vue";
import { loadStripe } from '@stripe/stripe-js';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import VueCookies from 'vue-cookies'

export default {
    name: "home-page",
    components: {
        ClipLoader,
        Errors,
        StripeCheckout
    },
    props: {
        bookingClassOptions: {
            required: true,
            type: Array
        },
        appUrl: {
            required: true,
            type: String
        },
        showTrailOfferModal: {
            required: true,
            type: String
        },
    },
    data() {
        return {
            modal: {
                step: 0,
                busy: false,
                show: this.shouldShowTrailOfferModal(),
                form: {
                    gym_id: null,
                    name: null,
                    email: null,
                    contact_number: null,
                    date_of_birth: null,
                    discipline_id: null,
                    booking_class_id: null
                },
                errors: {},
                success: false
            },
            intent: null,
            form: {
                company_name: null,
                address_line_1: null,
                address_line_2: null,
                card_holder_name: null,
                payment_method: null
            },
            // vue stripe
            publishableKey: 'pk_test_51HlifZGRPn2zKYasUtXnYrqY7k7nDqQaWrCHiA9LVhVScTNZdy3b0nPLh4MnMq7ZaYBuE5GwsWfNIXF3UUdfBekJ003QSu086s',
            loading: false,
            lineItems: [
                {
                    price: 'price_1LHaX2GRPn2zKYaspn3QeuxL', // The id of the one-time price you created in your Stripe dashboard
                    quantity: 1,
                },
            ],
            successURL: this.appUrl + '?trialOffer=success',
            cancelURL: this.appUrl + '?trialOffer=cancel',
        }
    },
    computed: {
        gymOptions() {
            return [
                {
                    value: 1,
                    text: "Swansea (YMCA)"
                },
                {
                    value: 2,
                    text: "Neath"
                }
            ];
        },
        disciplineOptions() {
            return [
                {
                    value: 1,
                    text: "Brazilian Jiu-Jitsu"
                },
                {
                    value: 2,
                    text: "Submission Grappling"
                },
                {
                    value: 3,
                    text: "Combat Jiu-Jitsu"
                },
                {
                    value: 4,
                    text: "Mixed Martial Arts"
                }
            ];
        },
        gymBookingClassOptions() {
            let component = this;

            return this.bookingClassOptions.filter(function (option) {
                return option.gym_id == component.modal.form.gym_id;
            });
        }
    },
    methods: {
        handleOk() {
            let self = this;
            this.modal.errors = {};
            this.modal.busy = true;

            // post request to backend
            axios
                .post("/trial-offer", this.modal.form)
                .then(response => {
                    if (response.status === 200) {
                        // You will be redirected to Stripe's secure checkout page
                        self.$refs.checkoutRef.redirectToCheckout();
                    } else {
                        self.modal.errors[0] = ['An unexpected error occured, please try again later.'];
                        self.modal.busy = false;
                    } 
                })
                .catch(e => {
                    self.modal.errors = e.response.data.errors;
                    self.modal.busy = false;
                });
        },

        hideTrialOfferModal() {
            this.modal.show = false;
            VueCookies.set('sjamartialarts_trial_offer_cookie', '1', '30D');
        },

        shouldShowTrailOfferModal() {
            return this.bookingClassOptions.length > 0 
                && !VueCookies.get('sjamartialarts_trial_offer_cookie')
                && this.showTrailOfferModal == 1;
        }
    },
    mounted() {
        // load stripe
        this.stripe = loadStripe(this.publishableKey);

        // displayed cancelled payment error
        if (this.$route.query.trialOffer === 'cancel') {
            this.modal.show = true;
            this.modal.errors[0] = ['The payment was not successfull, please try again.'];
        }

        // displayed successfull payment message
        if (this.$route.query.trialOffer === 'success') {
            this.modal.show = true;
            this.modal.success = 'You have submitted a payment successfully, please check your email for confirmation.';
            this.modal.step = 1;
        }

        // if no cookie set and booking classes avaliable, show modal
        if (!this.$cookie.get('sjamartialarts_trial_offer_cookie') && this.bookingClassOptions.length > 0) {
            setTimeout(() => this.modal.show = true, 3000);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.home-intro-wrapper {
    text-align: center;
    background-color: $saru-light-grey;
    .jumbotron {
        background-color: $saru-light-grey;
        padding-bottom: 1rem;
        margin-bottom: 15px;
    }
}

.home-buttons-wrapper {
    text-align: center;
    margin: 30px 10px 30px 10px;

    .home-button {
        width: 100%;

        a {
            font-size: 1.6rem;
            font-family: Marker Felt, fantasy;
        }
    
        a:hover {
            color: $saru-teal;
        }
    }

    @media (max-width: $lg-width) {
        .home-button {
            margin-top: 20px;
            a {
                max-width: 100%;
                width: 100%;
                min-width: 100%;
            }
        }
    }

    @media (max-width: $lg-width) {
        .home-button {
            margin-top: 20px;
            a {
                width: 100%;
            }
        }
    }
}

.home-classes-wrapper {
    background-color: $saru-white;
    padding: 25px 0px 25px 0px;
    text-align: center;

    .home-classes {
        h2 {
            margin-top: 0.5rem;
        }

        img {
            margin-bottom: 10px;
        }

        .home-classes-description {
            min-height: 100%;
        }

        .saru-button {
            padding: 10px;

            a {
                width: 100%;
                font-size: 1.4rem;
                font-family: Marker Felt, fantasy;
            }

            a:hover {
                color: $saru-teal;
            }
        }
    }
}

.saru-home-maps {
    width: 100%;
    height: 350px;
    border: none;
    margin: 0;
    padding: 0;
    opacity: 0.8;
    display: block;
}

.home-timetables-wrapper {
    background-color: $saru-light-grey;
    padding-top: 15px;
    text-align: center;
    .img-responsive {
        padding: 15px;
    }
}

.home-review-wrapper {
    background-color: $saru-white;
    padding: 15px 0px 15px 0px;
    text-align: center;
}

@media (min-width: 325px) {
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
    .home-classes-description {
        min-height: 105px;
        height: 105px;
        max-height: 105px;
    }  
}

@media (min-width: 992px) {
    .home-classes-description {
        min-height: 50px;
        height: 50px;
        max-height: 50px;
    }   
}

@media (min-width: 1200px) {
    .home-classes-description {
        min-height: 50px;
        height: 50px;
        max-height: 50px;
    }   
}
</style>
