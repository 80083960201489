var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-details-wrapper"},[_c('div',{staticClass:"container"},[_c('backButton'),_vm._v(" "),_c('errors',{attrs:{"errors":_vm.errors,"has-error":_vm.hasError}}),_vm._v(" "),(_vm.successMessage)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('p',{staticClass:"success-message"},[_vm._v("\n                "+_vm._s(_vm.successMessage)+"\n            ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card details-card"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"card-body"},[(_vm.saving)?_c('div',[_c('clip-loader',{staticClass:"loading-spinner"})],1):_vm._e(),_vm._v(" "),(!_vm.saving)?_c('div',[_c('form',{attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Gym:","label-for":"gym"}},[_c('b-form-select',{class:{
                                            'is-invalid': _vm.errors.gym
                                        },attrs:{"id":"gym","options":_vm.getGymOptions()},model:{value:(_vm.user.gym),callback:function ($$v) {_vm.$set(_vm.user, "gym", $$v)},expression:"user.gym"}},[_c('b-form-select-option',{attrs:{"value":'',"disabled":""}},[_vm._v("Which gym location do you\n                                            attend?")])],1)],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Full name:","label-for":"name"}},[_c('b-form-input',{class:{
                                            'is-invalid': _vm.errors.name
                                        },attrs:{"id":"name"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Email address:","label-for":"email_address"}},[_c('b-form-input',{class:{
                                            'is-invalid': _vm.errors.email
                                        },attrs:{"id":"email_address"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Member ID:","label-for":"memberID"}},[_c('b-form-input',{class:{
                                            'is-invalid': _vm.errors.memberId
                                        },attrs:{"id":"memberId","aria-describedby":"memberIdHelp"},model:{value:(_vm.user.memberId),callback:function ($$v) {_vm.$set(_vm.user, "memberId", $$v)},expression:"user.memberId"}}),_vm._v(" "),_c('small',{staticClass:"form-text text-muted",attrs:{"id":"memberIdHelp"}},[_vm._v("This is the ID you use to book classes e.g. JD2103")])],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Date of birth:","label-for":"dateOfBirth"}},[_c('datetime',{attrs:{"type":"date","input-id":"dateOfBirth","title":"Date of birth","flow":['year', 'month', 'date'],"max-datetime":new Date().toISOString(),"format":'dd/LL/yyyy',"input-class":{
                                            'form-control is-invalid':
                                                _vm.errors.dateOfBirth,
                                            'form-control': !_vm.errors.dateOfBirth
                                        }},model:{value:(_vm.user.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.user, "dateOfBirth", $$v)},expression:"user.dateOfBirth"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Contact Number:","label-for":"contactNumber"}},[_c('b-form-input',{class:{
                                            'is-invalid':
                                                _vm.errors.contactNumber
                                        },attrs:{"id":"contactNumber"},model:{value:(_vm.user.contactNumber),callback:function ($$v) {_vm.$set(_vm.user, "contactNumber", $$v)},expression:"user.contactNumber"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Children:","label-for":"children","description":"Please enter your child's name, if you have multiple children please seperate their names with a comma e.g. 'Joe Bloggs,Jane Doe'."}},[_c('b-form-input',{class:{
                                            'is-invalid': _vm.errors.children
                                        },attrs:{"id":"children","placeholder":"Leave blank if you have no children which attend any of our academy"},model:{value:(_vm.user.children),callback:function ($$v) {_vm.$set(_vm.user, "children", $$v)},expression:"user.children"}})],1),_vm._v(" "),_vm._m(1)],1)]):_vm._e()])])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("My Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-button-toolbar"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("\n                                        Update\n                                    ")])])
}]

export { render, staticRenderFns }