<template>
    <b-container class="mb-3">
        <b-row>
            <b-col>
                <hooper
                    :settings="hooperSettings"
                    pagination="yes"
                    class="hooper-slider"
                    style="height: 100%"
                >
                    <slide
                        v-for="(imagePath, index) in galleryImagePaths"
                        :key="index"
                    >
                        <div class="gallery-slide">
                            <img
                                class="img-fluid gallery-image"
                                :src="imagePath"
                            />
                        </div>
                    </slide>
                </hooper>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "landing-gallery",
    components: {},
    props: {
        galleryImagePaths: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            hooperSettings: {
                itemsToShow: 1.75,
                infiniteScroll: true,
                centerMode: true,
                progress: true,
                mouseDrag: false,
                touchDrag: true,
                wheelControl: false,
                mouseControl: false,
                keysControl: true,
                autoPlay: true,
                playSpeed: 2000,
                transition: 500
            }
        };
    }
};
</script>

<style lang="scss" scoped>
.hooper-slide {
    width: 530px;
    min-width: 530px;
    max-width: 530px;
}
</style>
