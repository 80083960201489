<template>
    <div class="container-fluid">
        <backButton></backButton>

        <h2>Members</h2>

        <userIndexTable></userIndexTable>
    </div>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";
import userIndexTable from "@/membership_hub/components/member/IndexTable.vue";

export default {
    name: "members",
    components: {
        backButton,
        userIndexTable
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.btn-back {
    background-color: $saru-dark-grey;
    color: $saru-white;
    font-family: Marker Felt, fantasy;
}
</style>
