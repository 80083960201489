<template>
    <div class="row">
        <div class="col-md-12">
            <div class="back-button">
                <router-link :to="{ name: 'dashboard' }">
                    <button type="button" class="btn btn-primary">
                        <i class="fas fa-arrow-circle-left"></i>
                        Back
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "back-button"
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.back-button {
    margin-top: 10px;
    margin-bottom: 20px;

    button {
        font-family: Marker Felt, fantasy;
    }

    button:hover {
        color: $saru-teal;
    }
}
</style>
