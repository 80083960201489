import axios from "axios";

export default {
    /**
     * Get all new starter reviews.
     * @returns
     */
    all() {
        return axios.get("/new-starter-reviews");
    },

    /**
     * Stores a new starter review
     * @returns 
     */
    store(payload) {
        return axios.post("/new-starter-review/add", payload);
    },

    /**
     * Delete a new starter review.
     * @param int newStarterReviewId
     * @returns
     */
    destroyNewStarterReview(newStarterReviewId) {
        return axios.post("/new-starter-review/" + newStarterReviewId + "/delete")
    },

    /**
     * Get all new starter review results.
     * @returns
     */
    results() {
        return axios.get("/new-starter-review/results");
    }
};