<<template>
    <div></div>
</template>

<script>
export default {
    name: "router-navigation",
    mounted() {
        // if user logged in route to dashboard
        if (this.$auth.check()) {
            this.$router
                .push({
                    name: "dashboard"
                })
                .catch(err => {
                    throw new Error(`Problem handling something: ${err}.`);
                });
        }
    }
};
</script>

<style></style>
