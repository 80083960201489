<template>
    <b-container>
        <b-row>
            <b-col>
                <backButton></backButton>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <h2>Information Hub</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <ul>
                    <li>
                        <a href="/information/parents_booklet" target="_blank"
                            >Parents Booklet</a
                        >
                    </li>
                    <li>
                        <a
                            href="/information/programme_syllabus"
                            target="_blank"
                            >Programme Syllabus</a
                        >
                    </li>
                    <li>
                        <a href="/information/license_booklet" target="_blank"
                            >License Booklet</a
                        >
                    </li>
                    <li>
                        <a
                            href="/information/kids_activity_booklet"
                            target="_blank"
                            >Kids Activity Booklet</a
                        >
                    </li>
                </ul>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <h3>Welcome Booklets</h3>
                <ul>
                    <li v-for="booklet in welcomeBooklets" :key="booklet.name">
                        <a :href="booklet.url" target="_blank">{{
                            booklet.name
                        }}</a>
                    </li>
                </ul>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";

export default {
    name: "information-hub",
    components: {
        backButton
    },
    data() {
        return {
            welcomeBooklets: [
                {
                    name: "Gibbon Basics",
                    url: "/information/gibbon_basics"
                },
                {
                    name: "Gibbon Advanced",
                    url: "/information/gibbon_advanced"
                },
                {
                    name: "Lemur Basics",
                    url: "/information/lemur_basics"
                },
                {
                    name: "Lemur Advanced",
                    url: "/information/lemur_advanced"
                },
                {
                    name: "Silverbacks CJJ",
                    url: "/information/silverback_cjj"
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped></style>
