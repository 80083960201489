<template>
    <div>
        <b-container class="class-page-header" fluid>
            <b-row>
                <b-col>
                    <img
                        class="img-responsive"
                        src="storage/images/classes/bjj/saru_1.jpg"
                        alt=""
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-title">
            <b-jumbotron header="BJJ (Brazilian Jiu-Jitsu)">
                <hr class="saru-hr" />
            </b-jumbotron>
        </b-container>

        <b-container>
            <b-row>
                <b-col class="class-page-text">
                    <h2>What is BJJ?</h2>
                    <p>
                        BJJ stands for Brazilian Jiu-Jitsu.
                    </p>
                    <p>
                        Brazilian Jiu-Jitsu was developed by the Gracie family
                        in Brazil and made famous in the UFC by Royce Gracie.
                        BJJ is developed around the principle of a smaller or
                        weaker opponent being able to beat a larger opponent in
                        a fight. BJJ has come a long way since then and is now
                        one of the fastest growing sports in the world. Our
                        programme is taught as a sport with the objective to
                        beat opponents via points or submission in a
                        competition.
                    </p>
                </b-col>
            </b-row>
        </b-container>

        <b-container v-if="!updatingDisciplineTimetables">
            <b-row>
                <b-col class="mt-3">
                    <hr class="saru-hr" />
                    <img
                        class="img-responsive"
                        src="storage/images/classes/bjj/timetable.png"
                        alt="SJA Martial Arts YMCA BJJ Timetable"
                        style="width:100%"
                    />
                </b-col>
            </b-row>
        </b-container>

        <b-container class="class-page-gallery" fluid>
            <b-row>
                <b-col>
                    <h2>Gallery</h2>
                    <hr class="saru-hr" />
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <classes-gallery-slider
                        class-type="bjj"
                        :gallery-image-paths="galleryImagePaths"
                    ></classes-gallery-slider>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "bjj-class-page",
    props: {
        galleryImagePaths: {
            required: true,
            type: Array
        },
        updatingDisciplineTimetables: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fields: [
                { time: "Time " },
                { monday: "Monday" },
                { tuesday: "Tuesday" },
                { wednesday: "Wednesday" },
                { thursday: "Thursday" },
                { sunday: "Sunday" }
            ],
            items: [
                {
                    time: "9.20am - 10am",
                    monday: "",
                    tuesday: "",
                    wednesday: "",
                    thursday: "",
                    sunday: "Kids BJJ Age 4 - 13"
                },
                {
                    time: "4.40pm - 5.20pm",
                    monday: "Kids BJJ Age 4 - 13",
                    tuesday: "",
                    wednesday: "Kids BJJ Age 4 - 13",
                    thursday: "",
                    sunday: ""
                },
                {
                    time: "6pm - 7pm",
                    monday: "Adult BJJ Age 14+",
                    tuesday: "Adult BJJ Age 14+",
                    wednesday: "Adult BJJ Age 14+",
                    thursday: "Adult BJJ Age 14+",
                    sunday: ""
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped></style>
