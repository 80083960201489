<template>
    <div class="landing-page-wrapper">
        <b-modal
            ref="landing-modal"
            id="landing-modal"
            modal-class="landing-modal"
            size="md"
            centered
            no-close-on-backdrop
            :cancel-disabled="true"
            title="Start your journey by entering a few details..."
            @ok="handleOk"
        >
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-center">
                        <!-- loading spinner -->
                        <div v-if="saving">
                            <clip-loader class="loading-spinner"></clip-loader>
                        </div>

                        <div v-if="hasError" class="landing-modal-errors">
                            <Errors :errors="errors" />
                        </div>
                    </div>
                </b-col>
            </b-row>

            <b-form class="landing-form" @submit.stop.prevent="onModalSubmit">
                <b-form-group
                    label-class="sr-only"
                    label="Your Name:"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="details.name"
                        required
                        placeholder="Name"
                        v-bind:class="{
                            'is-invalid': errors.name
                        }"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label-class="sr-only"
                    label="Email address:"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="details.email"
                        type="email"
                        placeholder="Email"
                        v-bind:class="{
                            'is-invalid': errors.email
                        }"
                    ></b-form-input>
                </b-form-group>

                <p>
                    By submitting this form you are accepting our
                    <a href="/privacy-policy" target="_blank">Privacy Policy</a
                    >.
                </p>
            </b-form>

            <template #modal-footer="{ ok }">
                <b-button
                    class="btn-lg btn-submit"
                    type="button"
                    @click="ok()"
                    variant="primary"
                >
                    Submit
                </b-button>

                <a @click="missOffer" class="landing-modal-exit-text">
                    I want to miss this offer
                </a>
            </template>
        </b-modal>

        <b-container class="landing-header-container" fluid>
            <!-- logo -->
            <b-row>
                <b-col>
                    <div
                        class="landing-logo-wrapper d-flex justify-content-center"
                    >
                        <img
                            class="landing-logo"
                            src="/storage/images/saru_logo.png"
                            alt="SJA Martial Arts Logo"
                        />
                    </div>
                </b-col>
            </b-row>

            <!-- video -->
            <div class="landing-video-wrapper d-flex justify-content-center">
                <div class="landing-video">
                    <div class="embed-responsive embed-responsive-16by9">
                        <video
                            id="landing-video"
                            class="landing-video-video embed-responsive-item mx-auto"
                            controls
                            width="1280"
                            height="720"
                            controlsList="nodownload"
                            :src="content.videoSource"
                            type="video/mp4"
                        >
                            <p class="vjs-no-js">
                                To view this video please enable JavaScript, and
                                consider upgrading to a web browser that
                                <a
                                    href="https://videojs.com/html5-video-support/"
                                    target="_blank"
                                >
                                    supports HTML5 video
                                </a>
                            </p>
                        </video>
                    </div>
                </div>
            </div>
        </b-container>

        <div class="landing-locked-wrapper">
            <b-row>
                <b-col>
                    <div v-show="contentLocked" class="locked">
                        <b-row>
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <h2 class="content">
                                        Content Locked
                                        <span class="fas fa-lock"></span>
                                    </h2>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <p class="content">
                                        Please watch this 2 minute video to
                                        unlock this content.
                                    </p>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                    <div v-if="!contentLocked" class="unlocked">
                        <b-row>
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <h2 class="content">
                                        Content Unlocked
                                        <span class="fas fa-unlock"></span>
                                    </h2>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <p class="content">
                                        {{ content.unlockedText }}
                                    </p>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- !success -->
                        <b-row v-show="!success && showCountdown">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <div class="content landing-counter">
                                        <counter
                                            ref="landing-counter-1"
                                            :year="countdown.year"
                                            :month="countdown.month"
                                            :day="countdown.day"
                                            :hour="countdown.hour"
                                            :minute="countdown.minute"
                                            :second="countdown.second"
                                            :millisecond="countdown.millisecond"
                                        ></counter>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <h3 class="content">
                                        {{ content.formTitleText }}
                                    </h3>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <div
                                        v-if="hasError"
                                        class="content landing-errors"
                                    >
                                        <Errors :errors="errors" />
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-show="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <b-form
                                        @submit="onSubmit"
                                        class="content landing-form"
                                    >
                                        <b-form-group
                                            label-class="sr-only"
                                            label="Your Name:"
                                            label-for="name"
                                        >
                                            <b-form-input
                                                id="name"
                                                v-model="details.name"
                                                required
                                                placeholder="Name"
                                                v-bind:class="{
                                                    'is-invalid': errors.name
                                                }"
                                            ></b-form-input>
                                        </b-form-group>

                                        <b-form-group
                                            label-class="sr-only"
                                            label="Email address:"
                                            label-for="email"
                                        >
                                            <b-form-input
                                                id="email"
                                                v-model="details.email"
                                                type="email"
                                                placeholder="Email"
                                                v-bind:class="{
                                                    'is-invalid': errors.email
                                                }"
                                            ></b-form-input>
                                        </b-form-group>

                                        <b-form-group
                                            label-class="sr-only"
                                            label="Contact Number:"
                                            label-for="contactNumber"
                                        >
                                            <b-form-input
                                                id="contactNumber"
                                                v-model="details.contactNumber"
                                                required
                                                placeholder="Contact Number"
                                                v-bind:class="{
                                                    'is-invalid':
                                                        errors.contactNumber
                                                }"
                                            ></b-form-input>
                                        </b-form-group>

                                        <p>
                                            By submitting this form you are
                                            accepting our
                                            <a
                                                href="/privacy-policy"
                                                target="_blank"
                                                >Privacy Policy</a
                                            >.
                                        </p>

                                        <b-button
                                            class="btn-lg btn-submit"
                                            type="button"
                                            @click="onSubmit"
                                            variant="primary"
                                        >
                                            <span v-show="saving">
                                                <b-spinner
                                                    small
                                                    label="Loading..."
                                                ></b-spinner>
                                                <span class="ml-1">
                                                    Loading...
                                                </span>
                                            </span>

                                            <span v-if="!saving">
                                                {{ content.formSubmitText }}
                                            </span>
                                        </b-button>

                                        <div class="mt-2">
                                            <a
                                                href="/"
                                                class="landing-exit-text"
                                            >
                                                I want to miss this offer
                                            </a>
                                        </div>
                                    </b-form>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- community & achivement & facility & reviews -->
                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <div class="content landing-reviews">
                                        <b-row>
                                            <b-col class="mt-3">
                                                <img
                                                    class="landing-image image-responsive"
                                                    src="/storage/images/landing/community.png"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="mt-3">
                                                <img
                                                    class="landing-image image-responsive"
                                                    src="/storage/images/landing/achivement.png"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="mt-3">
                                                <img
                                                    class="landing-image image-responsive"
                                                    src="/storage/images/landing/facility.png"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="mt-3">
                                                <img
                                                    class="landing-image image-responsive"
                                                    src="/storage/images/landing/elias.png"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="mt-3">
                                                <img
                                                    class="landing-image image-responsive"
                                                    src="/storage/images/landing/daniel.png"
                                                />
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="mt-3">
                                                <img
                                                    class="landing-image image-responsive"
                                                    src="/storage/images/landing/barrie.png"
                                                />
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <h3 class="content">
                                        {{ content.otherText }}
                                    </h3>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-show="!success">
                            <b-col>
                                <div class="landing-gallery">
                                    <landing-gallery
                                        :gallery-image-paths="galleryImagePaths"
                                    ></landing-gallery>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success && showCountdown">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <div class="content landing-counter">
                                        <counter
                                            ref="landing-counter-2"
                                            :year="countdown.year"
                                            :month="countdown.month"
                                            :day="countdown.day"
                                            :hour="countdown.hour"
                                            :minute="countdown.minute"
                                            :second="countdown.second"
                                            :millisecond="countdown.millisecond"
                                            @expired="countdownExpired"
                                        ></counter>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <h3 class="content">
                                        You can get started as soon as you want!
                                    </h3>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <div
                                        v-if="hasError"
                                        class="content landing-errors"
                                    >
                                        <Errors :errors="errors" />
                                    </div>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <b-form
                                        @submit="onSubmit"
                                        class="content landing-form"
                                    >
                                        <b-form-group
                                            label-class="sr-only"
                                            label="Your Name:"
                                            label-for="name"
                                        >
                                            <b-form-input
                                                id="name"
                                                v-model="details.name"
                                                required
                                                placeholder="Name"
                                                v-bind:class="{
                                                    'is-invalid': errors.name
                                                }"
                                            ></b-form-input>
                                        </b-form-group>

                                        <b-form-group
                                            label-class="sr-only"
                                            label="Email address:"
                                            label-for="email"
                                        >
                                            <b-form-input
                                                id="email"
                                                v-model="details.email"
                                                type="email"
                                                placeholder="Email"
                                                v-bind:class="{
                                                    'is-invalid': errors.email
                                                }"
                                            ></b-form-input>
                                        </b-form-group>

                                        <b-form-group
                                            label-class="sr-only"
                                            label="Contact Number:"
                                            label-for="contactNumber"
                                        >
                                            <b-form-input
                                                id="contactNumber"
                                                v-model="details.contactNumber"
                                                required
                                                placeholder="Contact Number"
                                                v-bind:class="{
                                                    'is-invalid':
                                                        errors.contactNumber
                                                }"
                                            ></b-form-input>
                                        </b-form-group>

                                        <p>
                                            By submitting this form you are
                                            accepting our
                                            <a
                                                href="/privacy-policy"
                                                target="_blank"
                                                >Privacy Policy</a
                                            >.
                                        </p>

                                        <b-button
                                            class="btn-lg btn-submit"
                                            type="button"
                                            @click="onSubmit"
                                            variant="primary"
                                            ><span v-show="saving">
                                                <b-spinner
                                                    small
                                                    label="Loading..."
                                                ></b-spinner>
                                                <span class="ml-1">
                                                    Loading...
                                                </span>
                                            </span>

                                            <span v-if="!saving">
                                                {{ content.formSubmitText }}
                                            </span>
                                        </b-button>

                                        <div class="mt-2">
                                            <a
                                                href="/"
                                                class="landing-exit-text"
                                            >
                                                I want to miss this offer
                                            </a>
                                        </div>
                                    </b-form>
                                </div>
                            </b-col>
                        </b-row>

                        <b-row v-if="!success">
                            <b-col>
                                <div class="d-flex justify-content-center">
                                    <button
                                        @click="scrollToTop"
                                        class="btn btn-primary btn-saru-alt"
                                    >
                                        Back to top
                                        <span class="fas fa-arrow-up"></span>
                                    </button>
                                </div>
                            </b-col>
                        </b-row>

                        <!-- success -->
                        <div v-if="success">
                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <h3 v-show="success" class="display-4">
                                            Congratulations
                                        </h3>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <p
                                            class="thank-you-message"
                                            v-show="success"
                                        >
                                            Thank you for filling out the form,
                                            one of our team members will be
                                            contacting you shortly. If you want
                                            more updates then follow our social
                                            media below.
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div class="d-flex justify-content-center">
                                        <SocialMediaLinks />
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>

        <LandingFooter />
    </div>
</template>

<script>
import Errors from "../../components/messages/Errors.vue";
import SocialMediaLinks from "../../components/landing/SocialMediaLinks.vue";
import LandingFooter from "@/pages/landing/LandingFooter.vue";

import axios from "axios";
import moment from "moment";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import Counter from "../../components/Counter";

export default {
    name: "landing-page-modal",
    components: {
        Errors,
        SocialMediaLinks,
        ClipLoader,
        Counter,
        LandingFooter
    },
    props: {
        content: {
            required: true,
            type: Object
        },
        galleryImagePaths: {
            required: false,
            type: Array
        }
    },
    data() {
        return {
            details: {
                name: null,
                email: null,
                contactNumber: null,
                landingPage: this.content.name
            },
            step: 0,
            saving: false,
            hasError: false,
            success: false,
            errors: [],
            contentLocked: true,
            unlockTimer: this.content.videoUnlockSeconds ?? 1000,
            countdownTimerMins: 15,
            countdown: {
                year: null,
                month: null,
                day: null,
                hour: null,
                minute: null,
                second: null,
                millisecond: null
            },
            showCountdown: true
        };
    },
    methods: {
        /**
         * When the from submits add enquiry to backend database.
         */
        onModalSubmit() {
            this.saving = true;
            this.errors = [];
            this.hasError = false;

            // post request to backend
            axios
                .post("/api/landing/modal", this.details)
                .then(res => {
                    // if successful then display success message
                    this.saving = false;

                    this.hideModal();
                    this.playVideo();

                    // unlock the content after 135 seconds
                    setTimeout(() => {
                        let component = this;

                        component.contentLocked = false;

                        // needed to add this next tick to get the counter to be rendered correctly
                        Vue.nextTick(function() {
                            component.startCountdownTimer();
                        });
                    }, this.unlockTimer);
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                    this.saving = false;
                });
        },
        /**
         * On submit of the landing form save data in backend.
         */
        onSubmit() {
            this.saving = true;
            this.errors = [];
            this.hasError = false;

            // post request to backend
            axios
                .post("/api/landing", this.details)
                .then(res => {
                    // if successful then display success message
                    this.success = true;
                    this.saving = false;
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                    this.saving = false;
                });
        },
        /**
         * Scroll the page to the top.
         */
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        /**
         * Show the landing modal.
         */
        showModal() {
            this.$refs["landing-modal"].show();
        },
        /**
         * Hide the landing modal.
         */
        hideModal() {
            this.$refs["landing-modal"].hide();
        },
        /**
         * Play the landing video.
         */
        playVideo() {
            var landingVideo = document.getElementById("landing-video");
            landingVideo.play();
        },
        /**
         * Handle the ok button event on the landing modal.
         */
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault();
            // Trigger submit handler
            this.onModalSubmit();
        },
        /**
         * When the user clicks on 'I want to miss offer' on the modal, hide the
         * modal, play the video and set the timer for the content.
         */
        missOffer() {
            this.hideModal();
            this.playVideo();

            // unlock the content after 60 seconds
            setTimeout(() => {
                let component = this;

                component.contentLocked = false;

                // needed to add this next tick to get the counter to be rendered correctly
                Vue.nextTick(function() {
                    component.startCountdownTimer();
                });
            }, this.unlockTimer);
        },
        /**
         * Set the countdown timer ready for when content unlocks.
         */
        startCountdownTimer() {
            var currentDate = new Date();
            var countdownDate = moment(currentDate)
                .add(this.countdownTimerMins, "m")
                .toDate();

            this.countdown.year = countdownDate.getFullYear();
            this.countdown.month = countdownDate.getMonth();
            this.countdown.day = countdownDate.getDate();
            this.countdown.hour = countdownDate.getHours();
            this.countdown.minute = countdownDate.getMinutes();
            this.countdown.second = countdownDate.getSeconds();
            this.countdown.millisecond = countdownDate.getMilliseconds();

            this.$refs["landing-counter-1"].showRemaining();
            this.$refs["landing-counter-2"].showRemaining();
        },
        /**
         * When countdown expires redirect to home page?
         */
        countdownExpired() {
            this.showCountdown = false;
        }
    },
    mounted() {
        // set axios base URL
        axios.defaults.baseURL = "/";

        this.showModal();
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.test-wrapper {
    position: relative;
}

.landing-page-wrapper {
    text-align: center;

    .landing-header-container {
        background-image: url("/storage/images/landing/header.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        height: 200px;
    }

    // logo
    .landing-logo-wrapper {
        padding: 10px;

        .landing-logo {
            width: 120px;
            height: 110px;
        }
    }

    // video
    .landing-video-wrapper {
        padding: 0px 10px 10px 10px;

        .landing-video {
            width: 720px;

            .landing-video-video {
                border-radius: 8px;
                z-index: 9;
            }
        }

        .landing-video-text {
            width: 400px;
            text-align: left;
            margin-top: 15px;
            margin-bottom: 15px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    // locked content
    .landing-locked-wrapper {
        padding: 10px 10px 10px 10px;
        background-color: $saru-dark-grey;
        color: $saru-white;

        .locked {
            min-height: 600px;
            background-color: $saru-teal;
            padding: 120px 10px 30px 10px;
            border-radius: 8px;
        }

        .unlocked {
            min-height: 600px;
            background-color: $saru-teal;
            padding: 120px 10px 30px 10px;
            border-radius: 8px;

            .content {
                width: 600px;
            }
        }
    }

    .landing-form {
        padding: 10px;
    }

    .landing-counter {
        padding: 10px;
    }

    .landing-reviews {
        padding: 10px;

        .landing-image {
            border-radius: 8px;
        }

        .image-responsive {
            width: 100%;
        }
    }

    .landing-errors {
        width: 100%;
        padding: 0px 10px 0px 10px;
    }

    .landing-exit-text {
        color: $saru-dark-grey;
    }

    .landing-gallery {
        padding: 5% 0% 10% 0%;
        h2 {
            text-align: center;
        }
    }

    @media (min-width: 376px) {
        .landing-header-container {
            height: 250px;
        }

        .landing-locked-wrapper {
            .locked {
                padding: 180px 10px 30px 10px;
            }

            .unlocked {
                padding: 180px 10px 30px 10px;
            }
        }
    }

    @media (min-width: 576px) {
        .unlocked .content {
            width: 100%;
        }

        .landing-header-container {
            height: 300px;
        }

        .landing-locked-wrapper {
            .locked {
                padding: 225px 10px 30px 10px;
            }

            .unlocked {
                padding: 225px 10px 30px 10px;
            }
        }
    }

    @media (min-width: 768px) {
        .unlocked .content {
            width: 500px;
        }

        .landing-header-container {
            height: 400px;
        }

        .landing-locked-wrapper {
            .locked {
                padding: 155px 10px 30px 10px;
            }

            .unlocked {
                padding: 155px 10px 30px 10px;
            }
        }
    }

    @media (min-width: 992px) {
        .unlocked .content {
            width: 600px;
        }

        .landing-header-container {
            height: 400px;
        }
    }

    .thank-you-message {
        width: 300px;
    }

    .btn-saru-alt {
        background-color: $saru-white;
        color: $saru-dark-grey;
        border-color: $saru-white;
        font-family: Marker Felt, fantasy;
        border-radius: 8px;
        font-size: 14pt;
    }

    .btn-saru-alt:hover {
        background-color: $saru-dark-grey;
        color: white;
        border-color: $saru-dark-grey;
    }
}

.btn-submit {
    width: 100%;
    background-color: $saru-dark-grey;
    color: $saru-white;
    font-family: Marker Felt, fantasy;
}

.landing-modal-errors {
    width: 100%;
}
</style>
