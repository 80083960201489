<template>
    <div class="question-index-table-wrapper">
        <b-alert variant="success" :show="successMessage != null">
            <p class="success-message">
                {{ successMessage }}
            </p>
        </b-alert>

        <v-client-table
            v-if="questions.length > 0"
            :columns="columns"
            :data="questions"
            :options="options"
        >
            <div slot="allowed_access" slot-scope="props">
                <p class="card-text">
                    {{ props.row.allowed_access == 0 ? "No" : "Yes" }}
                </p>
            </div>

            <div slot="classes" slot-scope="props">
                <div v-if="props.row.classes != 'No classes enrolled'">
                    <div v-for="el in props.row.classes" :key="el.id">
                        {{ el.class }}
                    </div>
                </div>
                <div v-if="props.row.classes === 'No classes enrolled'">
                    {{ props.row.classes }}
                </div>
            </div>

            <div slot="edit" slot-scope="props">
                <span
                    class="table-icon"
                    @click="editQuestionModal(props.row.id)"
                >
                    <i class="far fa-edit"></i>
                </span>
            </div>

            <div slot="delete" slot-scope="props">
                <span
                    class="table-icon"
                    @click="deleteQuestionModal(props.row.id)"
                >
                    <i class="far fa-trash-alt"></i>
                </span>
            </div>
        </v-client-table>

        <b-button-toolbar
            class="mb-2"
            key-nav
            aria-label="Toolbar with action buttons for questions."
        >
            <b-button-group class="mx-1">
                <b-button @click="modal.add.show = true">Add Question</b-button>
            </b-button-group>
        </b-button-toolbar>

        <!-- add modal -->
        <b-modal
            v-model="modal.add.show"
            id="add-question-modal"
            title="Add Question"
            ok-variant="success"
            ok-title="Add"
            @ok="handleAddQuestion"
        >
            <div v-if="Object.keys(modal.add.errors).length > 0">
                <Errors :errors="modal.add.errors" />
            </div>

            <b-form-group
                label-class="sr-only"
                label="Question:"
                label-for="modal.add.form.question"
            >
                <b-form-textarea
                    id="modal.add.form.question"
                    v-model="modal.add.form.question"
                    placeholder="Enter a question..."
                    rows="2"
                    max-rows="2"
                    v-bind:class="{
                        'is-invalid': modal.add.errors.question
                    }"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group
                label-class="sr-only"
                label="Question:"
                label-for="modal.add.form.answer"
            >
                <b-form-textarea
                    id="modal.add.form.answer"
                    v-model="modal.add.form.answer"
                    placeholder="Enter an answer..."
                    rows="3"
                    max-rows="5"
                    v-bind:class="{
                        'is-invalid': modal.add.errors.answer
                    }"
                ></b-form-textarea>
            </b-form-group>
        </b-modal>

        <!-- edit modal -->
        <b-modal
            v-model="modal.edit.show"
            id="edit-question-modal"
            title="Edit Question"
            ok-variant="success"
            ok-title="Save"
            @ok="handleEditQuestion"
        >
            <div v-if="Object.keys(modal.edit.errors).length > 0">
                <Errors :errors="modal.edit.errors" />
            </div>

            <b-form-group
                label-class="sr-only"
                label="Question:"
                label-for="modal.edit.form.question"
            >
                <b-form-textarea
                    id="modal.edit.form.question"
                    v-model="modal.edit.form.question"
                    placeholder="Enter a question..."
                    rows="2"
                    max-rows="2"
                    v-bind:class="{
                        'is-invalid': modal.edit.errors.question
                    }"
                ></b-form-textarea>
            </b-form-group>

            <b-form-group
                label-class="sr-only"
                label="Question:"
                label-for="modal.edit.form.answer"
            >
                <b-form-textarea
                    id="modal.edit.form.answer"
                    v-model="modal.edit.form.answer"
                    placeholder="Enter an answer..."
                    rows="3"
                    max-rows="5"
                    v-bind:class="{
                        'is-invalid': modal.edit.errors.answer
                    }"
                ></b-form-textarea>
            </b-form-group>
        </b-modal>

        <!-- delete modal -->
        <b-modal
            v-model="modal.delete.show"
            id="delete-question-modal"
            title="Delete Question"
            ok-variant="danger"
            ok-title="Delete"
            @ok="handleDeleteQuestion"
        >
            <div v-if="Object.keys(modal.delete.errors).length > 0">
                <Errors :errors="modal.delete.errors" />
            </div>

            <p>
                Are you sure you would like to delete the question
                <strong>'{{ modal.delete.form.question }}'</strong>?
            </p>
        </b-modal>
    </div>
</template>
<script>
import api from "@/api/questions";
import Errors from "@/components/layout/Errors.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "question-index-table",
    components: {
        Errors,
        ClipLoader
    },
    data() {
        return {
            errors: [],
            questions: [],
            successMessage: null,
            selectedQuestion: null,
            paginationData: null,
            // modal data
            modal: {
                add: {
                    show: false,
                    form: {
                        question: null,
                        answer: null
                    },
                    errors: []
                },
                edit: {
                    show: false,
                    form: {
                        id: null,
                        question: null,
                        answer: null
                    },
                    errors: []
                },
                delete: {
                    show: false,
                    form: {
                        id: null,
                        question: null
                    },
                    errors: []
                }
            },
            // table data
            columns: [
                "question",
                "answer",
                "helpful",
                "not_helpful",
                "edit",
                "delete"
            ],
            options: {
                skin:
                    "question-index-table table table-striped table-bordered table-hover",
                headings: {
                    question: "Question",
                    answer: "Answer",
                    helpful: "Helpful",
                    not_helpful: "Not helpful",
                    edit: "",
                    delete: ""
                },
                texts: {
                    count: "",
                    filter: "",
                    filterPlaceholder: "Search"
                },
                perPageValues: []
            }
        };
    },
    mounted() {
        this.getQuestions();
    },
    methods: {
        /**
         *  Get the questions data for the table from the backend.
         */
        async getQuestions() {
            this.questions = [];

            api.all().then(response => {
                response.data.questions.forEach(element => {
                    this.questions.push(element);
                });
            });
        },

        /**
         * Display the delete modal.
         *
         * @param questionId
         * @param question
         */
        openDeleteModal(questionId, question) {
            this.form.delete = {
                id: questionId,
                question: question
            };
        },

        /**
         * Stop the modal event from closing by default.
         *
         * @param bvModalEvt
         */
        handleAddQuestion(bvModalEvt) {
            // prevent modal from closing
            bvModalEvt.preventDefault();
            // trigger submit handler
            this.addQuestion();
        },

        /**
         * Add a question.
         */
        addQuestion() {
            this.successMessage = null;
            this.modal.add.errors = [];

            api.store(this.modal.add.form)
                .then(res => {
                    this.getQuestions();
                    this.successMessage = "Question was added succesfully.";
                    this.modal.add.show = false;
                })
                .catch(e => {
                    this.modal.add.errors = e.response.data.errors;
                });
        },

        /**
         * Prepare data and display edit question modal.
         *
         * @param questionId
         */
        editQuestionModal(questionId) {
            let question = this.questions.find(
                question => question.id === questionId
            );

            this.modal.edit.errors = [];

            this.modal.edit.form.id = question.id;
            this.modal.edit.form.question = question.question;
            this.modal.edit.form.answer = question.answer;

            this.modal.edit.show = true;
        },

        /**
         * Prevent modal from closing and call edit question.
         *
         * @param bvModalEvt
         */
        handleEditQuestion(bvModalEvt) {
            // prevent modal from closing
            bvModalEvt.preventDefault();
            // trigger submit handler
            this.editQuestion();
        },

        /**
         * Edit a question.
         */
        editQuestion() {
            this.successMessage = null;
            this.modal.edit.errors = [];

            api.update(this.modal.edit.form.id, this.modal.edit.form)
                .then(res => {
                    this.getQuestions();
                    this.successMessage = "Question was edited succesfully.";
                    this.modal.edit.show = false;
                })
                .catch(e => {
                    this.modal.edit.errors = e.response.data.errors;
                });
        },

        /**
         * Prepare data and display delete question modal.
         *
         * @param questionId
         */
        deleteQuestionModal(questionId) {
            let question = this.questions.find(
                question => question.id === questionId
            );

            this.modal.delete.form.id = question.id;
            this.modal.delete.form.question = question.question;

            this.modal.delete.show = true;
        },

        /**
         * Prevent modal from closing and call edit question.
         *
         * @param bvModalEvt
         */
        handleDeleteQuestion(bvModalEvt) {
            // prevent modal from closing
            bvModalEvt.preventDefault();
            // trigger submit handler
            this.deleteQuestion();
        },

        /**
         * Deletes a question.
         */
        deleteQuestion() {
            this.successMessage = null;
            this.modal.delete.errors = [];

            api.destroy(this.modal.delete.form.id)
                .then(res => {
                    this.getQuestions();
                    this.successMessage = "Question was deleted succesfully.";
                    this.modal.delete.show = false;
                })
                .catch(e => {
                    this.modal.delete.errors = e.response.data.errors;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.question-index-table-wrapper {
    .btn-submit {
        width: 100%;
        background-color: $saru-dark-grey;
        color: $saru-white;
        font-family: Marker Felt, fantasy;
    }

    .table-icon {
        cursor: pointer;
    }

    .success-message {
        margin-bottom: 0px;
        padding: 0px;
    }
}
</style>
