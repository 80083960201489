/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "es6-promise/auto";

import axios from "axios";

import "./bootstrap";

import Vue from "vue";
import VueAuth from "@websanova/vue-auth";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import VueCookie from "vue-cookie";

import UserIndex from "./membership_hub/UserIndex";

// vue auth
import auth from "./auth";

// vue router
import router from "./router";

// require bootstrap-vue
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// require vue tables 2 client table
import { ClientTable } from "vue-tables-2";
// vue-video-player
import VueVideoPlayer from "vue-video-player";
// require videojs style
import "video.js/dist/video-js.css";
// import vue signature pad plugin
import VueSignaturePad from "vue-signature-pad";
// import vue-datetime
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.min.css";

// vue calender
import VCalendar from 'v-calendar';

import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

// ck editor
import CKEditor from 'ckeditor4-vue';

import { StripePlugin } from '@vue-stripe/vue-stripe';

window.Vue = require("vue");

var moment = require("moment");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// vue axios
Vue.use(VueAxios, axios);
axios.defaults.baseURL = "/api";

// vue router
Vue.router = router;
Vue.use(VueRouter);

// vue cookie
Vue.use(VueCookie);

// vue authentication
Vue.use(VueAuth, auth);

// vue-video-player
Vue.use(VueVideoPlayer);

// bootstrap-vue
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// vue-tables-2
Vue.use(ClientTable);

// vue-signature-pad
Vue.use(VueSignaturePad);

// vue-calender
Vue.use(VCalendar);

Vue.component("hooper", Hooper);
Vue.component("slide", Slide);

// ck editor
Vue.use(CKEditor);

// vue-datetime
Vue.component("datetime", Datetime);

// layout components
Vue.component("layout-footer", require("./components/layout/Footer.vue").default);
Vue.component("layout-navbar", require("./components/layout/Navbar.vue").default);

// page components
Vue.component("home-page", require("./pages/Home.vue").default);
Vue.component("locations-page", require("./pages/Locations.vue").default);

Vue.component("location-page", require("./pages/locations/LocationPage.vue").default);

Vue.component("cjj-class-page", require("./pages/classes/CJJ.vue").default);
Vue.component("bjj-class-page", require("./pages/classes/BJJ.vue").default);
Vue.component("sg-class-page", require("./pages/classes/SG.vue").default);
Vue.component("mma-class-page", require("./pages/classes/MMA.vue").default);

Vue.component("contact-form-page", require("./pages/ContactForm.vue").default);
Vue.component("mat-system-contact-form-page", require("./pages/mat_system/ContactForm.vue").default);

Vue.component("faq-page", require('./pages/FAQ.vue').default);
Vue.component("events-page", require("./pages/Events.vue").default);

Vue.component("create-new-starter-review-page", require('./pages/new-starter-reviews/Create.vue').default);

// home page components
Vue.component(
    "review-slider",
    require("./components/ReviewSlider.vue").default
);

// classes page components
Vue.component(
    "classes-gallery-slider",
    require("./components/classes/ClassesGallerySlider.vue").default
);

// users page components
Vue.component("user-index", UserIndex);

// members area components
Vue.component(
    "classes-video-card",
    require("./components/members/classes/VideoCard.vue").default
);

Vue.component(
    "video-card-group",
    require("./components/members/classes/VideoCardGroup.vue").default
);

Vue.component(
    "landing-counter",
    require("./components/Counter.vue").default
);

Vue.component(
    "new-starter-form",
    require("./components/new_starter_form/NewStarterForm.vue").default
);

Vue.component(
    "timetable-page",
    require("@/pages/Timetable.vue").default
);

Vue.component(
    "router-navigation",
    require("./components/RouterNavigation.vue").default
);

// landing page components
Vue.component(
    "landing-page",
    require("./pages/landing/Landing.vue").default
);

Vue.component(
    "landing-page-modal",
    require("./pages/landing/LandingModal.vue").default
);

Vue.component(
    "landing-gallery",
    require("./pages/landing/LandingGallery.vue").default
);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
    router
}).$mount("#app");
