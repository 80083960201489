<template>
    <div>
        <div class="login-container" v-show="!loggedIn">
            <div class="login">
                <div class="login-form">
                    <h2>Welcome to the Membership Hub!</h2>
                    <p>
                        Login or register below.
                    </p>

                    <!-- errors -->
                    <div class="alert alert-danger" v-if="hasError">
                        <div v-if="errors">
                            <div v-for="(v, k) in errors" :key="k">
                                <p
                                    class="error-message"
                                    v-for="error in v"
                                    :key="error"
                                >
                                    {{ error }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- loading spinner -->
                    <div v-if="saving">
                        <clip-loader class="loading-spinner"></clip-loader>
                    </div>

                    <div v-if="this.$route.params.successRegistrationRedirect">
                        <div class="alert alert-success">
                            Registered successfully! Login below.
                        </div>
                    </div>

                    <!-- form -->
                    <form
                        autocomplete="on"
                        @submit.prevent="login"
                        method="post"
                    >
                        <b-form-group
                            label-class="sr-only"
                            label="Email:"
                            label-for="email"
                        >
                            <b-form-input
                                id="email"
                                name="email"
                                type="email"
                                v-model="form.email"
                                placeholder="Email address"
                                v-bind:class="{
                                    'is-invalid': hasError && errors.email
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            label-class="sr-only"
                            label="Password:"
                            label-for="password"
                        >
                            <b-form-input
                                id="password"
                                name="password"
                                type="password"
                                v-model="form.password"
                                placeholder="Password"
                                v-bind:class="{
                                    'is-invalid': hasError && errors.password
                                }"
                            ></b-form-input>
                        </b-form-group>

                        <div class="login-button-toolbar">
                            <router-link :to="'register'">
                                <button
                                    type="button"
                                    class="btn btn-primary nav-link m-1"
                                    :key="'register'"
                                >
                                    Register
                                </button>
                            </router-link>
                            <button
                                type="submit"
                                class="btn btn-primary nav-link m-1"
                            >
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "login",
    components: {
        ClipLoader
    },
    data() {
        return {
            form: {
                email: null,
                password: null
            },
            hasError: false,
            errors: null,
            loggedIn: false,
            saving: false
        };
    },
    methods: {
        /**
         * Call the login route.
         */
        login() {
            var component = this;
            this.saving = true;

            this.$auth.login({
                data:  component.form,
                success: function() {
                    component.saving = false;
                    // redirect user to dashboard
                    this.$router.push({ name: "dashboard" });
                },
                error: function(res) {
                    component.saving = false;
                    component.hasError = true;
                    component.errors = res.response.data.errors;
                },
                rememberMe: true,
                fetchUser: true
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.login-container {
    .error-message {
        margin-bottom: 0px;
    }

    .login {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 150px;
        margin-bottom: 250px;

        @media (min-width: $md-width) {
            margin-bottom: 350px;
        }

        .login-form {
            text-align: center;
            width: 400px;
        }
    }

    .login-button-toolbar {
        display: flex;
        justify-content: center;

        button {
            font-size: 1.2rem;
            font-family: Marker Felt, fantasy;
        }

        button:hover {
            color: $saru-teal;
        }
    }
}
</style>
