<template>
    <div class="user-index-wrapper">
        <b-container fluid>
            <b-row>
                <b-col cols="12">
                    <div v-if="!$auth.ready()">
                        <clip-loader class="loading-spinner"></clip-loader>
                    </div>

                    <div v-if="$auth.ready()">
                        <router-navigation></router-navigation>

                        <router-view></router-view>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    data() {
        return {
            userId: null
        };
    },
    components: {
        ClipLoader
    }
};
</script>

<style lang="scss" scoped>
.user-index-wrapper {
    min-height: 600px;

    .loading-spinner {
        position: relative;
        padding-top: 20%;
    }

    .members-area-navbar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 75px;
        padding: 10px 20px 10px 20px;

        .navbar-title {
            margin: 10px;
            width: 100%;
        }

        .navbar-navigation {
            width: 100%;
        }
    }
}
</style>
