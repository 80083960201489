import axios from "axios";

export default {
    /**
     * Get all users data.
     */
    all() {
        return axios.get("/users");
    },
    /**
     * Get a single user's data.
     *
     * @param {Int} id
     */
    find(id) {
        return axios.get(`/users/${id}`);
    },
    /**
     * Update a user record.
     *
     * @param {Int} id
     * @param {Object} data
     */
    update(id, data) {
        return axios.put(`/users/${id}`, data);
    },
    updateClasses(id, data) {
        return axios.post(`/users/${id}`, data);
    },
    getClasses(id) {
        return axios.get(`/users/classes/${id}`);
    },
    destroyUser(id) {
        return axios.post(`/users/delete/${id}`);
    }
};
