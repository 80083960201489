<template>
    <div>
        <b-container fluid>
            <b-row>
                <b-col class="mt-3">
                    <hr class="saru-hr" />
                    <img
                        class="img-responsive"
                        src="storage/images/home/timetable.png"
                        alt="SJA Martial Arts HQ Timetable"
                        style="width:100%"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-3">
                    <hr class="saru-hr" />
                    <img
                        class="img-responsive"
                        src="storage/images/home/small_group_timetable.png"
                        alt="SJA Martial Arts HQ Timetable"
                        style="width:100%"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mt-3">
                    <img
                        class="img-responsive"
                        src="storage/images/home/neath_and_bridgend_timetable.png"
                        alt="SJA Martial Arts Neath and Bridgend Timetable"
                        style="width:100%"
                    />
                    <hr class="saru-hr" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "timetable-page"
};
</script>

<style lang="scss" scoped></style>
