import VueRouter from "vue-router";

// auth pages
import Register from "@/membership_hub/pages/auth/Register";
import Login from "@/membership_hub/pages/auth/Login";

import Dashboard from "@/membership_hub/pages/Dashboard";

// user pages
import UserDetails from "@/membership_hub/pages/user/Details";
import UserMembership from "@/membership_hub/pages/user/Membership";

// admin pages
import Members from "@/membership_hub/pages/admin/Members";
import Questions from "@/membership_hub/pages/admin/Questions";
import NewStarters from "@/membership_hub/pages/admin/NewStarters";
import NewStarterReviews from "@/membership_hub/pages/admin/NewStarterReviews";

// dashboard pages
import Bjj from "@/membership_hub/pages/classes/Bjj";
import Cjj from "@/membership_hub/pages/classes/Cjj";
import Sg from "@/membership_hub/pages/classes/Sg";
import Mma from "@/membership_hub/pages/classes/Mma";
import InformationHub from "@/membership_hub/pages/InformationHub";
import Events from "@/membership_hub/pages/Events";

const pathPrefix = '/membership-hub';

// routes
const routes = [
    {
        path: pathPrefix + "/register",
        name: "register",
        component: Register,
        meta: {
            auth: false
        }
    },
    {
        path: pathPrefix + "/login",
        name: "login",
        component: Login,
        meta: {
            auth: false
        }
    },
    // user routes
    {
        path: pathPrefix + "/user/details",
        name: "user.details",
        component: UserDetails,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/user/membership",
        name: "user.membership",
        component: UserMembership,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            auth: true
        }
    },
    // classes routes
    {
        path: pathPrefix + "/classes/cjj",
        name: "classes.cjj",
        component: Cjj,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/classes/bjj",
        name: "classes.bjj",
        component: Bjj,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/classes/sg",
        name: "classes.sg",
        component: Sg,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/classes/mma",
        name: "classes.mma",
        component: Mma,
        meta: {
            auth: true
        }
    },
    // information hub routes
    {
        path: pathPrefix + "/information",
        name: "information",
        component: InformationHub,
        meta: {
            auth: true
        }
    },
    // events route
    {
        path: pathPrefix + "/events",
        name: "events",
        component: Events,
        meta: {
            auth: true
        }
    },
    // admin routes
    {
        path: pathPrefix + "/members",
        name: "members",
        component: Members,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/questions",
        name: "questions",
        component: Questions,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/new-starters",
        name: "new-starters",
        component: NewStarters,
        meta: {
            auth: true
        }
    },
    {
        path: pathPrefix + "/new-starter-reviews",
        name: "new-starter-reviews",
        component: NewStarterReviews,
        meta: {
            auth: true
        }
    },
    {
        // matches everything else
        path: "*",
        name: "notFound"
    }
];

const router = new VueRouter({
    history: true,
    mode: "history",
    routes
});

export default router;
