<template>
    <div class="register-container">
        <div class="register">
            <div class="register-form">
                <h3>Sign me up!</h3>

                <!-- errors -->
                <div class="alert alert-danger" v-if="has_error">
                    <div v-if="errors">
                        <div v-for="(v, k) in errors" :key="k">
                            <p
                                class="error-message"
                                v-for="error in v"
                                :key="error"
                            >
                                {{ error }}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- loading spinner -->
                <div v-if="saving">
                    <clip-loader class="loading-spinner"></clip-loader>
                </div>

                <!-- form -->
                <form
                    autocomplete="off"
                    @submit.prevent="register"
                    v-if="!success && !saving"
                    method="post"
                >
                    <b-form-group
                        label-class="sr-only"
                        label="Gym:"
                        label-for="gym"
                    >
                        <b-form-select
                            id="gym"
                            v-model="gym"
                            :options="getGymOptions()"
                            v-bind:class="{
                                'is-invalid': has_error && errors.gym
                            }"
                        >
                            <b-form-select-option :value="''" disabled
                                >Which gym location do you
                                attend?</b-form-select-option
                            >
                        </b-form-select>
                    </b-form-group>

                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            v-model="name"
                            aria-describedby="name"
                            placeholder="Full Name"
                            v-bind:class="{
                                'is-invalid': has_error && errors.name
                            }"
                        />
                    </div>

                    <div class="form-group">
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            placeholder="Email address"
                            v-model="email"
                            aria-describedby="emailHelp"
                            v-bind:class="{
                                'is-invalid': has_error && errors.email
                            }"
                        />
                        <small id="emailHelp" class="form-text text-muted"
                            >We'll never share your email with anyone
                            else.</small
                        >
                    </div>

                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            id="memberId"
                            placeholder="Member ID"
                            v-model="memberId"
                            aria-describedby="memberIdHelp"
                            v-bind:class="{
                                'is-invalid': has_error && errors.memberId
                            }"
                        />
                        <small id="memberIdHelp" class="form-text text-muted"
                            >This is the ID you use to book classes e.g. JD2103</small
                        >
                    </div>

                    <div class="form-group">
                        <input
                            type="password"
                            class="form-control"
                            id="password"
                            v-model="password"
                            placeholder="Password"
                            v-bind:class="{
                                'is-invalid': has_error && errors.password
                            }"
                        />
                    </div>

                    <div class="form-group">
                        <input
                            type="password"
                            class="form-control"
                            id="password_confirmation"
                            v-model="password_confirmation"
                            placeholder="Confirm Password"
                            v-bind:class="{
                                'is-invalid': has_error && errors.password
                            }"
                        />
                    </div>

                    <p>
                        By submitting this form you are accepting our
                        <a href="/privacy-policy" target="_blank"
                            >Privacy Policy</a
                        >.
                    </p>

                    <div class="register-button-toolbar">
                        <router-link :to="'login'">
                            <button
                                type="button"
                                class="btn btn-primary nav-link m-1"
                                :key="'login'"
                            >
                                <i class="fas fa-arrow-circle-left"></i>
                                Back to Login
                            </button>
                        </router-link>
                        <button
                            type="submit"
                            class="btn btn-primary nav-link m-1"
                        >
                            Register
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    name: "register",
    components: {
        ClipLoader
    },
    data() {
        return {
            gym: "",
            name: "",
            email: "",
            memberId: "",
            password: "",
            password_confirmation: "",
            saving: false,
            has_error: false,
            errors: null,
            success: false
        };
    },
    methods: {
        /**
         * Register a new user.
         */
        async register() {
            var app = this;
            this.saving = true;
            // clear any errors
            this.has_error = false;
            this.errors = null;

            await this.$auth.register({
                data: {
                    gym: app.gym,
                    name: app.name,
                    email: app.email,
                    memberId: app.memberId,
                    password: app.password,
                    password_confirmation: app.password_confirmation
                },
                success: function() {
                    app.success = true;
                    app.saving = false;
                    app.has_error = false;
                    app.errors = null;
                },
                error: function(res) {
                    app.saving = false;
                    app.has_error = true;
                    app.errors = res.response.data.errors;
                }
            });

            if (this.success) {
                this.$router
                    .push({
                        name: "login",
                        params: { successRegistrationRedirect: true }
                    })
                    .catch(err => {
                        throw new Error(
                            `Problem handling something: ${err}.`
                        );
                    });
            }
        },
        /**
         * Get the gym options for the select input.
         */
        getGymOptions() {
            return [
                {
                    value: "Swansea",
                    text: "Swansea (YMCA)"
                },
                {
                    value: "Neath",
                    text: "Neath"
                },
                {
                    value: "None",
                    text: "None"
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.register-container {
    .error-message {
        margin-bottom: 0px;
    }

    .register {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 25px;

        @media (min-width: $md-width) {
            padding: 50px;
        }

        .register-form {
            text-align: center;
            max-width: 350px;
            min-width: 350px;
            width: 350px;
        }
    }

    .register-button-toolbar {
        display: flex;
        justify-content: center;

        button {
            font-size: 1.2rem;
            font-family: Marker Felt, fantasy;
        }

        button:hover {
            color: $saru-teal;
        }
    }

    .loading-spinner {
        margin-top: 30px;
    }
}
</style>
