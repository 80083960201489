<template>
    <div class="container">
        <backButton></backButton>

        <div class="row">
            <div class="col-md-12">
                <h3>{{ pageTitle }}</h3>
            </div>
        </div>

        <video-card-group :classType="'sg'"></video-card-group>
    </div>
</template>

<script>
import backButton from "@/membership_hub/components/Back.vue";

export default {
    name: "Sg",
    components: {
        backButton
    },
    data() {
        return {
            pageTitle: "Submission Grappling Videos"
        };
    }
};
</script>
