<template>
    <div class="user-details-wrapper">
        <div class="container">
            <backButton></backButton>

            <errors :errors="errors" :has-error="hasError"></errors>

            <div class="alert alert-success" role="alert" v-if="successMessage">
                <p class="success-message">
                    {{ successMessage }}
                </p>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card details-card">
                        <div class="card-header">
                            <h3>My Details</h3>
                        </div>
                        <div class="card-body">
                            <div v-if="saving">
                                <clip-loader
                                    class="loading-spinner"
                                ></clip-loader>
                            </div>

                            <div v-if="!saving">
                                <!-- form -->
                                <form
                                    autocomplete="off"
                                    @submit.prevent="updateUser"
                                    method="post"
                                >
                                    <b-form-group label="Gym:" label-for="gym">
                                        <b-form-select
                                            id="gym"
                                            v-model="user.gym"
                                            :options="getGymOptions()"
                                            v-bind:class="{
                                                'is-invalid': errors.gym
                                            }"
                                        >
                                            <b-form-select-option
                                                :value="''"
                                                disabled
                                                >Which gym location do you
                                                attend?</b-form-select-option
                                            >
                                        </b-form-select>
                                    </b-form-group>

                                    <b-form-group
                                        label="Full name:"
                                        label-for="name"
                                    >
                                        <b-form-input
                                            id="name"
                                            v-model="user.name"
                                            v-bind:class="{
                                                'is-invalid': errors.name
                                            }"
                                        ></b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        label="Email address:"
                                        label-for="email_address"
                                    >
                                        <b-form-input
                                            id="email_address"
                                            v-model="user.email"
                                            v-bind:class="{
                                                'is-invalid': errors.email
                                            }"
                                        ></b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        label="Member ID:"
                                        label-for="memberID"
                                    >
                                        <b-form-input
                                            id="memberId"
                                            v-model="user.memberId"
                                            aria-describedby="memberIdHelp"
                                            v-bind:class="{
                                                'is-invalid': errors.memberId
                                            }"
                                        ></b-form-input>
                                        <small id="memberIdHelp" class="form-text text-muted"
                                            >This is the ID you use to book classes e.g. JD2103</small
                                        >
                                    </b-form-group>

                                    <b-form-group
                                        label="Date of birth:"
                                        label-for="dateOfBirth"
                                    >
                                        <datetime
                                            v-model="user.dateOfBirth"
                                            type="date"
                                            input-id="dateOfBirth"
                                            title="Date of birth"
                                            :flow="['year', 'month', 'date']"
                                            :max-datetime="
                                                new Date().toISOString()
                                            "
                                            :format="'dd/LL/yyyy'"
                                            v-bind:input-class="{
                                                'form-control is-invalid':
                                                    errors.dateOfBirth,
                                                'form-control': !errors.dateOfBirth
                                            }"
                                        ></datetime>
                                    </b-form-group>

                                    <b-form-group
                                        label="Contact Number:"
                                        label-for="contactNumber"
                                    >
                                        <b-form-input
                                            id="contactNumber"
                                            v-model="user.contactNumber"
                                            v-bind:class="{
                                                'is-invalid':
                                                    errors.contactNumber
                                            }"
                                        ></b-form-input>
                                    </b-form-group>

                                    <b-form-group
                                        label="Children:"
                                        label-for="children"
                                        description="Please enter your child's name, if you have multiple children please seperate their names with a comma e.g. 'Joe Bloggs,Jane Doe'."
                                    >
                                        <b-form-input
                                            id="children"
                                            v-model="user.children"
                                            placeholder="Leave blank if you have no children which attend any of our academy"
                                            v-bind:class="{
                                                'is-invalid': errors.children
                                            }"
                                        ></b-form-input>
                                    </b-form-group>

                                    <div class="register-button-toolbar">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                        >
                                            Update
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api/users";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import backButton from "@/membership_hub/components/Back.vue";
import errors from "@/components/layout/Errors.vue";

export default {
    name: "user-details",
    components: {
        ClipLoader,
        backButton,
        errors
    },
    data() {
        return {
            hasError: false,
            saving: false,
            user: {
                id: null,
                name: null,
                email: null,
                memberId: null,
                dateOfBirth: null,
                contactNumber: null,
                gym: null,
                allowed_access: null,
                children: null
            },
            errors: [],
            successMessage: null
        };
    },
    mounted() {
        this.getCurrentDetails();
    },
    methods: {
        /**
         * Update the user's details in the backend.
         */
        updateUser() {
            this.saving = true;
            this.successMessage = null;

            // post request to backend
            axios
                .post("/users/" + this.$auth.user().id, this.user)
                .then(res => {
                    // if successful then display success message
                    this.success = true;
                    this.hasError = false;
                    this.errors = [];
                    this.successMessage = res.data.success;
                    // update the auth user data
                    this.$auth.user(res.data.user);
                    this.getCurrentDetails();
                })
                .catch(e => {
                    this.hasError = true;
                    this.errors = e.response.data.errors;
                })
                .then(_ => {
                    this.saving = false;
                });
        },
        /**
         * Display the current user's details from auth.
         */
        getCurrentDetails() {
            this.user.id = this.$auth.user().id;
            this.user.name = this.$auth.user().name;
            this.user.email = this.$auth.user().email;
            this.user.memberId = this.$auth.user().member_id;
            this.user.dateOfBirth = new Date(
                this.$auth.user().date_of_birth
            ).toISOString();
            this.user.contactNumber = this.$auth.user().contact_number;
            this.user.gym = this.$auth.user().gym;
            this.user.allowed_access = this.$auth.user().allowed_access;
            this.user.children = this.$auth.user().children;
        },
        /**
         * Get the gym options for the select input.
         */
        getGymOptions() {
            return [
                {
                    value: "Swansea",
                    text: "Swansea (YMCA)"
                },
                {
                    value: "Neath",
                    text: "Neath"
                },
                {
                    value: "None",
                    text: "None"
                }
            ];
        }
    }
};
</script>

<style lang="scss" scoped>
@import "resources/sass/variables";

.user-details-wrapper {
    .details-card {
        margin-bottom: 15px;
    }

    .register-button-toolbar {
        button {
            width: 100%;
            font-family: Marker Felt, fantasy;
        }

        button:hover {
            color: $teal;
        }
    }

    .errors {
        text-align: center;
        width: 100%;
    }

    .error-message {
        margin-bottom: 0px;
        padding: 0px;
    }

    .success-message {
        margin-bottom: 0px;
        padding: 0px;
    }
}
</style>
