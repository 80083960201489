<template>
    <div>
        <div class="intro-wrapper">
            <div class="jumbotron">
                <div class="container">
                    <h1 class="display-4">{{ config.name }}</h1>
                    <hr class="saru-hr" />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12 col-md-9">
                    <p>
                        {{ config.introduction }}
                    </p>
                </div>
                <div class="col-12 col-md-3">

                </div>
            </div>

            <div v-if="coaches.length > 0" class="row">
                <div class="col-12">
                    <h2>Coaches</h2>
                    <hr class="saru-hr" />
                </div>
            </div>

            <div v-if="coaches.length > 0" class="row">
                <div v-for="coach in coaches" :key="coach.name" class="col-12 col-md-8 col-lg-6 col-xl-4">
                    <div class="card mb-3 coaches-card" style="max-width: 540px;">
                        <div class="row no-gutters">
                            <div class="col-12 col-sm-4 col-md-4 align-middle">
                                <img 
                                    class="coaches-card-image rounded-circle" 
                                    :src="coach.headshot_image_src" 
                                    :alt="coach.headshot_image_alt"
                                >
                            </div>
                            <div class="col-12 col-sm-8 col-md-8">
                                <div class="card-body">
                                    <h5 class="card-title">{{ coach.name }}</h5>
                                    <p class="card-text">
                                        {{ coach.text }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <ul class="list-unstyled">
                        <li><b>Address:</b> {{ config.address }}</li>
                        <li><b>Phone:</b> {{ config.phone }}</li>
                        <li>
                            <div
                                class="location-social-media-links ml-auto m-r-10 visible-md-block visible-lg-block"
                            >
                                <a
                                    href="https://www.facebook.com/SJAmartialarts"
                                    target="_blank"
                                    ><i class="fab fa-facebook-square"></i
                                ></a>
                                <a
                                    href="https://twitter.com/sarujujitsu"
                                    target="_blank"
                                    ><i class="fab fa-twitter-square"></i
                                ></a>
                                <a
                                    href="https://www.instagram.com/sjamartialarts/"
                                    target="_blank"
                                    ><i class="fab fa-instagram"></i
                                ></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>  
        </div>

        <div class="container-fluid p-0 mb-5">
            <div class="row">
                <div class="col-md-12">
                    <iframe 
                        class="saru-home-maps" 
                        loading="lazy"
                        :src="config.google_maps_src" 
                        allowfullscreen
                    >
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'location-page',
    props: {
        config: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            coaches: this.config.coaches,
        };
    }
}
</script>

<style lang="scss" scoped>
.saru-home-maps {
    width: 100%;
    height: 350px;
    border: none;
    margin: 0;
    padding: 0;
    opacity: 0.8;
    display: block;
}

.coaches-card {
    min-height: 285px;

    .coaches-card-image {
        width: 100%;
        height: 100%;
        padding-left: 10px;
        padding-right: 0px;
        padding-top: 25px;
        padding-bottom: 2 5px;
    }

    @media (min-width: 576px) {
        
    }

    @media (min-width: 768px) {
        .coaches-card-image {
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 0px;
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }

    @media (min-width: 992px) {
        .coaches-card-image {
            width: 100%;
            height: 100%;
            padding-left: 10px;
            padding-right: 0px;
            padding-top: 55px;
            padding-bottom: 55px;
        }
    }
}


</style>